import { createElement, useState, Component, useEffect, useRef } from 'react';
import { LocalStorageBackend, MemoryBackend } from './persistence';
function createBackend(backend) {
    if (!backend)
        return new MemoryBackend();
    if (backend === true)
        return new LocalStorageBackend();
    else
        return backend;
}
/**
 * Creates a state store, along with a hook and HOC to access it
 * @param defaults The keys and corresponding default values for the global state
 * @param persist Either a boolean (whether or not to persist to local storage)
 *                or a custom persistence backend. The backend must be an
 *                object supporting backend.get(key) and
 *                backend.set(key, value).
 * @returns A global hook and HOC, both of which support a string key or array
 *          of keys to access global state for.
 */
export default function createState(defaults, persist = false) {
    const backend = createBackend(persist);
    const defaultKeys = Object.keys(defaults);
    const stateSubs = new Set();
    for (const k of defaultKeys) {
        if (backend.get(k) === undefined)
            backend.set(k, defaults[k]);
    }
    const hooks = {};
    for (const k in defaults) {
        hooks[k] = () => {
            const [state, setState] = useState(backend.get(k));
            const curState = useRef(state);
            useEffect(() => {
                const cb = (v) => {
                    if (v.hasOwnProperty(k)) {
                        curState.current = v[k];
                        setState(v[k]);
                    }
                };
                stateSubs.add(cb);
                return () => {
                    stateSubs.delete(cb);
                };
            });
            return [
                state,
                val => {
                    const newVal = typeof val === 'function' ? val(curState.current) : val;
                    curState.current = newVal;
                    backend.set(k, newVal);
                    for (const f of stateSubs)
                        f({ [k]: newVal });
                }
            ];
        };
    }
    const hook = Object.assign((key = defaultKeys) => {
        if (Array.isArray(key)) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const ret = {};
            for (const k of key.sort())
                ret[k] = hooks[k]();
            return ret;
        }
        return hooks[key]();
    }, hooks);
    const hoc = (GSC, key = defaultKeys) => {
        const keys = Array.isArray(key) ? key : [key];
        return class WithGlobalState extends Component {
            static displayName = `WithGlobalState<${keys.join(', ')}>(${GSC.displayName || GSC.name || 'Component'})`;
            constructor(props) {
                super(props);
                const state = {};
                for (const k of keys)
                    state[k] = backend.get(k);
                this.state = state;
            }
            componentDidMount() {
                stateSubs.add(this.setLocalState);
            }
            setLocalState = ((globalUpdate) => {
                let modified = false;
                const localUpdate = {};
                for (const k of keys) {
                    if (globalUpdate.hasOwnProperty(k) && globalUpdate[k] !== this.state[k]) {
                        localUpdate[k] = globalUpdate[k];
                        this.state[k] = globalUpdate[k];
                        modified = true;
                    }
                }
                if (modified)
                    this.setState(localUpdate);
            }).bind(this);
            componentWillUnmount() {
                stateSubs.delete(this.setLocalState);
            }
            render() {
                const prevState = this.state;
                return (createElement(GSC, {
                    ...this.props,
                    setGlobalState(newState) {
                        const newGlobalState = (typeof newState === 'function'
                            ? newState(prevState)
                            : newState);
                        const targetUpdate = {};
                        if (newGlobalState != null) {
                            for (const k of keys) {
                                if (newGlobalState.hasOwnProperty(k))
                                    targetUpdate[k] = newGlobalState[k];
                            }
                        }
                        for (const k in targetUpdate) {
                            backend.set(k, targetUpdate[k]);
                            prevState[k] = targetUpdate[k];
                        }
                        for (const f of stateSubs)
                            f(targetUpdate);
                    },
                    globalState: prevState
                }));
            }
        };
    };
    return { hook, hoc };
}
export { LocalStorageBackend, MemoryBackend };
