import { CloseIcon, DeleteIcon, EmailIcon, RepeatIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Tab,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import { useEffect, useId, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import AddRoleMenu from '../../components/add-role-menu';
import PermissionBadge from '../../components/permission-badge';
import RoleTag from '../../components/role-tag';
import RemovableRole from '../../components/removable-role';
import {
  api,
  APIError,
  Invite,
  invites,
  PermissionLevel,
  roles,
  UserInfo,
  users
} from '../../util/api';
import { useGlobalState } from '../../util/state';
import FocusLock from 'react-focus-lock';
import Loading from '../../components/loading';

const PermissionMenu = ({
  value,
  min,
  max,
  onSelect
}: {
  value: PermissionLevel;
  min: PermissionLevel;
  max: PermissionLevel;
  onSelect: (level: PermissionLevel) => unknown;
}) => {
  const [loading, setLoading] = useState(false);

  const permissions = useMemo(() => {
    const vals: PermissionLevel[] = [];
    for (let i = min; i <= max; ++i) vals.push(i);
    return vals;
  }, [min, max]);

  return (
    <Menu isLazy>
      <MenuButton disabled={loading}>
        <PermissionBadge permission={value} />
      </MenuButton>
      <MenuList>
        {permissions.map(perm => (
          <MenuItem
            key={perm}
            onClick={async () => {
              if (perm == value) return;
              setLoading(true);
              await onSelect(perm);
              setLoading(false);
            }}
          >
            <PermissionBadge permission={perm} />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

const DeleteUser = ({ info }: { info: UserInfo }) => {
  const [allUsers, setAllUsers] = useGlobalState('users');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);

  return (
    <Popover isLazy isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="left">
      <PopoverTrigger>
        <IconButton
          isDisabled={loading}
          aria-label="Delete user"
          icon={<DeleteIcon />}
          colorScheme="red"
          variant="outline"
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverHeader>Confirm deletion</PopoverHeader>
          <PopoverBody>
            Are you sure you want to delete {info.name}'s account ({info.email}
            )? You can restore it later from the "Deleted" tab.
          </PopoverBody>
          <PopoverFooter>
            <ButtonGroup display="flex" justifyContent="flex-end">
              <Button isDisabled={loading} variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                isDisabled={loading}
                isLoading={loading}
                onClick={async () => {
                  setLoading(true);
                  try {
                    await api('/users', {
                      method: 'PATCH',
                      body: {
                        id: info.id,
                        permission: PermissionLevel.Blacklist,
                        roles: []
                      }
                    });
                    const users = { ...allUsers };
                    users[info.id].roles = [];
                    users[info.id].permission = PermissionLevel.Blacklist;
                    setAllUsers(users);
                  } catch (err) {
                    // todo: toast?
                  }
                  setLoading(false);
                  onClose();
                }}
              >
                Confirm
              </Button>
            </ButtonGroup>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

const PendingUser = ({ info }: { info: UserInfo }) => {
  const [userInfo] = useGlobalState('userInfo');
  const [allRoles] = useGlobalState('roles');
  const [allUsers, setAllUsers] = useGlobalState('users');
  const [loading, setLoading] = useState(false);
  const [perm, setPerm] = useState(info.permission);
  const [roles, setRoles] = useState(info.roles);

  useEffect(() => {
    setPerm(info.permission);
    setRoles(info.roles);
  }, [info]);

  return (
    <>
      <Td>
        <Flex align="center">
          <Avatar name={info.name} src={info.avatarURL} showBorder me={1} />
          <Text>{info.name}</Text>
        </Flex>
      </Td>
      <Td>
        <PermissionMenu
          value={perm}
          onSelect={setPerm}
          min={PermissionLevel.Reporter}
          max={
            userInfo.permission < PermissionLevel.Advisor
              ? userInfo.permission - 1
              : userInfo.permission
          }
        />
      </Td>
      <Td>
        {allRoles && (
          <Wrap align="center">
            {roles.map(id => (
              <WrapItem key={id}>
                <RemovableRole
                  role={allRoles[id]}
                  onRemove={() => {
                    setRoles(roles.filter(v => v != id));
                  }}
                />
              </WrapItem>
            ))}
            <WrapItem>
              <AddRoleMenu
                canEdit
                roles={Object.keys(allRoles)
                  .filter(v => !roles.includes(v))
                  .map(v => allRoles[v])}
                onSelect={role => {
                  setRoles(roles.concat(role.id));
                }}
              />
            </WrapItem>
          </Wrap>
        )}
      </Td>
      <Td isNumeric>
        <ButtonGroup>
          <Button
            colorScheme="green"
            isDisabled={perm <= PermissionLevel.None || loading}
            onClick={async () => {
              setLoading(true);
              try {
                await api('/users', {
                  method: 'PATCH',
                  body: { id: info.id, permission: perm, roles }
                });
                const users = { ...allUsers };
                users[info.id].roles = roles;
                users[info.id].permission = perm;
                setAllUsers(users);
              } catch (err) {
                // todo: toast?
              }
              setLoading(false);
            }}
          >
            Add user
          </Button>
          <DeleteUser info={info} />
        </ButtonGroup>
      </Td>
    </>
  );
};

const PendingInvite = ({ invite }: { invite: Invite }) => {
  const [loading, setLoading] = useState(false);
  const [userInfo] = useGlobalState('userInfo');
  const [allRoles] = useGlobalState('roles');
  const [allInvites, setAllInvites] = useGlobalState('invites');

  return (
    <>
      <Td>
        <Flex align="center">
          <EmailIcon me={0.5} boxSize={12} p={1.5} ps={0.5} />
          <Text>{invite.email}</Text>
        </Flex>
      </Td>
      <Td>
        <PermissionMenu
          value={invite.permission}
          onSelect={async perm => {
            try {
              await api('/users/invites', {
                method: 'PATCH',
                body: {
                  email: invite.email,
                  permission: perm
                }
              });
              const invites = { ...allInvites };
              invites[invite.email].permission = perm;
              setAllInvites(invites);
            } catch (err) {
              // TODO: toast?
            }
          }}
          min={PermissionLevel.Reporter}
          max={
            userInfo.permission < PermissionLevel.Advisor
              ? userInfo.permission - 1
              : userInfo.permission
          }
        />
      </Td>
      <Td>
        {allRoles && (
          <Wrap align="center">
            {invite.roles.map(id => (
              <WrapItem key={id}>
                <RemovableRole
                  role={allRoles[id]}
                  onRemove={async () => {
                    const roles = invite.roles.filter(v => v != id);
                    try {
                      await api('/users/invites', {
                        method: 'PATCH',
                        body: {
                          email: invite.email,
                          roles
                        }
                      });
                      const invites = { ...allInvites };
                      invites[invite.email].roles = roles;
                      setAllInvites(invites);
                    } catch (err) {
                      // TODO: toast?
                    }
                  }}
                />
              </WrapItem>
            ))}
            <WrapItem>
              <AddRoleMenu
                canEdit
                roles={Object.values(allRoles).filter(v => !invite.roles.includes(v.id))}
                onSelect={async role => {
                  const roles = invite.roles.concat(role.id);
                  try {
                    await api('/users/invites', {
                      method: 'PATCH',
                      body: {
                        email: invite.email,
                        roles
                      }
                    });
                    const invites = { ...allInvites };
                    invites[invite.email].roles = roles;
                    setAllInvites(invites);
                  } catch (err) {
                    // TODO: toast?
                  }
                }}
              />
            </WrapItem>
          </Wrap>
        )}
      </Td>
      <Td isNumeric>
        <ButtonGroup>
          <Button
            colorScheme="gray"
            isDisabled={loading}
            leftIcon={<RepeatIcon />}
            onClick={async () => {
              setLoading(true);
              try {
                await api('/users/invites', {
                  method: 'PUT',
                  body: { email: invite.email }
                });
              } catch (err) {
                // TODO: toast?
              }
              setLoading(false);
            }}
          >
            Resend
          </Button>
          <IconButton
            aria-label="Cancel invite"
            colorScheme="red"
            variant="outline"
            isDisabled={loading}
            icon={<CloseIcon />}
            onClick={async () => {
              setLoading(true);
              try {
                await api('/users/invites', {
                  method: 'DELETE',
                  body: { email: invite.email }
                });
                const invites = { ...allInvites };
                delete invites[invite.email];
                setAllInvites(invites);
              } catch (err) {
                // todo: toast?
              }
              setLoading(false);
            }}
          />
        </ButtonGroup>
      </Td>
    </>
  );
};

const InviteButton = () => {
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputEmail, setInputEmail] = useState('');
  const email = useMemo(() => inputEmail.toLowerCase(), [inputEmail]);
  const [err, setErr] = useState('');
  const emailSelectID = useId();
  const [allInvites, setAllInvites] = useGlobalState('invites');
  const [allUsers] = useGlobalState('users');

  useEffect(() => {
    if (allInvites[email]) {
      setErr('This email has already been invited');
      return;
    }
    for (const id in allUsers) {
      if (allUsers[id].email == email) {
        setErr(allUsers[id].name + ' has already joined');
        return;
      }
    }
    setErr('');
  }, [email]);

  return (
    <Popover isLazy isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="left">
      <PopoverTrigger>
        <Button size="sm" leftIcon={<EmailIcon />}>
          Invite user
        </Button>
      </PopoverTrigger>
      <Portal>
        <FocusLock returnFocus>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <FormControl isInvalid={err != ''}>
                <FormLabel htmlFor={emailSelectID}>Email</FormLabel>
                <Input
                  value={inputEmail}
                  onChange={e => setInputEmail(e.currentTarget.value)}
                  id={emailSelectID}
                />
                <FormErrorMessage>{err}</FormErrorMessage>
              </FormControl>
            </PopoverBody>
            <PopoverFooter>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Button isDisabled={loading} variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  isDisabled={err != '' || !email || loading}
                  isLoading={loading}
                  loadingText="Inviting..."
                  onClick={async () => {
                    setLoading(true);
                    try {
                      const invite: Invite = {
                        email,
                        permission: PermissionLevel.Reporter,
                        roles: []
                      };
                      await api('/users/invites', {
                        method: 'POST',
                        body: invite
                      });
                      const newInvites = { ...allInvites };
                      newInvites[email] = invite;
                      setAllInvites(newInvites);
                    } catch (err) {
                      if (err instanceof APIError && err.status == 500) {
                        setErr('Failed to send email');
                        setLoading(false);
                        return;
                      }
                    }
                    setInputEmail('');
                    setLoading(false);
                    onClose();
                  }}
                >
                  Invite
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </FocusLock>
      </Portal>
    </Popover>
  );
};

const Users = ({ section }: { section?: 'pending' | 'deleted' }) => {
  const navigate = useNavigate();
  const [userInfo] = useGlobalState('userInfo');
  const [allRoles, setAllRoles] = useGlobalState('roles');
  const [allUsers, setAllUsers] = useGlobalState('users');
  const [allInvites, setAllInvites] = useGlobalState('invites');
  const findTab = () => (section == 'pending' ? 1 : section == 'deleted' ? 2 : 0);
  const [tab, setTab] = useState(findTab);

  useEffect(() => {
    setTab(findTab());
  }, [section]);

  useEffect(() => {
    const foundSection = [undefined, 'pending', 'deleted'][tab];
    if (foundSection != section) {
      navigate('/users' + (foundSection ? '/' + foundSection : ''));
    }
  }, [tab]);

  const [allowedUsers, pendingUsers, blacklistedUsers] = useMemo(() => {
    const values = Object.values(allUsers || {}).filter(v => v.id != userInfo.id);
    const lastNameCompare = (a: string, b: string) => {
      a = a.slice(a.lastIndexOf(' ') + 1);
      b = b.slice(b.lastIndexOf(' ') + 1);
      return a.localeCompare(b);
    };
    values.sort((a, b) => b.permission - a.permission || lastNameCompare(a.name, b.name));

    let i = 0;
    for (; i < values.length && values[i].permission > PermissionLevel.None; ++i);
    let j = i;
    for (; j < values.length && values[j].permission > PermissionLevel.Blacklist; ++j);
    return [values.slice(0, i), values.slice(i, j), values.slice(j)];
  }, [allUsers]);

  const inviteList = useMemo(() => Object.values(allInvites || {}), [allInvites]);

  useEffect(() => {
    const controller = new AbortController();
    if (!allRoles) {
      roles(controller.signal).then(setAllRoles);
    }
    if (!allUsers) {
      users(controller.signal).then(setAllUsers);
    }
    if (!allInvites && userInfo.permission >= PermissionLevel.Strategic) {
      invites(controller.signal).then(setAllInvites);
    }
    return () => controller.abort();
  }, []);

  if (!allRoles || !allUsers || (userInfo.permission >= PermissionLevel.Strategic && !allInvites)) {
    return (
      <Flex h="calc(var(--dvh) - 80px)" justify="center" align="center">
        <Loading />
      </Flex>
    );
  }

  const baseTable = (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th>Member</Th>
            <Th>Permission</Th>
            <Th>Roles</Th>
            {userInfo.permission >= PermissionLevel.Strategic && (
              <Th isNumeric>{allInvites && allUsers && <InviteButton />}</Th>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {allowedUsers.map(info => (
            <Tr key={info.id}>
              <Td>
                <Flex align="center">
                  <Avatar name={info.name} src={info.avatarURL} showBorder me={1} />
                  <Text>{info.name}</Text>
                </Flex>
              </Td>
              <Td>
                {userInfo.permission >
                Math.max(
                  Math.min(info.permission, PermissionLevel.Strategic),
                  PermissionLevel.Reporter + 1
                ) ? (
                  <PermissionMenu
                    value={info.permission}
                    onSelect={async perm => {
                      try {
                        await api('/users', {
                          method: 'PATCH',
                          body: {
                            id: info.id,
                            permission: perm
                          }
                        });
                        const users = { ...allUsers };
                        users[info.id].permission = perm;
                        setAllUsers(users);
                      } catch (err) {
                        // TODO: toast?
                      }
                    }}
                    min={PermissionLevel.Reporter}
                    max={
                      userInfo.permission < PermissionLevel.Advisor
                        ? userInfo.permission - 1
                        : userInfo.permission
                    }
                  />
                ) : (
                  <PermissionBadge permission={info.permission} />
                )}
              </Td>
              <Td>
                <Wrap>
                  {allRoles &&
                    info.roles.map(id => (
                      <WrapItem key={id}>
                        {userInfo.permission >
                          Math.min(info.permission, PermissionLevel.Strategic) &&
                        (userInfo.permission >= PermissionLevel.Strategic ||
                          userInfo.roles.includes(id)) ? (
                          <RemovableRole
                            role={allRoles[id]}
                            onRemove={async () => {
                              try {
                                const newRoles = info.roles.filter(v => v != id);
                                await api('/users', {
                                  method: 'PATCH',
                                  body: { id: info.id, roles: newRoles }
                                });
                                const users = { ...allUsers };
                                users[info.id].roles = newRoles;
                                setAllUsers(users);
                              } catch (err) {
                                // todo: toast?
                              }
                            }}
                          />
                        ) : (
                          <RoleTag role={allRoles[id]} />
                        )}
                      </WrapItem>
                    ))}
                  {allRoles &&
                  userInfo.permission > Math.min(info.permission, PermissionLevel.Strategic) &&
                  (userInfo.permission >= PermissionLevel.Strategic ||
                    userInfo.roles.some(id => !info.roles.includes(id))) ? (
                    <AddRoleMenu
                      roles={(allRoles && userInfo.permission >= PermissionLevel.Strategic
                        ? Object.keys(allRoles)
                        : userInfo.roles
                      )
                        .filter(v => !info.roles.includes(v))
                        .map(v => allRoles[v])}
                      canEdit={userInfo.permission >= PermissionLevel.Strategic}
                      onSelect={async role => {
                        try {
                          await api('/users', {
                            method: 'PATCH',
                            body: {
                              id: info.id,
                              roles: info.roles.concat(role.id)
                            }
                          });
                          const users = { ...allUsers };
                          users[info.id].roles.push(role.id);
                          setAllUsers(users);
                        } catch (err) {
                          // todo: toast?
                        }
                      }}
                    />
                  ) : (
                    !info.roles.length && <Text>None</Text>
                  )}
                </Wrap>
              </Td>
              {userInfo.permission >= PermissionLevel.Strategic && (
                <Td isNumeric>
                  {(info.permission < userInfo.permission ||
                    userInfo.permission == PermissionLevel.Advisor) && <DeleteUser info={info} />}
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );

  return userInfo.permission >= PermissionLevel.Strategic ? (
    <Box>
      <Tabs isFitted index={tab} onChange={setTab}>
        <TabList>
          <Tab>Staff</Tab>
          <Tab>Pending</Tab>
          <Tab>Deleted</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>{baseTable}</TabPanel>
          <TabPanel>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Username</Th>
                    <Th>Permission</Th>
                    <Th>Roles</Th>
                    <Th isNumeric>{allInvites && allUsers && <InviteButton />}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {pendingUsers.map(info => (
                    <Tr key={info.id}>
                      <PendingUser info={info} />
                    </Tr>
                  ))}
                  {inviteList.map(invite => (
                    <Tr key={invite.email}>
                      <PendingInvite invite={invite} />
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <TableContainer>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Username</Th>
                    <Th>Email</Th>
                    <Th isNumeric>{allInvites && allUsers && <InviteButton />}</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {blacklistedUsers.map(info => (
                    <Tr key={info.id}>
                      <Td>
                        <Flex align="center">
                          <Avatar name={info.name} src={info.avatarURL} showBorder me={1} />
                          <Text>{info.name}</Text>
                        </Flex>
                      </Td>
                      <Td>
                        <Text>{info.email}</Text>
                      </Td>
                      <Td isNumeric>
                        <Popover isLazy placement="left">
                          {({ onClose }) => {
                            const [loading, setLoading] = useState(false);

                            return (
                              <>
                                <PopoverTrigger>
                                  <IconButton
                                    aria-label="Restore user"
                                    colorScheme="gray"
                                    isDisabled={loading}
                                    icon={<RepeatIcon />}
                                    variant="outline"
                                  />
                                </PopoverTrigger>
                                <Portal>
                                  <PopoverContent>
                                    <PopoverArrow />
                                    <PopoverHeader>Confirm restoration</PopoverHeader>
                                    <PopoverBody>
                                      Are you sure you want to restore {info.name}'s account (
                                      {info.email})? You can give them back their permissions and
                                      roles from the "Pending" tab.
                                    </PopoverBody>
                                    <PopoverFooter>
                                      <ButtonGroup display="flex" justifyContent="flex-end">
                                        <Button
                                          isDisabled={loading}
                                          variant="outline"
                                          onClick={onClose}
                                        >
                                          Cancel
                                        </Button>
                                        <Button
                                          isDisabled={loading}
                                          isLoading={loading}
                                          onClick={async () => {
                                            setLoading(true);
                                            try {
                                              await api('/users', {
                                                method: 'PATCH',
                                                body: {
                                                  id: info.id,
                                                  permission: PermissionLevel.None
                                                }
                                              });
                                              const users = { ...allUsers };
                                              users[info.id].roles = [];
                                              users[info.id].permission = PermissionLevel.None;
                                              setAllUsers(users);
                                            } catch (err) {
                                              // todo: toast?
                                            }
                                            setLoading(false);
                                            onClose();
                                          }}
                                        >
                                          Confirm
                                        </Button>
                                      </ButtonGroup>
                                    </PopoverFooter>
                                  </PopoverContent>
                                </Portal>
                              </>
                            );
                          }}
                        </Popover>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  ) : (
    baseTable
  );
};

export default Users;
