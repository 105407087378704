import { useCallback, useEffect, useRef, useState } from 'react';

export declare namespace GooglePicker {
  type CallbackData = {
    action: string;
    docs: Document[];
  };
  type PickerCallback = (evt: CallbackData) => void;

  enum ViewId {
    DOCS,
    DOCS_IMAGES,
    DOCS_IMAGES_AND_VIDEOS,
    DOCS_VIDEOS,
    DOCUMENTS,
    DRAWINGS,
    FOLDERS,
    FORMS,
    PDFS,
    PRESENTATIONS,
    SPREADSHEETS
  }

  enum DocsViewMode {
    GRID,
    LIST
  }

  enum Feature {
    MINE_ONLY,
    MULTISELECT_ENABLED,
    NAV_HIDDEN,
    SIMPLE_UPLOAD_ENABLED,
    SUPPORT_DRIVES
  }

  interface Document {
    url: string;
    id: string;
    name: string;
  }

  class View {
    constructor(viewId: ViewId);
    getId(): ViewId;
    setMimeTypes(mimeTypes: string): this;
    setQuery(query: string): this;
  }

  class DocsView extends View {
    constructor(viewId?: ViewId);
    setEnableDrives(enableDrives: boolean): this;
    setIncludeFolders(includeFolders: boolean): this;
    setSelectFolderEnabled(selectFolderEnabled: boolean): this;
    setMode(mode: DocsViewMode): this;
    setOwnedByMe(ownedByMe?: boolean): this;
    setParent(parent: string): this;
    setStarred(starred: boolean): this;
  }

  class DocsUploadView extends View {
    constructor();
    setIncludeFolders(includeFolders: boolean): this;
    setParent(parent: string): this;
  }

  class ViewGroup {
    constructor(base: View | ViewId);
    addLabel(label: string): this;
    addView(view: View | ViewId): this;
    addViewGroup(viewGroup: ViewGroup): this;
  }

  class ResourceId {
    private constructor();
    static generate(document: Document): string;
  }

  class PickerBuilder {
    constructor();
    addView(view: View | ViewId): PickerBuilder;
    addViewGroup(viewGroup: ViewGroup): PickerBuilder;
    build(): Picker;
    disableFeature(feature: Feature): PickerBuilder;
    enableFeature(feature: Feature): PickerBuilder;
    getRelayUrl(): string;
    getTitle(): string;
    hideTitleBar(): PickerBuilder;
    isFeatureEnabled(feature: Feature): boolean;
    setAppId(appId: string): PickerBuilder;
    /** Avoid calling this; the library will do it for you */
    setCallback(callback: PickerCallback): PickerBuilder;
    setDeveloperKey(developerKey: string): PickerBuilder;
    setDocument(document: Document): PickerBuilder;
    setLocale(locale: string): PickerBuilder;
    setMaxItems(maxItems: number): PickerBuilder;
    setOAuthToken(token: string): PickerBuilder;
    setOrigin(origin: string): PickerBuilder;
    setRelayUrl(relayUrl: string): PickerBuilder;
    setSelectableMimeTypes(mimeTypes: string): PickerBuilder;
    setSize(width: number, height: number): PickerBuilder;
    setTitle(title: string): PickerBuilder;
    toUri(): string;
  }

  class Picker {
    /** Do not call this; use PickerBuilder instead */
    private constructor();
    isVisible(): boolean;
    /** Avoid calling this; the library will do it for you */
    setCallback(callback: PickerCallback): void;
    setRelayUrl(relayUrl: string): void;
    setVisible(visible: boolean): void;
    dispose(): void;
  }
}

declare var gapi: {
  load(module: string, opts?: { callback: () => unknown }): void;
};

declare var google: {
  picker: typeof GooglePicker;
};

export type PickerBuilder = (picker: typeof GooglePicker) => GooglePicker.Picker;

let pickerLoadHandlers: (() => unknown)[];

const NOP = () => {};

export function useDrivePicker(builder: PickerBuilder, callback: GooglePicker.PickerCallback) {
  const [pickerLoaded, setPickerLoaded] = useState(false);
  const [visible, setVisible] = useState(false);
  const picker = useRef<GooglePicker.Picker>();
  const pickerCallback = useCallback<GooglePicker.PickerCallback>(
    evt => {
      setVisible(picker.current.isVisible());
      callback(evt);
    },
    [callback]
  );

  useEffect(() => {
    if (typeof google != 'undefined' && google.picker) {
      setPickerLoaded(true);
    } else {
      if (!pickerLoadHandlers) {
        const gapiLoadHandler = () => {
          gapi.load('picker', {
            callback: () => {
              for (const handler of pickerLoadHandlers) {
                handler();
              }
            }
          });
        };

        if (typeof gapi == 'undefined') {
          const script = document.createElement('script');
          script.async = true;
          script.src = 'https://apis.google.com/js/api.js';
          script.addEventListener('load', gapiLoadHandler);
          document.body.appendChild(script);
        } else {
          gapiLoadHandler();
        }

        pickerLoadHandlers = [];
      }
      const onLoad = () => {
        setPickerLoaded(true);
      };
      pickerLoadHandlers.push(onLoad);
      return () => {
        pickerLoadHandlers = pickerLoadHandlers.filter(v => v != onLoad);
      };
    }
  }, []);

  useEffect(() => {
    if (pickerLoaded) {
      const newPicker = builder(google.picker);
      if (picker.current) {
        picker.current.setVisible(false);
        picker.current.setCallback(NOP);
        picker.current.dispose();
      }
      picker.current = newPicker;
      picker.current.setVisible(visible);
      picker.current.setCallback(pickerCallback);
    }
  }, [pickerLoaded, builder]);

  useEffect(() => {
    if (picker.current) {
      picker.current.setVisible(visible);
    }
  }, [visible]);

  useEffect(() => {
    if (picker.current) {
      picker.current.setCallback(pickerCallback);
    }
  }, [pickerCallback]);

  return [visible, setVisible] as [typeof visible, typeof setVisible];
}
