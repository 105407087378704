class LocalStorageBackend {
    gs;
    static id = 0;
    constructor(keyName = 'rusGlobalState' + LocalStorageBackend.id++) {
        this.gs = JSON.parse(localStorage.getItem(keyName) || '{}');
        const persist = () => localStorage.setItem(keyName, JSON.stringify(this.gs));
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'hidden')
                persist();
        });
        window.addEventListener('pagehide', () => {
            persist();
        });
        window.addEventListener('beforeunload', () => {
            persist();
        });
    }
    get(k) {
        return this.gs[k];
    }
    set(k, v) {
        this.gs[k] = v;
    }
}
export default LocalStorageBackend;
