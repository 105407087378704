class MemoryBackend {
    gs = {};
    get(k) {
        return this.gs[k];
    }
    set(k, v) {
        this.gs[k] = v;
    }
}
export default MemoryBackend;
