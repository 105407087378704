import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Hide,
  HStack,
  IconButton,
  Input,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Show,
  Text,
  UnorderedList,
  useDisclosure,
  VStack
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Loading from '../../components/loading';
import { Link as RouterLink } from 'react-router-dom';
import { api, Deadline, issue, page, PermissionLevel } from '../../util/api';
import { useGlobalState } from '../../util/state';

const pad2 = (num: number) => num.toString().padStart(2, '0');

const toISODate = (time: number) => {
  const date = new Date(time);
  return `${date.getFullYear()}-${pad2(date.getMonth() + 1)}-${pad2(date.getDate())}`;
};

const toISO = (time: number) => {
  const date = new Date(time);
  return `${toISODate(time)}T${pad2(date.getHours())}:${pad2(date.getMinutes())}`;
};

const EditDeadlineModal = ({
  isOpen,
  onClose,
  onChange,
  info
}: {
  isOpen: boolean;
  onClose: () => unknown;
  onChange: (name: string, time: number) => boolean | Promise<boolean>;
  info?: Deadline;
}) => {
  const [name, setName] = useState(info ? info.name : '');
  const [time, setTime] = useState(info ? toISO(info.time) : '');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setName(info ? info.name : '');
    setTime(info ? toISO(info.time) : '');
  }, [info]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{info ? 'Edit' : 'Create'} deadline</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            <FormControl>
              <FormLabel>Deadline name</FormLabel>
              <Input
                placeholder="e.g. Production night 1"
                value={name}
                onChange={e => setName(e.currentTarget.value)}
              />
            </FormControl>
            <FormControl isInvalid={time && isNaN(Date.parse(time))}>
              <FormLabel>Deadline time</FormLabel>
              <Input
                type="datetime-local"
                value={time}
                onChange={e => setTime(e.currentTarget.value)}
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={loading}
            loadingText={info ? 'Saving...' : 'Creating...'}
            isDisabled={!name || isNaN(Date.parse(time))}
            onClick={async () => {
              const numTime = Date.parse(time);
              if (info && info.name == name && info.time == numTime) {
                onClose();
                return;
              }
              setLoading(true);
              if (await onChange(name, numTime)) {
                onClose();
              }
              setLoading(false);
            }}
          >
            {info ? 'Save' : 'Create'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const DeleteDeadlineButton = ({
  issueID,
  id,
  onDelete
}: {
  issueID: string;
  id: string;
  onDelete: () => unknown;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [allIssues, setAllIssues] = useGlobalState('issues');
  const [allPages, setAllPages] = useGlobalState('pages');
  const [conf, setConf] = useState('');
  const [loading, setLoading] = useState(false);

  const deadline = allIssues[issueID].deadlines[id];

  return (
    <>
      <IconButton
        aria-label="Delete deadline"
        size="sm"
        colorScheme="red"
        variant="outline"
        icon={<DeleteIcon />}
        onClick={onOpen}
      />
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm deletion</ModalHeader>
          <ModalBody>
            <Text as="span">
              Are you sure you want to delete the {deadline.name} deadline? Type CONFIRM below if
              you're sure.
            </Text>
            <Text as="span" fontWeight="bold">
              {' '}
              This will remove this deadline from any checklist items that have it and cannot be
              undone.
            </Text>
            <Input mt={2} onChange={e => setConf(e.currentTarget.value)} />
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={loading}
              colorScheme="red"
              variant="outline"
              isDisabled={loading || conf != 'CONFIRM'}
              onClick={async () => {
                setLoading(true);
                try {
                  await api('/assets/issue/' + issueID + '/deadlines', {
                    method: 'DELETE',
                    body: { id }
                  });
                  const newIssues = { ...allIssues };
                  const newPages = { ...allPages };
                  delete newIssues[issueID].deadlines[id];
                  for (const page in newIssues[issueID].pages) {
                    if (newPages[page]) {
                      for (const item of newPages[page].checklist) {
                        if (item.deadline == id) delete item.deadline;
                      }
                    }
                  }
                  setAllIssues(newIssues);
                  setAllPages(newPages);
                  onClose();
                  onDelete();
                } catch (err) {
                  // todo: toast?
                }
                setLoading(false);
              }}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const EditDeadlineButton = ({
  issueID,
  id,
  onEdit
}: {
  issueID: string;
  id: string;
  onEdit: (time: number) => unknown;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [allIssues, setAllIssues] = useGlobalState('issues');

  return (
    <>
      <IconButton
        aria-label="Edit deadline"
        size="sm"
        colorScheme="green"
        icon={<EditIcon />}
        onClick={onOpen}
      />
      <EditDeadlineModal
        isOpen={isOpen}
        onClose={onClose}
        onChange={async (name, time) => {
          try {
            await api('/assets/issue/' + issueID + '/deadlines', {
              method: 'PATCH',
              body: {
                id,
                name,
                time
              }
            });
            const newIssues = { ...allIssues };
            newIssues[issueID].deadlines[id] = {
              id,
              name,
              time
            };
            setAllIssues(newIssues);
            onEdit(time);
            return true;
          } catch (err) {
            // todo: toast?
            return false;
          }
        }}
        info={allIssues[issueID].deadlines[id]}
      />
    </>
  );
};

const prettyTime = (time: number) => {
  return `${prettyDate(time)}, ${prettyTimeOnly(time)}`;
};

const prettyTimeOnly = (time: number) => {
  const date = new Date(time);

  let hour = date.getHours();
  let suffix = 'AM';

  if (hour >= 12) {
    suffix = 'PM';
    hour -= 12;
  }

  if (!hour) hour = 12;

  return `${hour}:${date.getMinutes().toString().padStart(2, '0')} ${suffix}`;
};

const prettyDate = (time: number) => {
  const date = new Date(time);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const IssueDeadlines = () => {
  const { issueID, deadlineID } = useParams();
  const [allIssues, setAllIssues] = useGlobalState('issues');
  const [allPages, setAllPages] = useGlobalState('pages');
  const [userInfo] = useGlobalState('userInfo');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const issueInfo = allIssues[issueID];

  useEffect(() => {
    const ctrl = new AbortController();
    if (!issueInfo) {
      issue(issueID, ctrl.signal).then(
        info => {
          setAllIssues({
            ...allIssues,
            [issueID]: info
          });
        },
        () => {
          navigate('/issues', { replace: true });
        }
      );
    } else {
      for (const id in issueInfo.pages) {
        if (issueInfo.pages[id].canEdit && !allPages[id]) {
          page(issueID, id, ctrl.signal).then(v => {
            setAllPages(pages => ({ ...pages, [id]: v }));
          });
        }
      }
    }
    return () => ctrl.abort();
  }, [issueInfo]);

  const issueDeadlines = useMemo(
    () => issueInfo && Object.values(issueInfo.deadlines).sort((a, b) => b.time - a.time),
    [allIssues] // don't actually change issueInfo object, only mutate
  );
  const chosenPages = useMemo(
    () =>
      issueInfo &&
      Object.keys(issueInfo.pages)
        .map(v => allPages[v])
        .filter(v => v)
        .sort((a, b) => a.page - b.page),
    [allPages]
  );

  const findExpanded = () => {
    if (!issueInfo) return -1;
    return issueDeadlines.findIndex(v => v.id == deadlineID);
  };
  const [expanded, setExpanded] = useState(findExpanded);

  useEffect(() => {
    if (issueDeadlines) {
      if (expanded == -1) {
        if (deadlineID) {
          navigate('/issue/' + issueID + '/deadlines', { replace: true });
        }
      } else if (issueDeadlines[expanded].id != deadlineID) {
        navigate('/issue/' + issueID + '/deadlines/' + issueDeadlines[expanded].id, {
          replace: true
        });
      }
    }
  }, [issueDeadlines, deadlineID, expanded]);

  if (
    !issueInfo ||
    Object.keys(issueInfo.pages).filter(v => issueInfo.pages[v].canEdit).length !=
      chosenPages.length
  ) {
    return (
      <Flex w="100%" h="calc(var(--dvh) - 80px)" justify="center" align="center">
        <Loading />
      </Flex>
    );
  }

  return (
    <Box p={6}>
      <Flex direction="row" align="flex-start" justify="space-between" w="100%" mb={6}>
        <Heading lineHeight={1.1}>{issueInfo.name} deadlines</Heading>
        {userInfo.permission >= PermissionLevel.Strategic && (
          <>
            <Button ms={2} leftIcon={<AddIcon />} onClick={onOpen}>
              New deadline
            </Button>
            <EditDeadlineModal
              isOpen={isOpen}
              onClose={onClose}
              onChange={async (name, time) => {
                try {
                  const id = await api<string>('/assets/issue/' + issueID + '/deadlines', {
                    method: 'POST',
                    body: {
                      name,
                      time
                    }
                  });
                  const newIssues = { ...allIssues };
                  newIssues[issueID].deadlines[id] = {
                    id,
                    name,
                    time
                  };
                  setAllIssues(newIssues);
                  const currentTime = issueDeadlines[expanded].time;
                  if (time > currentTime) setExpanded(expanded + 1);
                  return true;
                } catch (err) {
                  // todo: toast?
                  return false;
                }
              }}
            />
          </>
        )}
      </Flex>
      {issueDeadlines && chosenPages ? (
        issueDeadlines.length > 0 ? (
          <Accordion allowToggle index={expanded} onChange={v => setExpanded(v as number)}>
            {issueDeadlines.map(deadline => {
              const incomplete = chosenPages
                .map(page => ({
                  page,
                  items: page.checklist.filter(
                    (v, i) => v.deadline == deadline.id && !page.answers[i]
                  )
                }))
                .filter(v => v.items.length);
              const nonIncompletePages = chosenPages.filter(
                v => !incomplete.some(item => item.page == v)
              );
              const complete = nonIncompletePages
                .map(page => ({
                  page,
                  items: page.checklist.filter(
                    (v, i) => v.deadline == deadline.id && page.answers[i]
                  )
                }))
                .filter(v => v.items.length);
              const overdue = deadline.time < Date.now();

              return (
                <AccordionItem key={deadline.id}>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <HStack align="center">
                          <Show above="sm">
                            <Text as="span" fontWeight="semibold">
                              {deadline.name}
                            </Text>
                            <Text as="span">({prettyTime(deadline.time)})</Text>
                          </Show>
                          <Hide above="sm">
                            <Text as="span">{deadline.name}</Text>
                          </Hide>
                          {overdue &&
                            (incomplete.length ? (
                              <Badge colorScheme="red">Incomplete</Badge>
                            ) : (
                              <Badge colorScheme="green">Completed</Badge>
                            ))}
                        </HStack>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel>
                    <Flex direction="row" justify="space-between">
                      <VStack align="flex-start">
                        {!incomplete.length && !complete.length && (
                          <Text fontStyle="italic">No checklist items assigned</Text>
                        )}
                        {incomplete.length > 0 && (
                          <Box>
                            <Text fontWeight="bold" color={overdue ? 'red.500' : null}>
                              {overdue ? 'Overdue' : 'Incomplete'} ({incomplete.length})
                            </Text>
                            <UnorderedList ps={3}>
                              {incomplete.map((item, i) => (
                                <ListItem key={i}>
                                  <HStack>
                                    <Text>
                                      {item.page.name}{' '}
                                      {item.page.name != item.page.slug && <>({item.page.slug})</>}
                                    </Text>
                                    <Button
                                      size="xs"
                                      as={RouterLink}
                                      to={'/issue/' + issueID + '/page/' + item.page.id}
                                    >
                                      View
                                    </Button>
                                  </HStack>
                                  <UnorderedList>
                                    {item.items.map((q, i) => (
                                      <ListItem key={i}>
                                        <Text fontStyle="italic">{q.question}</Text>
                                      </ListItem>
                                    ))}
                                  </UnorderedList>
                                </ListItem>
                              ))}
                            </UnorderedList>
                          </Box>
                        )}
                        {complete.length > 0 && (
                          <Box>
                            <Text fontWeight="bold" color="primary.500">
                              Complete ({complete.length})
                            </Text>
                            <UnorderedList ps={3}>
                              {complete.map((item, i) => (
                                <ListItem key={i}>
                                  <Text>
                                    {item.page.name}{' '}
                                    {item.page.name != item.page.slug && <>({item.page.slug})</>}
                                  </Text>
                                </ListItem>
                              ))}
                            </UnorderedList>
                          </Box>
                        )}
                      </VStack>
                      {userInfo.permission >= PermissionLevel.Strategic && (
                        <ButtonGroup>
                          <EditDeadlineButton
                            issueID={issueID}
                            id={deadline.id}
                            onEdit={newTime => {
                              let newLoc = issueDeadlines
                                .filter(v => v != deadline)
                                .findIndex(v => newTime > v.time);
                              if (newLoc == -1) newLoc = issueDeadlines.length - 1;
                              setExpanded(newLoc);
                            }}
                          />
                          <DeleteDeadlineButton
                            issueID={issueID}
                            id={deadline.id}
                            onDelete={() => {
                              setExpanded(-1);
                            }}
                          />
                        </ButtonGroup>
                      )}
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        ) : (
          <Text textAlign="center">
            No deadlines have been set so far.
            {userInfo.permission >= PermissionLevel.Strategic &&
              ' Click "New deadline" to create the first one.'}
          </Text>
        )
      ) : null}
    </Box>
  );
};

export default IssueDeadlines;
