export const devKey = process.env.WPO_GOOGLE_DEVELOPER_KEY;

if (!devKey) {
  throw new TypeError(
    'Cannot find WPO developer key; please rebuild app with WPO_GOOGLE_DEVELOPER_KEY=<api key>'
  );
}

const docCache: Record<string, Promise<Blob>> = {};
const docMeta: Record<
  string,
  {
    t: AbortController;
    c: number;
  }
> = {};

export function getDoc(id: string, signal?: AbortSignal) {
  if (!docMeta[id]) {
    const ctrl = new AbortController();
    docMeta[id] = {
      t: ctrl,
      c: 0
    };
    docCache[id] = fetch(
      `https://www.googleapis.com/drive/v3/files/${id}?alt=media&acknowledgeAbuse=true&key=${devKey}`,
      {
        signal: ctrl.signal
      }
    ).then(
      res => res.blob(),
      err => {
        if (process.env.NODE_ENV != 'production') {
          return null;
        }
        if (!ctrl.signal.aborted) {
          throw err;
        }
        delete docMeta[id];
        return null;
      }
    );
  }
  ++docMeta[id].c;
  if (signal) {
    const onAbort = () => {
      setTimeout(() => {
        if (!--docMeta[id].c) {
          docMeta[id].t.abort();
        }
      }, 2000);
    };
    if (signal.aborted) onAbort();
    signal.addEventListener('abort', onAbort);
  }
  return docCache[id];
}
