import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Link,
  Card,
  CardHeader,
  CardBody,
  useColorMode,
  UnorderedList,
  ListItem,
  HStack,
  VStack,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import DrivePage from '../../components/drive-page';
import Loading from '../../components/loading';
import { ChecklistQuestion, page, PageInfo } from '../../util/api';
import { useGlobalState } from '../../util/state';

const timed = (num: number, suffix: string, future: boolean) =>
  `${future ? 'in ' : ''}${num} ${suffix}${num == 1 ? '' : 's'}${future ? '' : ' ago'}`;

const prettyTimeDistance = (absTime: number) => {
  let time = Date.now() - absTime;
  let future = time < 0;
  if (future) time = -time;
  if (time < 1000) return 'now';
  time /= 1000;
  if (time < 60) return timed(Math.floor(time), 'second', future);
  time /= 60;
  if (time < 60) return timed(Math.floor(time), 'minute', future);
  time /= 60;
  if (time < 24) return timed(Math.floor(time), 'hour', future);
  time =
    (future
      ? absTime - new Date().setHours(0, 0, 0, 0)
      : new Date().setHours(23, 59, 59, 999) - absTime) /
    (1000 * 60 * 60 * 24);
  if (time < 2) return future ? 'tomorrow' : 'yesterday';
  if (time < 14) return timed(Math.floor(time), 'day', future);
  return timed(Math.floor(time / 7), 'week', future);
};

const pageLayouts = [1, 2, 2, 3, 5];

const Home = () => {
  const [currentIssue] = useGlobalState('currentIssue');
  const [allIssues] = useGlobalState('issues');
  const [allPages, setAllPages] = useGlobalState('pages');
  const { colorMode } = useColorMode();

  const issueInfo = allIssues[currentIssue];
  const myPages =
    issueInfo &&
    Object.values(issueInfo.pages)
      .filter(v => v.canEdit)
      .sort((a, b) => a.page - b.page)
      .map(v => allPages[v.id]);

  useEffect(() => {
    const ctrl = new AbortController();
    if (issueInfo) {
      for (const id in issueInfo.pages) {
        if (issueInfo.pages[id].canEdit && !allPages[id]) {
          page(currentIssue, id, ctrl.signal).then(v => {
            setAllPages(pages => ({ ...pages, [id]: v }));
          });
        }
      }
    }
    return () => ctrl.abort();
  }, [issueInfo]);

  const [overdueWork, todoWork] = useMemo(() => {
    if (!myPages) return [null, null];

    const overdueWork: Record<string, ChecklistQuestion[]> = {};
    const todoWork: Record<string, ChecklistQuestion[]> = {};

    for (const page of myPages || []) {
      if (!page) continue;
      overdueWork[page.id] = page.checklist
        .filter(
          (v, i) =>
            !page.answers[i] && v.deadline && issueInfo.deadlines[v.deadline].time < Date.now()
        )
        .sort(
          (a, b) => issueInfo.deadlines[a.deadline].time - issueInfo.deadlines[b.deadline].time
        );
      todoWork[page.id] = page.checklist
        .filter(
          (v, i) =>
            !page.answers[i] && (!v.deadline || issueInfo.deadlines[v.deadline].time >= Date.now())
        )
        .sort((a, b) =>
          a.deadline
            ? b.deadline
              ? issueInfo.deadlines[a.deadline].time - issueInfo.deadlines[b.deadline].time
              : -1
            : b.deadline
            ? 1
            : 0
        );
    }

    return [overdueWork, todoWork];
  }, [myPages]);

  if (myPages && myPages.some(v => !v)) {
    return (
      <Flex w="100%" h="calc(var(--dvh) - 80px)" justify="center" align="center">
        <Loading />
      </Flex>
    );
  }

  const pageWidths =
    myPages &&
    (myPages.length < 5
      ? pageLayouts.filter(v => v < myPages.length).concat(myPages.length)
      : pageLayouts
    ).map(n => `calc(calc(var(--dvw) - ${65 + (n - 1) * 16}px) / ${n})`);

  const genPage = (page: PageInfo) => (
    <Card
      variant="filled"
      h="100%"
      w={pageWidths}
      as={RouterLink}
      to={`/issue/${issueInfo.id}/page/${page.id}`}
      _hover={{
        boxShadow: colorMode == 'dark' ? 'dark-lg' : 'lg'
      }}
      _active={{
        boxShadow: colorMode == 'dark' ? 'dark-lg' : 'xl'
      }}
      transition="200ms box-shadow"
      bgColor={colorMode == 'dark' ? null : 'gray.200'}
    >
      <CardHeader pb={0}>
        <Heading size="lg" lineHeight={1}>
          {page.name}
        </Heading>
      </CardHeader>
      <CardBody pt={4} pb={4}>
        <Box boxShadow={colorMode == 'dark' || !page.revisions.length ? null : 'lg'}>
          {page.revisions.length ? (
            <DrivePage fileID={page.revisions[page.revisions.length - 1].id} />
          ) : (
            <Box
              w="100%"
              py="77.27%"
              pos="relative"
              bg={colorMode == 'dark' ? 'gray.600' : 'gray.300'}
            >
              <Flex
                pos="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                align="center"
                justify="center"
              >
                <Heading size={['md', 'lg']} px={2} style={{ lineHeight: 1 }}>
                  {page.slug}
                </Heading>
              </Flex>
              <Text pos="absolute" w="100%" bottom={2} left={0} textAlign="center">
                {page.page}
              </Text>
            </Box>
          )}
        </Box>
        <Box pt={1}>
          <Text fontSize="sm">
            Last updated{' '}
            {prettyTimeDistance(
              page.revisions.length
                ? page.revisions[page.revisions.length - 1].timestamp
                : issueInfo.startTime
            )}
          </Text>
        </Box>
        {overdueWork[page.id].length > 0 && (
          <Box pt={2}>
            <Text fontWeight="bold" color="red.500">
              Overdue ({overdueWork[page.id].length})
            </Text>
            <UnorderedList ps={3}>
              {overdueWork[page.id].map((item, i) => (
                <ListItem key={i} pb={1}>
                  <Text fontWeight="bold" lineHeight={1.2}>
                    {item.question} (due{' '}
                    {prettyTimeDistance(issueInfo.deadlines[item.deadline].time)})
                  </Text>
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        )}
        {todoWork[page.id].length > 0 && (
          <Box pt={2}>
            <Text fontWeight="bold">To do ({todoWork[page.id].length})</Text>
            <UnorderedList ps={3}>
              {todoWork[page.id].map((item, i) => (
                <ListItem key={i} pb={1}>
                  <Text lineHeight={1.2}>
                    {item.question}
                    {item.deadline && (
                      <> (due {prettyTimeDistance(issueInfo.deadlines[item.deadline].time)})</>
                    )}
                  </Text>
                </ListItem>
              ))}
            </UnorderedList>
          </Box>
        )}
        {!overdueWork[page.id].length && !todoWork[page.id].length && (
          <Box pt={2}>
            <Text fontWeight="bold" color="primary.500">
              All done!
            </Text>
          </Box>
        )}
      </CardBody>
    </Card>
  );

  return (
    <Box p={6}>
      <Flex minH="calc(var(--dvh) - 128px)" w="100%" direction="column" justify="space-between">
        {issueInfo ? (
          <Flex direction="column" justify="space-between" w="100%" h="100%" flex={1}>
            <Box w="100%" h="100%">
              <Heading lineHeight={1} mb={6}>
                {issueInfo.name} Dashboard
              </Heading>
              {myPages.length ? (
                <Wrap spacing={4} justify="center">
                  {myPages.map(page => (
                    <WrapItem key={page.id}>{genPage(page)}</WrapItem>
                  ))}
                </Wrap>
              ) : (
                <Text>
                  You haven't yet been assigned any pages this issue. For now, take a look at the
                  issue's{' '}
                  <Link textDecor="underline" as={RouterLink} to="/issue">
                    current progress
                  </Link>
                  .
                </Text>
              )}
            </Box>
          </Flex>
        ) : (
          <Box>
            <Heading lineHeight={1} mb={6}>
              Welcome to Winged Post Organizer!
            </Heading>
            <Text>
              Winged Post production hasn't started yet. For now, take a look at some{' '}
              <Link textDecor="underline" as={RouterLink} to="/issues">
                past issues
              </Link>
              .
            </Text>
          </Box>
        )}
        <Flex
          fontSize="sm"
          fontWeight="bold"
          direction="row"
          w="100%"
          mt={8}
          justify="center"
          align="center"
        >
          <Text>Created by Arjun Barrett '23</Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Home;
