import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const colorSchemes = ['red', 'orange', 'yellow', 'green', 'teal', 'blue', 'cyan', 'purple', 'pink'];

export function colorHash(src: string) {
  let base = 0;
  for (let i = 0; i < src.length; ++i) {
    base = (base * src.charCodeAt(src.length - i - 1) + src.charCodeAt(i)) % colorSchemes.length;
  }
  return colorSchemes[base];
}

export const darkMatch = matchMedia('(prefers-color-scheme: dark)');

export default extendTheme(
  {
    colors: {
      primary: {
        50: '#e5f9eb',
        100: '#c6e9d1',
        200: '#a5d9b7',
        300: '#84ca9d',
        400: '#62ba85',
        500: '#48a16f',
        600: '#377d58',
        700: '#265a40',
        800: '#143624',
        900: '#001407'
      }
    },
    config: {
      initialColorMode:
        localStorage.getItem('force-color') || (darkMatch.matches ? 'dark' : 'light')
    }
  },
  withDefaultColorScheme({
    colorScheme: 'primary'
  })
);
