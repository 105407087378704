import {
  Box,
  Button,
  Menu,
  MenuProps,
  MenuButton,
  MenuItem,
  MenuList,
  TagProps,
  useDisclosure,
  IconButton,
  Popover,
  PopoverAnchor,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  FormControl,
  FormLabel,
  Input,
  ButtonGroup,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  HStack,
  Text,
  Portal,
  useOutsideClick,
  Flex,
  MenuItemProps
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon, EditIcon, SmallAddIcon } from '@chakra-ui/icons';
import { useGlobalState } from '../util/state';
import { api, Role } from '../util/api';
import RoleTag from './role-tag';
import FocusLock from 'react-focus-lock';
import { createRef, MutableRefObject, RefObject, useEffect, useRef, useState } from 'react';

const EditableRoleItem = ({
  containerRef,
  shouldClose,
  role,
  onSelect,
  onSubOpen,
  onSubClose,
  editRef,
  delRef,
  ...props
}: {
  role: Role;
  onSelect: () => void;
  onSubOpen: () => void;
  onSubClose: () => void;
  containerRef?: RefObject<HTMLElement>;
  shouldClose: boolean;
  editRef: MutableRefObject<HTMLElement>;
  delRef: MutableRefObject<HTMLElement>;
} & Omit<MenuItemProps, 'role' | 'children'>) => {
  const [loading, setLoading] = useState(false);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const delDisclosure = useDisclosure();
  const [editName, setEditName] = useState(role.name);
  const editButtonRef = useRef<HTMLButtonElement & HTMLDivElement>();
  const delButtonRef = useRef<HTMLButtonElement & HTMLDivElement>();
  const [myInfo, setMyInfo] = useGlobalState('userInfo');
  const [globalRoles, setGlobalRoles] = useGlobalState('roles');
  const [globalUsers, setGlobalUsers] = useGlobalState('users');
  const [globalInvites, setGlobalInvites] = useGlobalState('invites');
  const [globalPages, setGlobalPages] = useGlobalState('pages');

  useEffect(() => {
    if (shouldClose) {
      onClose();
      delDisclosure.onClose();
    }
  }, [shouldClose]);

  useEffect(() => {
    if (isOpen || delDisclosure.isOpen) onSubOpen();
    else onSubClose();
  }, [isOpen || delDisclosure.isOpen]);

  return (
    <Popover isLazy closeOnBlur={false} isOpen={isOpen}>
      <MenuItem
        onClick={evt => {
          if (
            !editButtonRef.current?.contains(evt.target as HTMLElement) &&
            !delButtonRef.current?.contains(evt.target as HTMLElement) &&
            !editRef.current?.contains(evt.target as HTMLElement) &&
            !delRef.current?.contains(evt.target as HTMLElement)
          ) {
            onSelect();
          }
        }}
        {...props}
      >
        <Flex justify="space-between" width="100%" height="100%">
          <RoleTag role={role} {...props} />
          <ButtonGroup>
            <PopoverAnchor>
              <IconButton
                as="div"
                icon={<EditIcon />}
                ref={editButtonRef}
                onClick={() => {
                  delDisclosure.onClose();
                  onToggle();
                }}
                aria-label="Edit role"
                isDisabled={loading}
                size="xs"
              />
            </PopoverAnchor>
            <Popover isLazy closeOnBlur={false} isOpen={delDisclosure.isOpen}>
              <PopoverAnchor>
                <IconButton
                  as="div"
                  icon={<DeleteIcon />}
                  ref={delButtonRef}
                  onClick={() => {
                    delDisclosure.onToggle();
                    onClose();
                  }}
                  aria-label="Delete role"
                  isDisabled={loading}
                  size="xs"
                />
              </PopoverAnchor>
              <Portal containerRef={containerRef}>
                <PopoverContent ref={delRef}>
                  <PopoverArrow />
                  <PopoverHeader>Confirm deletion</PopoverHeader>
                  <PopoverBody>
                    Are you sure you want to delete the {role.name} role? It will be removed from
                    any pages and/or users that still have it.
                  </PopoverBody>
                  <PopoverFooter>
                    <ButtonGroup display="flex" justifyContent="flex-end">
                      <Button
                        isDisabled={loading}
                        variant="outline"
                        onClick={delDisclosure.onClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        isDisabled={loading}
                        isLoading={loading}
                        onClick={async () => {
                          delDisclosure.onClose();
                          setLoading(true);
                          try {
                            await api('/users/roles', {
                              method: 'DELETE',
                              body: { id: role.id }
                            });

                            const filter = <T extends Record<string, { roles: string[] }>>(
                              input: T,
                              inplace?: boolean
                            ) => {
                              const output = inplace ? input : { ...input };
                              for (const k in output) {
                                output[k].roles = output[k].roles.filter(v => v != role.id);
                              }
                              return output;
                            };

                            const newRoles = { ...globalRoles };
                            delete newRoles[role.id];
                            setGlobalRoles(newRoles);
                            setGlobalPages(filter(globalPages));
                            if (globalUsers) setGlobalUsers(filter(globalUsers));
                            if (globalInvites) setGlobalInvites(filter(globalInvites));
                            const newInfo = { ...myInfo };
                            newInfo.roles = newInfo.roles.filter(v => v != role.id);
                            setMyInfo(newInfo);
                          } catch (err) {
                            // todo: toast?
                          }
                          setLoading(false);
                        }}
                      >
                        Confirm
                      </Button>
                    </ButtonGroup>
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            </Popover>
          </ButtonGroup>
        </Flex>
      </MenuItem>
      <Portal containerRef={containerRef}>
        <FocusLock returnFocus>
          <PopoverContent ref={editRef}>
            <PopoverArrow />
            <PopoverBody>
              <FormControl>
                <FormLabel>Role name</FormLabel>
                <Input value={editName} onChange={e => setEditName(e.currentTarget.value)} />
              </FormControl>
            </PopoverBody>
            <PopoverFooter>
              <ButtonGroup display="flex" justifyContent="flex-end">
                <Button isDisabled={loading} variant="outline" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  isDisabled={!editName || editName == role.name || loading}
                  isLoading={loading}
                  onClick={async () => {
                    onClose();
                    setLoading(true);
                    try {
                      await api('/users/roles', {
                        method: 'PATCH',
                        body: { id: role.id, name: editName }
                      });
                      const newRoles = { ...globalRoles };
                      newRoles[role.id] = {
                        id: role.id,
                        name: editName
                      };
                      setGlobalRoles(newRoles);
                    } catch (err) {
                      // todo: toast?
                    }
                    setLoading(false);
                  }}
                >
                  Save
                </Button>
              </ButtonGroup>
            </PopoverFooter>
          </PopoverContent>
        </FocusLock>
      </Portal>
    </Popover>
  );
};

const AddRoleMenu = ({
  roles,
  canEdit,
  onSelect,
  containerRef,
  ...props
}: {
  roles: Role[];
  canEdit: boolean;
  onSelect: (role: Role) => unknown;
  containerRef?: RefObject<HTMLElement>;
} & Omit<MenuProps, 'children'>) => {
  const [globalRoles, setGlobalRoles] = useGlobalState('roles');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuDisclosure = useDisclosure();
  const [name, setName] = useState('');
  const contentRef = useRef<HTMLDivElement>();
  const buttonRef = useRef<HTMLButtonElement>();
  const menuRef = useRef<HTMLDivElement>();
  const innerRefMap = useRef<Record<string, HTMLElement>>({});
  const [loading, setLoading] = useState(false);
  const [openedRole, setOpenedRole] = useState('');

  useEffect(() => {
    if (!menuDisclosure.isOpen) onClose();
  }, [menuDisclosure.isOpen]);

  useEffect(() => {
    if (openedRole) onClose();
  }, [openedRole]);

  useOutsideClick({
    ref: menuRef,
    handler: evt => {
      if (
        !buttonRef.current?.contains(evt.target as HTMLElement) &&
        !contentRef.current?.contains(evt.target as HTMLElement) &&
        !innerRefMap[openedRole + '-edit']?.current?.contains(evt.target as HTMLElement) &&
        !innerRefMap[openedRole + '-del']?.current?.contains(evt.target as HTMLElement)
      ) {
        menuDisclosure.onClose();
      }
    }
  });

  return (
    <Box>
      <Menu
        isLazy
        lazyBehavior="keepMounted"
        closeOnBlur={false}
        closeOnSelect={false}
        isOpen={menuDisclosure.isOpen}
        onOpen={menuDisclosure.onOpen}
        onClose={menuDisclosure.onClose}
        {...props}
      >
        <MenuButton ref={buttonRef} as={Button} colorScheme="gray" leftIcon={<AddIcon />} size="xs">
          Add role
        </MenuButton>
        <Portal containerRef={containerRef}>
          <MenuList ref={menuRef}>
            {roles.map(role =>
              canEdit ? (
                <EditableRoleItem
                  key={role.id}
                  shouldClose={
                    !menuDisclosure.isOpen || isOpen || (openedRole && openedRole != role.id)
                  }
                  containerRef={containerRef}
                  onSubOpen={() => setOpenedRole(role.id)}
                  onSubClose={() => {
                    if (openedRole == role.id) setOpenedRole('');
                  }}
                  editRef={(innerRefMap[role.id + '-edit'] ||= createRef())}
                  delRef={(innerRefMap[role.id + '-del'] ||= createRef())}
                  role={role}
                  onSelect={() => {
                    if (menuDisclosure.isOpen) {
                      menuDisclosure.onClose();
                      onSelect(role);
                    }
                  }}
                />
              ) : (
                <MenuItem
                  key={role.id}
                  onClick={() => {
                    if (menuDisclosure.isOpen) {
                      menuDisclosure.onClose();
                      onSelect(role);
                    }
                  }}
                >
                  <RoleTag role={role} />
                </MenuItem>
              )
            )}
            {canEdit && (
              <Popover isLazy closeOnBlur={false} isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
                <PopoverTrigger>
                  <MenuItem>
                    <HStack>
                      <SmallAddIcon />
                      <Text>New role</Text>
                    </HStack>
                  </MenuItem>
                </PopoverTrigger>
                <Portal containerRef={containerRef}>
                  <FocusLock returnFocus>
                    <PopoverContent ref={contentRef}>
                      <PopoverArrow />
                      <PopoverBody>
                        <FormControl>
                          <FormLabel>Role name</FormLabel>
                          <Input value={name} onChange={e => setName(e.currentTarget.value)} />
                        </FormControl>
                      </PopoverBody>
                      <PopoverFooter>
                        <ButtonGroup display="flex" justifyContent="flex-end">
                          <Button isDisabled={loading} variant="outline" onClick={onClose}>
                            Cancel
                          </Button>
                          <Button
                            isDisabled={!name || loading}
                            isLoading={loading}
                            loadingText="Adding..."
                            onClick={async () => {
                              setLoading(true);
                              try {
                                const id = await api<string>('/users/roles', {
                                  method: 'POST',
                                  body: { name }
                                });
                                const newRoles = { ...globalRoles };
                                newRoles[id] = {
                                  id,
                                  name
                                };
                                setGlobalRoles(newRoles);
                              } catch (err) {
                                // todo: toast?
                              }
                              setName('');
                              setLoading(false);
                              onClose();
                            }}
                          >
                            Add
                          </Button>
                        </ButtonGroup>
                      </PopoverFooter>
                    </PopoverContent>
                  </FocusLock>
                </Portal>
              </Popover>
            )}
          </MenuList>
        </Portal>
      </Menu>
    </Box>
  );
};

export default AddRoleMenu;
