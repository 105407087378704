import {
  AddIcon,
  ArrowUpIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon
} from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Hide,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Show,
  Spinner,
  Stack,
  StackDivider,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useDisclosure,
  useMediaQuery,
  VStack,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import AddRoleMenu from '../../components/add-role-menu';
import DrivePage from '../../components/drive-page';
import Loading from '../../components/loading';
import PDFPage from '../../components/pdf-page';
import RemovableRole from '../../components/removable-role';
import RoleTag from '../../components/role-tag';
import {
  api,
  page,
  issue,
  roles,
  APIError,
  PermissionLevel,
  ChecklistQuestion,
  Deadline,
  users,
  PageRevision
} from '../../util/api';
import { getDoc } from '../../util/google';
import { useGlobalState } from '../../util/state';
import { useBlocker } from '../../util/use-blocker';
import { PDFDocument, PDFImage } from 'pdf-lib';

const DeletePageModal = ({
  isOpen,
  onClose,
  issueID,
  pageID
}: {
  isOpen: boolean;
  onClose: () => unknown;
  issueID: string;
  pageID: string;
}) => {
  const [allIssues, setAllIssues] = useGlobalState('issues');
  const [allPages, setAllPages] = useGlobalState('pages');
  const [conf, setConf] = useState('');
  const [loading, setLoading] = useState(false);

  const info = allPages[pageID] || allIssues[issueID]?.pages[pageID];

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm deletion</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text as="span">
            Are you sure you want to delete {info.name}? Type CONFIRM below if you're sure.
          </Text>
          <Text as="span" fontWeight="bold">
            {' '}
            This will remove all versions of the page and cannot be undone.
          </Text>
          <Input mt={2} value={conf} onChange={e => setConf(e.currentTarget.value)} />
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={loading}
            colorScheme="red"
            variant="outline"
            isDisabled={conf != 'CONFIRM' || loading}
            onClick={async () => {
              setLoading(true);
              try {
                await api('/assets/issue/' + issueID + '/page/' + pageID, {
                  method: 'DELETE'
                });
                if (allIssues[issueID]) {
                  const newIssues = { ...allIssues };
                  delete newIssues[issueID].pages[pageID];
                  setAllIssues(newIssues);
                }
                if (allPages[pageID]) {
                  const newPages = { ...allPages };
                  delete newPages[pageID];
                  setAllPages(newPages);
                }
                onClose();
              } catch (err) {
                // todo: toast?
              }
              setLoading(false);
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const prettyTime = (time: number) => {
  return `${prettyDate(time)}, ${prettyTimeOnly(time)}`;
};

const prettyTimeOnly = (time: number) => {
  const date = new Date(time);

  let hour = date.getHours();
  let suffix = 'AM';

  if (hour >= 12) {
    suffix = 'PM';
    hour -= 12;
  }

  if (!hour) hour = 12;

  return `${hour}:${date.getMinutes().toString().padStart(2, '0')} ${suffix}`;
};

const prettyDate = (time: number) => {
  const date = new Date(time);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const EditableChecklistQuestion = ({
  info,
  disabled,
  answer,
  canEdit,
  deadlines,
  onChange,
  onAnswer,
  onDelete
}: {
  info: ChecklistQuestion;
  disabled: boolean;
  answer: boolean;
  deadlines: Record<string, Deadline>;
  canEdit: boolean;
  onChange: (question: ChecklistQuestion) => unknown;
  onAnswer: (answer: boolean) => boolean | Promise<boolean>;
  onDelete: () => unknown;
}) => {
  const [loading, setLoading] = useState(false);
  const [curAnswer, setCurAnswer] = useState(answer);
  const [question, setQuestion] = useState(info.question);
  const [deadline, setDeadline] = useState(info.deadline);
  const [editing, setEditing] = useState(false);

  const reset = () => {
    setQuestion(info.question);
    setDeadline(info.deadline);
  };

  useEffect(reset, [info]);

  useEffect(() => setCurAnswer(answer), [answer]);

  return (
    <Tr>
      <Td ps={0}>
        {editing ? (
          <Input
            isDisabled={disabled || loading}
            value={question}
            onChange={e => setQuestion(e.currentTarget.value)}
          />
        ) : (
          <HStack>
            <Text fontWeight="semibold">{question}</Text>
            {info.deadline && Date.now() >= deadlines[info.deadline].time && !answer && (
              <Badge colorScheme="red">Overdue</Badge>
            )}
          </HStack>
        )}
      </Td>
      <Td>
        <Checkbox
          isChecked={curAnswer}
          disabled={!canEdit}
          onChange={async e => {
            if (disabled) return;
            const checked = e.currentTarget.checked;
            setCurAnswer(checked);
            if (!(await onAnswer(checked))) {
              setCurAnswer(!checked);
            }
          }}
        />
      </Td>
      <Td isNumeric={!canEdit} pe={canEdit ? null : 1}>
        {editing ? (
          <Select
            isDisabled={disabled || loading}
            value={deadline == null ? 'none' : deadline}
            onChange={evt => {
              setDeadline(evt.currentTarget.value == 'none' ? undefined : evt.currentTarget.value);
            }}
          >
            <option value="none">None</option>
            {Object.keys(deadlines).map(key => (
              <option key={key} value={key}>
                {deadlines[key].name} ({prettyTime(deadlines[key].time)})
              </option>
            ))}
          </Select>
        ) : (
          <Text fontWeight="semibold">
            {deadline == null
              ? 'None'
              : `${deadlines[deadline].name} (${prettyTime(deadlines[deadline].time)})`}
          </Text>
        )}
      </Td>
      {canEdit && (
        <Td isNumeric pe={1}>
          <ButtonGroup>
            {editing ? (
              <>
                <IconButton
                  aria-label="Save"
                  icon={<CheckIcon />}
                  isDisabled={!question || disabled || loading}
                  colorScheme="green"
                  onClick={async () => {
                    if (question == info.question && deadline == info.deadline) {
                      setEditing(false);
                      return;
                    }
                    setLoading(true);
                    await onChange({
                      question,
                      deadline
                    });
                    setLoading(false);
                    setEditing(false);
                  }}
                />
                <IconButton
                  aria-label="Cancel"
                  variant="outline"
                  icon={<CloseIcon />}
                  isDisabled={loading}
                  colorScheme="red"
                  onClick={() => {
                    setEditing(false);
                    reset();
                  }}
                />
              </>
            ) : (
              <>
                <IconButton
                  aria-label="Edit"
                  icon={<EditIcon />}
                  isDisabled={loading}
                  colorScheme="gray"
                  onClick={() => {
                    setEditing(true);
                  }}
                />
                <IconButton
                  aria-label="Delete"
                  variant="outline"
                  icon={<DeleteIcon />}
                  isDisabled={disabled || loading}
                  colorScheme="red"
                  onClick={async () => {
                    setLoading(true);
                    await onDelete();
                    setLoading(false);
                  }}
                />
              </>
            )}
          </ButtonGroup>
        </Td>
      )}
    </Tr>
  );
};

const IssuePage = () => {
  const { issueID, pageID } = useParams();
  const [allIssues, setAllIssues] = useGlobalState('issues');
  const [allUsers, setAllUsers] = useGlobalState('users');
  const [allPages, setAllPages] = useGlobalState('pages');
  const [userInfo] = useGlobalState('userInfo');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const [pdfData, setPDFData] = useState<Blob | null>(null);
  const [pdfLink, setPDFLink] = useState('');
  const [downloadingPDF, setDownloadingPDF] = useState(false);
  const [pdfLoad, setPDFLoad] = useState(false);
  const [editingName, setEditingName] = useState(false);
  const [savingName, setSavingName] = useState(false);
  const [editingSlug, setEditingSlug] = useState(false);
  const [savingSlug, setSavingSlug] = useState(false);
  const navigate = useNavigate();
  const [allRoles, setAllRoles] = useGlobalState('roles');

  useEffect(() => {
    const ctrl = new AbortController();
    if (!allRoles) {
      roles(ctrl.signal).then(setAllRoles);
    }
    return () => ctrl.abort();
  }, []);

  useEffect(() => {
    if (!pdfData) setPDFLink('');
    else {
      const link = URL.createObjectURL(pdfData);
      setPDFLink(link);
      return () => URL.revokeObjectURL(link);
    }
  }, [pdfData]);

  const issueInfo = allIssues[issueID];
  const baseInfo = issueInfo?.pages[pageID];
  const info = allPages[pageID];

  useEffect(() => {
    if (baseInfo) {
      const pages = Object.values(issueInfo.pages).sort((a, b) => a.page - b.page);
      const ind = pages.indexOf(baseInfo);

      const keydownHandler = (evt: KeyboardEvent) => {
        if (evt.target instanceof HTMLInputElement) return;

        if (evt.key == 'ArrowLeft') {
          if (ind > 0) {
            navigate('/issue/' + issueID + '/page/' + pages[ind - 1].id, {
              replace: true
            });
          }
        } else if (evt.key == 'ArrowRight') {
          if (ind < pages.length - 1) {
            navigate('/issue/' + issueID + '/page/' + pages[ind + 1].id, {
              replace: true
            });
          }
        }
      };

      window.addEventListener('keydown', keydownHandler, { passive: true });
      return () => window.removeEventListener('keydown', keydownHandler);
    }
  }, [baseInfo]);

  useEffect(() => {
    const ctrl = new AbortController();
    if (issueInfo && !baseInfo) {
      navigate('/issue/' + issueID, { replace: true });
    } else if ((!baseInfo || baseInfo.canEdit) && !info) {
      page(issueID, pageID, ctrl.signal).then(
        info => {
          setAllPages({
            ...allPages,
            [pageID]: info
          });
        },
        err => {
          if (!(err instanceof APIError && err.status == 403)) {
            navigate('/issue/' + issueID, { replace: true });
          }
        }
      );
    }
    return () => ctrl.abort();
  }, [info]);

  useEffect(() => {
    const ctrl = new AbortController();
    if (info && !allUsers) {
      users(ctrl.signal).then(setAllUsers);
    }
    return () => ctrl.abort();
  }, [info]);

  useEffect(() => {
    const ctrl = new AbortController();
    if (!issueInfo) {
      issue(issueID, ctrl.signal).then(
        info => {
          setAllIssues({
            ...allIssues,
            [issueID]: info
          });
        },
        () => {
          navigate('/issues', { replace: true });
        }
      );
    }
    return () => ctrl.abort();
  }, [issueInfo]);

  const latestRevision = info && info.revisions[info.revisions.length - 1];
  const pdf = latestRevision?.id || baseInfo?.pdf;

  const isHorizontal = () =>
    pdf &&
    ((window.innerHeight - 224) * 11) / 17 + 580 <= window.innerWidth &&
    window.innerHeight > 640;
  const [horizontal, setHorizontal] = useState(isHorizontal);

  const refresh = () => setHorizontal(isHorizontal());

  useEffect(() => {
    window.addEventListener('resize', refresh, { passive: true });
    return () => window.removeEventListener('resize', refresh);
  }, []);

  useEffect(refresh, [pdf]);

  const name = info?.name || baseInfo?.name;
  const slug = info?.slug || baseInfo?.slug;

  const [editName, setEditName] = useState<string>(name || '');
  const [editSlug, setEditSlug] = useState<string>(slug || '');
  const [newQuestion, setNewQuestion] = useState<string | null>('');
  const [newDeadline, setNewDeadline] = useState<string | undefined>();
  const [editingChecklist, setEditingChecklist] = useState(false);

  useEffect(() => {
    setEditName(name || '');
  }, [name]);

  useEffect(() => {
    setEditSlug(slug || '');
  }, [slug]);

  useEffect(() => {
    setNewQuestion('');
    setNewDeadline(undefined);
  }, [info && info.checklist]);

  useEffect(() => {
    setPDFData(null);

    setPDFLoad(false);
    if (pdf) {
      const ctrl = new AbortController();
      getDoc(pdf, ctrl.signal).then(setPDFData);
      return () => ctrl.abort();
    }
  }, [pdf]);

  const downloadPDF = () => {
    const link = document.createElement('a');
    link.href = pdfLink;
    link.download = issueInfo.prefix + baseInfo.slug + issueInfo.suffix + '.pdf';
    link.click();
  };

  useEffect(() => {
    if (downloadingPDF && pdfData && issueInfo) {
      downloadPDF();
      setDownloadingPDF(false);
    }
  }, [pdfData, downloadingPDF, issueInfo]);

  if (!baseInfo || (baseInfo && baseInfo.canEdit && !info)) {
    return (
      <Flex w="100%" h="calc(var(--dvh) - 80px)" justify="center" align="center">
        <Loading />
      </Flex>
    );
  }

  let linkDoubletruck = false;
  if (info && issueInfo.doubletruck && info.page - (info.page & 1) == issueInfo.numPages / 2) {
    const neighbor = Object.values(issueInfo.pages).find(v => v.page == (info.page ^ 1));
    linkDoubletruck = neighbor && neighbor.canEdit;
  }
  const deadlineValues = Object.values(issueInfo.deadlines);
  const indd = info ? info.inddURL : baseInfo.indd;
  const pageNum = info ? info.page : baseInfo.page;
  const modified = latestRevision?.timestamp || baseInfo?.modified;

  return (
    <Box p={6}>
      <Flex justify="space-between" align="flex-start" w="100%" mb={4}>
        <Wrap spacing={4} align="center" pe={4} overflow="visible">
          {editingName ? (
            <WrapItem>
              <Input
                value={editName}
                onChange={e => setEditName(e.currentTarget.value)}
                htmlSize={name.length}
              />
            </WrapItem>
          ) : (
            <WrapItem>
              <Heading lineHeight={1}>{name}</Heading>
            </WrapItem>
          )}
          {userInfo.permission >= PermissionLevel.Strategic && (
            <WrapItem>
              <ButtonGroup>
                {editingName ? (
                  <>
                    <IconButton
                      aria-label="Save"
                      icon={<CheckIcon />}
                      isDisabled={savingName || !editName}
                      onClick={async () => {
                        if (editName == name) {
                          setEditingName(false);
                          return;
                        }
                        setSavingName(true);
                        try {
                          await api('/assets/issue/' + issueID + '/page/' + pageID, {
                            method: 'PATCH',
                            body: {
                              name: editName
                            }
                          });
                          const newIssues = { ...allIssues };
                          baseInfo.name = editName;
                          setAllIssues(newIssues);
                          setAllPages({
                            ...allPages,
                            [pageID]: {
                              ...info,
                              name: editName
                            }
                          });
                          setEditingName(false);
                        } catch (err) {
                          // todo: toast?
                        }
                        setSavingName(false);
                      }}
                    />
                    <IconButton
                      aria-label="Cancel"
                      variant="outline"
                      isDisabled={savingName}
                      colorScheme="red"
                      icon={<CloseIcon />}
                      onClick={() => setEditingName(false)}
                    />
                  </>
                ) : (
                  <>
                    <IconButton
                      aria-label="Edit name"
                      variant="outline"
                      icon={<EditIcon />}
                      onClick={() => setEditingName(true)}
                    />
                    <IconButton
                      aria-label="Delete page"
                      variant="outline"
                      colorScheme="red"
                      icon={<DeleteIcon />}
                      onClick={onOpen}
                    />
                  </>
                )}
                <DeletePageModal
                  issueID={issueID}
                  pageID={pageID}
                  isOpen={isOpen}
                  onClose={onClose}
                />
              </ButtonGroup>
            </WrapItem>
          )}
        </Wrap>
        {info && (
          <Button
            as={RouterLink}
            to={
              '/issue/' +
              issueID +
              '/page/' +
              (linkDoubletruck ? 'doubletruck' : pageID) +
              '/upload'
            }
            leftIcon={<AddIcon />}
          >
            New version
          </Button>
        )}
      </Flex>
      <Flex direction={horizontal ? 'row' : 'column'} justify="space-between" w="100%">
        {pdf && (
          <VStack>
            <Box
              h={horizontal ? 'calc(var(--dvh) - 224px)' : null}
              w={horizontal ? 'calc(calc(0.6471 * var(--dvh)) - 144.94px)' : '100%'}
              boxShadow={colorMode == 'dark' ? null : 'lg'}
              bgColor={pdfLoad ? 'white' : 'transparent'}
              as={horizontal ? 'a' : null}
              href={horizontal ? pdfLink : null}
              target={horizontal ? '_blank' : null}
              cursor={horizontal ? 'zoom-in' : null}
            >
              <DrivePage
                fileID={pdf}
                onLoad={() => {
                  setPDFLoad(true);
                }}
              />
            </Box>
            <Flex
              direction="row"
              justify="space-between"
              w={horizontal ? 'calc(calc(0.6471 * var(--dvh)) - 144.94px)' : '100%'}
            >
              <Button
                size={horizontal ? 'sm' : ['sm', 'sm', 'md']}
                leftIcon={<DownloadIcon />}
                colorScheme="green"
                isLoading={downloadingPDF}
                onClick={() => {
                  if (pdfData && baseInfo) {
                    downloadPDF();
                  } else {
                    setDownloadingPDF(true);
                  }
                }}
              >
                PDF
              </Button>
              <Button
                size={horizontal ? 'sm' : ['sm', 'sm', 'md']}
                variant="outline"
                leftIcon={<DownloadIcon />}
                isDisabled={!indd}
                colorScheme="green"
                as="a"
                href={indd}
                target="_blank"
              >
                INDD
                {info && info.inddRev != null && info.inddRev < info.revisions.length - 1
                  ? ' (outdated)'
                  : ''}
              </Button>
            </Flex>
          </VStack>
        )}
        <Flex
          direction="column"
          flexGrow={1}
          ms={horizontal ? 6 : 0}
          mt={horizontal ? 0 : 6}
          align="flex-start"
          height={horizontal ? 'calc(var(--dvh) - 224px)' : null}
        >
          <Flex
            direction="row"
            justify="space-between"
            align={info && info.revisions.length == 0 ? 'center' : 'flex-start'}
            w="100%"
          >
            <Flex direction="column" align="flex-start">
              <Heading size="md" lineHeight={1}>
                Filename
              </Heading>
              <HStack mt={0.5}>
                <Flex direction="row" align="center" fontSize="md">
                  <Text as="span">{issueInfo.prefix}</Text>
                  {editingSlug ? (
                    <Input
                      size="xs"
                      w="auto"
                      htmlSize={slug.length}
                      value={editSlug}
                      onChange={e => setEditSlug(e.currentTarget.value)}
                    />
                  ) : (
                    <Text as="span">{slug}</Text>
                  )}
                  <Text as="span" whiteSpace="nowrap">
                    {issueInfo.suffix}
                  </Text>
                </Flex>
                {userInfo.permission >= PermissionLevel.Strategic &&
                  (editingSlug ? (
                    <ButtonGroup>
                      <IconButton
                        aria-label="Save"
                        icon={<CheckIcon />}
                        isDisabled={savingSlug || !editSlug}
                        isLoading={savingSlug}
                        size="xs"
                        onClick={async () => {
                          if (editSlug == slug) {
                            setEditingSlug(false);
                            return;
                          }
                          setSavingSlug(true);
                          try {
                            await api('/assets/issue/' + issueID + '/page/' + pageID, {
                              method: 'PATCH',
                              body: {
                                slug: editSlug
                              }
                            });
                            const newIssues = { ...allIssues };
                            baseInfo.slug = editSlug;
                            setAllIssues(newIssues);
                            setAllPages({
                              ...allPages,
                              [pageID]: {
                                ...info,
                                slug: editSlug
                              }
                            });
                            setEditingSlug(false);
                          } catch (err) {
                            // todo: toast?
                          }
                          setSavingSlug(false);
                        }}
                      />
                      <IconButton
                        aria-label="Cancel"
                        variant="outline"
                        isDisabled={savingSlug}
                        colorScheme="red"
                        icon={<CloseIcon />}
                        size="xs"
                        onClick={() => setEditingSlug(false)}
                      />
                    </ButtonGroup>
                  ) : (
                    <IconButton
                      aria-label="Edit filename"
                      icon={<EditIcon />}
                      size="xs"
                      onClick={() => setEditingSlug(true)}
                    />
                  ))}
              </HStack>
              <Heading size="md" lineHeight={1} mt={4}>
                Issue
              </Heading>
              <Text size="md">{issueInfo.name}</Text>
              <Heading size="md" lineHeight={1} mt={4}>
                Folio
              </Heading>
              <Text size="md">{prettyDate(issueInfo.folio)}</Text>
              <Heading size="md" lineHeight={1} mt={4}>
                Page
              </Heading>
              <Text fontSize="md">
                {pageNum} of {issueInfo.numPages}
              </Text>
              {modified && (
                <>
                  <Heading size="md" lineHeight={1} mt={4}>
                    Last updated
                  </Heading>
                  <Text fontSize="md">{prettyTime(modified)}</Text>
                </>
              )}
              {info &&
                allRoles &&
                (info.roles.length > 0 || userInfo.permission >= PermissionLevel.Strategic) && (
                  <>
                    <Heading size="md" lineHeight={1} mt={4}>
                      Roles
                    </Heading>
                    <Wrap align="flex-end" pt={1} pb={1}>
                      {info.roles.map(id => (
                        <WrapItem key={id}>
                          {userInfo.permission >= PermissionLevel.Strategic ? (
                            <RemovableRole
                              role={allRoles[id]}
                              onRemove={async () => {
                                try {
                                  const newRoles = info.roles.filter(v => v != id);
                                  await api('/assets/issue/' + issueID + '/page/' + pageID, {
                                    method: 'PATCH',
                                    body: {
                                      roles: newRoles
                                    }
                                  });
                                  setAllPages({
                                    ...allPages,
                                    [pageID]: {
                                      ...info,
                                      roles: newRoles
                                    }
                                  });
                                } catch (err) {
                                  // todo: toast?
                                }
                              }}
                            />
                          ) : (
                            <RoleTag role={allRoles[id]} />
                          )}
                        </WrapItem>
                      ))}
                      {userInfo.permission >= PermissionLevel.Strategic && (
                        <WrapItem>
                          <AddRoleMenu
                            roles={Object.values(allRoles).filter(v => !info.roles.includes(v.id))}
                            canEdit
                            onSelect={async role => {
                              try {
                                const newRoles = info.roles.concat(role.id);
                                await api('/assets/issue/' + issueID + '/page/' + pageID, {
                                  method: 'PATCH',
                                  body: {
                                    roles: newRoles
                                  }
                                });
                                setAllPages({
                                  ...allPages,
                                  [pageID]: {
                                    ...info,
                                    roles: newRoles
                                  }
                                });
                              } catch (err) {
                                // todo: toast?
                              }
                            }}
                          />
                        </WrapItem>
                      )}
                    </Wrap>
                  </>
                )}
            </Flex>
            {info && allUsers && (
              <VStack ms={6} flexGrow={1} maxW="md">
                <Heading size="md">Revisions</Heading>
                {info.revisions.length > 0 ? (
                  <Accordion allowToggle w="100%" maxH="324px" overflow="auto">
                    {info.revisions.map((v, i) => (
                      <AccordionItem key={i} overflow="hidden">
                        <h2>
                          <AccordionButton ps={2} pe={1}>
                            <Flex justify="space-between" direction="row" align="center" w="100%">
                              <Box as="span" fontWeight="semibold">
                                <Show above="md">
                                  <HStack>
                                    <Text as="span">{prettyTime(v.timestamp)}</Text>
                                    {i == info.revisions.length - 1 ? (
                                      <Badge>Current</Badge>
                                    ) : (
                                      i == info.inddRev && (
                                        <Badge colorScheme="gray">Latest INDD</Badge>
                                      )
                                    )}
                                  </HStack>
                                </Show>
                                <Hide above="md">
                                  <Show above="sm">{prettyDate(v.timestamp)}</Show>
                                  <Hide above="sm">#{i + 1}</Hide>
                                </Hide>
                              </Box>
                              <ButtonGroup alignItems="center">
                                <IconButton
                                  ms={2}
                                  aria-label="Download PDF"
                                  icon={<DownloadIcon />}
                                  size="xs"
                                  as="a"
                                  href={v.url}
                                  download
                                  onClick={e => e.stopPropagation()}
                                />
                                <AccordionIcon />
                              </ButtonGroup>
                            </Flex>
                          </AccordionButton>
                          <AccordionPanel ps={2} pe={2} pt={0.5} pb={2}>
                            <Flex direction="row" justify="space-between" w="100%" align="center">
                              <HStack align="center">
                                <Avatar
                                  name={allUsers[v.author].name}
                                  src={allUsers[v.author].avatarURL}
                                  size="sm"
                                />
                                <Show above="sm">
                                  <Text>{allUsers[v.author].name}</Text>
                                </Show>
                              </HStack>
                              <Text ms={2} textAlign="right">
                                <Show above="md">
                                  {v.comment && (
                                    <>
                                      Note:{' '}
                                      <Text fontWeight="semibold" as="span">
                                        {v.comment}
                                      </Text>
                                    </>
                                  )}
                                </Show>
                                <Hide above="md">
                                  <Show above="sm">{prettyTimeOnly(v.timestamp)}</Show>
                                  <Hide above="sm">
                                    <Text as="span" wordBreak="break-all">
                                      {prettyDate(v.timestamp)}
                                    </Text>
                                  </Hide>
                                </Hide>
                              </Text>
                            </Flex>
                          </AccordionPanel>
                        </h2>
                      </AccordionItem>
                    ))}
                  </Accordion>
                ) : (
                  <Text textAlign="center">
                    No revisions uploaded yet. Click "New version" in the top left to upload your
                    PDF and InDesign.
                  </Text>
                )}
              </VStack>
            )}
          </Flex>
          {info && (
            <TableContainer
              overflowY="auto"
              px={1}
              mt={pdf ? 2 : 8}
              w={
                horizontal
                  ? 'calc(var(--dvw) - calc(calc(calc(0.6471 * var(--dvh)) - 144.94px) + 72px))'
                  : '100%'
              }
              minH="120px"
            >
              <Table colorScheme={colorMode == 'dark' ? 'whiteAlpha' : 'blackAlpha'}>
                <Thead>
                  <Tr>
                    <Th ps={0}>Checklist item</Th>
                    <Th>Complete</Th>
                    <Th
                      isNumeric={userInfo.permission < PermissionLevel.Strategic}
                      pe={userInfo.permission < PermissionLevel.Strategic ? 1 : null}
                    >
                      Deadline
                    </Th>
                    {userInfo.permission >= PermissionLevel.Strategic && <Th isNumeric pe={1}></Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {info.checklist.map((question, i) => (
                    <EditableChecklistQuestion
                      key={i}
                      info={question}
                      disabled={editingChecklist}
                      answer={info.answers[i]}
                      deadlines={issueInfo.deadlines}
                      canEdit={userInfo.permission >= PermissionLevel.Strategic}
                      onChange={async newQuestion => {
                        setEditingChecklist(true);
                        try {
                          const newChecklist = [...info.checklist];
                          newChecklist[i] = newQuestion;
                          await api('/assets/issue/' + issueID + '/page/' + pageID, {
                            method: 'PATCH',
                            body: {
                              checklist: newChecklist,
                              answers: info.answers
                            }
                          });
                          setAllPages({
                            ...allPages,
                            [pageID]: {
                              ...info,
                              checklist: newChecklist,
                              answers: info.answers.slice()
                            }
                          });
                        } catch (err) {
                          // todo: toast?
                        }
                        setEditingChecklist(false);
                      }}
                      onAnswer={async answer => {
                        setEditingChecklist(true);
                        try {
                          const answers = info.answers.slice();
                          answers[i] = answer;
                          await api('/assets/issue/' + issueID + '/page/' + pageID + '/answers', {
                            method: 'POST',
                            body: { answers }
                          });
                          setAllPages({
                            ...allPages,
                            [pageID]: {
                              ...info,
                              answers
                            }
                          });
                          setEditingChecklist(false);
                          return true;
                        } catch (err) {
                          // todo: toast?
                          setEditingChecklist(false);
                          return false;
                        }
                      }}
                      onDelete={async () => {
                        setEditingChecklist(true);
                        try {
                          const newChecklist = info.checklist.filter((_, j) => i != j);
                          const newAnswers = info.answers.filter((_, j) => i != j);
                          await api('/assets/issue/' + issueID + '/page/' + pageID, {
                            method: 'PATCH',
                            body: {
                              checklist: newChecklist,
                              answers: newAnswers
                            }
                          });
                          setAllPages({
                            ...allPages,
                            [pageID]: {
                              ...info,
                              checklist: newChecklist,
                              answers: newAnswers
                            }
                          });
                        } catch (err) {
                          // todo: toast?
                        }
                        setEditingChecklist(false);
                      }}
                    />
                  ))}
                  {userInfo.permission >= PermissionLevel.Strategic && (
                    <Tr>
                      <Td ps={0}>
                        <Input
                          placeholder="e.g. Layout done"
                          value={newQuestion}
                          onChange={e => setNewQuestion(e.currentTarget.value)}
                        />
                      </Td>
                      <Td>
                        <Checkbox isDisabled isIndeterminate />
                      </Td>
                      <Td>
                        <Select
                          value={newDeadline == null ? 'none' : newDeadline}
                          onChange={evt => {
                            setNewDeadline(
                              evt.currentTarget.value == 'none'
                                ? undefined
                                : evt.currentTarget.value
                            );
                          }}
                        >
                          <option value="none">None</option>
                          {deadlineValues.map(deadline => (
                            <option key={deadline.id} value={deadline.id}>
                              {deadline.name} ({prettyTime(deadline.time)})
                            </option>
                          ))}
                        </Select>
                      </Td>
                      <Td isNumeric pe={1}>
                        <Button
                          leftIcon={<AddIcon />}
                          size="sm"
                          isDisabled={editingChecklist || !newQuestion}
                          onClick={async () => {
                            setEditingChecklist(true);
                            try {
                              const newChecklist = info.checklist.concat({
                                question: newQuestion,
                                deadline: newDeadline
                              });
                              const newAnswers = info.answers.concat(false);
                              await api('/assets/issue/' + issueID + '/page/' + pageID, {
                                method: 'PATCH',
                                body: {
                                  checklist: newChecklist,
                                  answers: newAnswers
                                }
                              });
                              setAllPages({
                                ...allPages,
                                [pageID]: {
                                  ...info,
                                  checklist: newChecklist,
                                  answers: newAnswers
                                }
                              });
                              setNewQuestion('');
                              setNewDeadline(undefined);
                            } catch (err) {
                              // todo: toast?
                            }
                            setEditingChecklist(false);
                          }}
                        >
                          Add item
                        </Button>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

const upload = <T = unknown,>(
  url: string,
  data: FormData,
  onProgress: (cur: number, total: number) => void,
  backupTotal: number,
  signal: AbortSignal
) =>
  new Promise<T>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    let latestTotal = backupTotal;
    signal.addEventListener('abort', () => {
      resolve(null);
      xhr.abort();
    });
    xhr.upload.addEventListener('progress', e => {
      if (e.lengthComputable) {
        latestTotal = e.total || backupTotal;
        onProgress(e.loaded, e.total);
      }
    });
    xhr.addEventListener('error', () => {
      reject(new Error('Request failed'));
    });
    xhr.addEventListener('readystatechange', () => {
      if (xhr.readyState == 4) {
        if (xhr.status >= 200 && xhr.status <= 299) {
          onProgress(latestTotal, latestTotal);
          resolve(JSON.parse(xhr.responseText) as T);
        } else reject(new APIError(xhr.status, 'Upload failed'));
      }
    });
    xhr.open('POST', url, true);
    xhr.send(data);
  });

const convPDFRE = /\.(pdf|jpeg|jpg|png)$/i;

const IssuePageUpload = () => {
  const { issueID, pageID } = useParams();
  const [allIssues, setAllIssues] = useGlobalState('issues');
  const [allPages, setAllPages] = useGlobalState('pages');
  const { colorMode } = useColorMode();
  const [file, setFile] = useState<File | null>(null);
  const [pdfFile, setPDFFile] = useState<Blob | null>(null);
  const [fileID, setFileID] = useState('');
  const [inddFile, setINDDFile] = useState<File | null>(null);
  const [pdfLoad, setPDFLoad] = useState(false);
  const [loadErr, setLoadErr] = useState('');
  const [comment, setComment] = useState('');
  const [answers, setAnswers] = useState<boolean[] | null>(null);
  const [pdfUploadProgress, setPDFUploadProgress] = useState<{
    current: number;
    total: number;
  }>({
    current: 0,
    total: 0
  });
  const [inddUploadProgress, setINDDUploadProgress] = useState<{
    current: number;
    total: number;
  }>({
    current: 0,
    total: 0
  });
  const redirecting = useRef(false);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const pdfRef = useRef<HTMLInputElement>();
  const inddRef = useRef<HTMLInputElement>();

  const info = allPages[pageID];
  const issueInfo = allIssues[issueID];

  useBlocker({
    enabled: uploading,
    onBlock: nav => {
      if (
        redirecting.current ||
        window.confirm('Your revision is still uploading. Are you sure you want to leave?')
      ) {
        return nav.confirm();
      }
      nav.cancel();
    }
  });

  useEffect(() => {
    const ctrl = new AbortController();
    if (!info) {
      page(issueID, pageID, ctrl.signal).then(
        res => {
          setAllPages({
            ...allPages,
            [pageID]: res
          });
        },
        () => {
          navigate('/issue/' + issueID + '/page/' + pageID, { replace: true });
        }
      );
    } else {
      setAnswers(info.answers);
    }
    return () => ctrl.abort();
  }, [info]);

  useEffect(() => {
    const ctrl = new AbortController();
    if (!issueInfo) {
      issue(issueID, ctrl.signal).then(
        res => {
          setAllIssues({
            ...allIssues,
            [issueID]: res
          });
        },
        () => {
          navigate('/issues', { replace: true });
        }
      );
    }
    return () => ctrl.abort();
  }, [issueInfo]);

  useEffect(() => {
    const ctrl = new AbortController();
    if (file != null) {
      const fn = file.name.toLowerCase();
      if (fn.endsWith('.pdf')) {
        setPDFFile(file);
      } else {
        const run = async () => {
          const pdf = await PDFDocument.create();
          const buf = await file.arrayBuffer();
          let image: PDFImage;
          try {
            image = await (fn.endsWith('.png') ? pdf.embedPng(buf) : pdf.embedJpg(buf));
          } catch (err) {
            setLoadErr(file.name + ' failed to load');
            setPDFLoad(false);
            setPDFFile(null);
            setFile(null);
            setFileID('');
            return;
          }
          if (ctrl.signal.aborted) return;
          const page = pdf.addPage([792, 1224]);
          page.drawImage(image, {
            x: 0,
            y: 0,
            width: 792,
            height: 1224
          });
          const out = await pdf.save();
          if (ctrl.signal.aborted) return;
          setPDFFile(new Blob([out], { type: 'application/pdf' }));
        };
        run();
      }
    }
    return () => ctrl.abort();
  }, [fileID]);

  useEffect(() => {
    const ctrl = new AbortController();
    const run = async () => {
      try {
        let uploadAnswers = Promise.resolve();
        if (answers.some((v, i) => v != info.answers[i])) {
          uploadAnswers = api('/assets/issue/' + issueID + '/page/' + pageID + '/answers', {
            method: 'POST',
            body: { answers },
            signal: ctrl.signal
          });
        }
        let uploadINDD: Promise<{ id: string; url: string; rev?: number }>;
        if (inddFile != null) {
          const inddData = new FormData();
          inddData.append('file', inddFile);
          uploadINDD = upload(
            '/api/assets/issue/' +
              issueID +
              '/page/' +
              pageID +
              '/indd?rev=' +
              info.revisions.length,
            inddData,
            (current, total) => {
              setINDDUploadProgress({ current, total });
            },
            inddFile.size || 1,
            ctrl.signal
          );
        }
        const pdfData = new FormData();
        pdfData.append('file', pdfFile);
        const uploadPDF = upload<PageRevision>(
          '/api/assets/issue/' +
            issueID +
            '/page/' +
            pageID +
            '/pdf?comment=' +
            encodeURIComponent(comment),
          pdfData,
          (current, total) => {
            setPDFUploadProgress({ current, total });
          },
          pdfFile.size,
          ctrl.signal
        );
        await uploadAnswers;
        const inddResult = await uploadINDD;
        const revision = await uploadPDF;

        if (ctrl.signal.aborted) {
          setUploading(false);
          return;
        }

        const newIssues = { ...allIssues };

        const pageMeta = newIssues[issueID].pages[pageID];
        if (inddResult) pageMeta.indd = inddResult.id;
        pageMeta.pdf = revision.id;
        pageMeta.modified = revision.timestamp;
        setAllIssues(newIssues);

        const newPageInfo = {
          ...info,
          answers,
          revisions: info.revisions.concat(revision)
        };

        if (inddResult) {
          newPageInfo.inddID = inddResult.id;
          newPageInfo.inddURL = inddResult.url;
          newPageInfo.inddRev = inddResult.rev;
        }

        setAllPages({
          ...allPages,
          [pageID]: newPageInfo
        });

        redirecting.current = true;
        navigate('/issue/' + issueID + '/page/' + pageID);
      } catch (err) {
        // todo: toast?
      }
      setUploading(false);
    };
    if (uploading) run();
    return () => ctrl.abort();
  }, [uploading]);

  if (!info) {
    return (
      <Flex w="100%" h="calc(var(--dvh) - 80px)" justify="center" align="center">
        <Loading />
      </Flex>
    );
  }

  return (
    <Box p={6}>
      <Heading lineHeight={1} pb={2}>
        Update {info.name}
      </Heading>
      <Stack direction={['column', 'column', 'row']} spacing={4} mt={2} align="flex-start">
        <Stack direction={['row', 'row', 'column']}>
          <Card
            variant="filled"
            h={[
              'calc(calc(0.7727 * var(--dvw)) - 55.64px)',
              'calc(calc(0.7727 * var(--dvw)) - 55.64px)',
              'calc(calc(0.5 * var(--dvh)) - 94px)'
            ]}
            w={[
              'calc(calc(0.5 * var(--dvw)) - 36px)',
              'calc(calc(0.5 * var(--dvw)) - 36px)',
              'calc(calc(0.3235 * var(--dvh)) - 60.82px)'
            ]}
            borderStyle="dashed"
            borderWidth={file ? '0px' : '2px'}
            borderRadius={0}
            borderColor={colorMode == 'dark' ? null : 'blackAlpha.500'}
            transition="box-shadow 200ms"
            bgColor={file ? 'white' : null}
            boxShadow={colorMode == 'dark' || !pdfLoad ? null : 'lg'}
            _hover={
              uploading
                ? null
                : {
                    boxShadow: colorMode == 'dark' ? 'dark-lg' : 'lg'
                  }
            }
            _active={
              uploading
                ? null
                : {
                    boxShadow: colorMode == 'dark' ? 'dark-lg' : 'xl'
                  }
            }
            as="button"
            cursor={uploading ? 'not-allowed' : null}
            disabled={uploading}
            onClick={() => pdfRef.current.click()}
            onDragOver={e => e.preventDefault()}
            onDrop={e => {
              e.preventDefault();
              if (
                !uploading &&
                e.dataTransfer.files.length > 0 &&
                convPDFRE.test(e.dataTransfer.files[0].name)
              ) {
                e.preventDefault();
                setPDFLoad(false);
                setLoadErr('');
                setFile(e.dataTransfer.files[0]);
                setPDFFile(null);
                setFileID(Math.random().toString(36).slice(2, 15));
              }
            }}
          >
            <Flex
              w="100%"
              h="100%"
              p={file ? 0 : 2}
              align="center"
              justify="center"
              overflow="hidden"
            >
              {file ? (
                <>
                  <PDFPage
                    key={fileID}
                    data={pdfFile}
                    onLoad={() => {
                      setPDFLoad(true);
                    }}
                    onErr={err => {
                      if (!loadErr) {
                        setLoadErr(file.name + ' ' + err);
                        setPDFLoad(false);
                        setPDFFile(null);
                        setFile(null);
                        setFileID('');
                      }
                    }}
                  />
                  <Flex
                    pos="absolute"
                    bottom="0"
                    left="0"
                    w="100%"
                    p={4}
                    justify="center"
                    direction="row"
                  >
                    <Text
                      textAlign="center"
                      px={2}
                      bgColor={colorMode == 'dark' ? 'gray.600' : 'gray.100'}
                      borderRadius={2}
                      wordBreak="break-all"
                    >
                      {file.name}
                    </Text>
                  </Flex>
                  {uploading && (
                    <Flex
                      pos="absolute"
                      top="0"
                      left="0"
                      w="100%"
                      h="100%"
                      justify="center"
                      align="center"
                      bgColor="blackAlpha.700"
                    >
                      <CircularProgress
                        color="primary.500"
                        trackColor={colorMode == 'dark' ? 'whiteAlpha.700' : 'gray.200'}
                        value={
                          pdfUploadProgress.total &&
                          (pdfUploadProgress.current / pdfUploadProgress.total) * 100
                        }
                      >
                        <CircularProgressLabel color="white">
                          {Math.floor(
                            pdfUploadProgress.total &&
                              (pdfUploadProgress.current / pdfUploadProgress.total) * 100
                          )}
                          %
                        </CircularProgressLabel>
                      </CircularProgress>
                    </Flex>
                  )}
                </>
              ) : (
                <VStack w="100%">
                  <ArrowUpIcon boxSize={8} />
                  <Text fontSize="lg" w="100%">
                    {loadErr || 'Select PDF file or page sketch'}
                  </Text>
                </VStack>
              )}
            </Flex>
          </Card>
          <Card
            variant="filled"
            h={[
              'calc(calc(0.7727 * var(--dvw)) - 55.64px)',
              'calc(calc(0.7727 * var(--dvw)) - 55.64px)',
              'calc(calc(0.5 * var(--dvh)) - 94px)'
            ]}
            w={[
              'calc(calc(0.5 * var(--dvw)) - 36px)',
              'calc(calc(0.5 * var(--dvw)) - 36px)',
              'calc(calc(0.3235 * var(--dvh)) - 60.82px)'
            ]}
            borderStyle="dashed"
            borderWidth="2px"
            borderRadius={0}
            borderColor={colorMode == 'dark' ? null : 'blackAlpha.500'}
            transition="box-shadow 200ms"
            _hover={
              uploading
                ? null
                : {
                    boxShadow: colorMode == 'dark' ? 'dark-lg' : 'lg'
                  }
            }
            _active={
              uploading
                ? null
                : {
                    boxShadow: colorMode == 'dark' ? 'dark-lg' : 'xl'
                  }
            }
            as="button"
            cursor={uploading ? 'not-allowed' : null}
            disabled={uploading}
            onClick={() => inddRef.current.click()}
            onDragOver={e => e.preventDefault()}
            onDrop={e => {
              e.preventDefault();
              if (
                !uploading &&
                e.dataTransfer.files.length > 0 &&
                e.dataTransfer.files[0].name.endsWith('.indd')
              ) {
                setINDDFile(e.dataTransfer.files[0]);
              }
            }}
          >
            <Flex w="100%" h="100%" p={2} align="center" justify="center" overflow="hidden">
              <VStack w="100%">
                <ArrowUpIcon boxSize={8} />
                <Text fontSize="lg" w="100%">
                  {inddFile ? inddFile.name : 'Select InDesign file (optional)'}
                </Text>
              </VStack>
              {uploading && inddFile && (
                <Flex
                  pos="absolute"
                  top="0"
                  left="0"
                  w="100%"
                  h="100%"
                  justify="center"
                  align="center"
                  bgColor="blackAlpha.700"
                >
                  <CircularProgress
                    color="primary.500"
                    trackColor={colorMode == 'dark' ? 'whiteAlpha.700' : 'gray.200'}
                    value={
                      inddUploadProgress.total &&
                      (inddUploadProgress.current / inddUploadProgress.total) * 100
                    }
                  >
                    <CircularProgressLabel color="white">
                      {Math.floor(
                        inddUploadProgress.total &&
                          (inddUploadProgress.current / inddUploadProgress.total) * 100
                      )}
                      %
                    </CircularProgressLabel>
                  </CircularProgress>
                </Flex>
              )}
            </Flex>
          </Card>
          <Input
            type="file"
            accept=".pdf,.png,.jpeg,.jpg"
            display="none"
            ref={pdfRef}
            onChange={e => {
              if (!e.currentTarget.files.length || !convPDFRE.test(e.currentTarget.files[0].name)) {
                return;
              }
              setPDFLoad(false);
              setLoadErr('');
              setFile(e.currentTarget.files[0]);
              setPDFFile(null);
              setFileID(Math.random().toString(36).slice(2, 15));
            }}
          />
          <Input
            type="file"
            accept=".indd"
            display="none"
            ref={inddRef}
            onChange={e => {
              if (!e.currentTarget.files.length) {
                setINDDFile(null);
                return;
              }
              if (!e.currentTarget.files[0].name.endsWith('.indd')) return;
              setINDDFile(e.currentTarget.files[0]);
            }}
          />
        </Stack>
        <Flex
          direction="column"
          justify="space-between"
          w="100%"
          h={[null, null, 'calc(var(--dvh) - 180px)']}
          minH={[
            'calc(var(--dvh) - calc(calc(calc(0.7727 * var(--dvw)) - 55.64px) + 194px))',
            'calc(var(--dvh) - calc(calc(calc(0.7727 * var(--dvw)) - 55.64px) + 194px))',
            null
          ]}
        >
          <TableContainer
            maxH={[null, null, 'calc(var(--dvh) - 260px)']}
            minH="120px"
            w={[
              '100%',
              '100%',
              'calc(var(--dvw) - calc(calc(calc(0.3235 * var(--dvh)) - 60.82px) + 64px))'
            ]}
            overflowY="auto"
          >
            <Table colorScheme={colorMode == 'dark' ? 'whiteAlpha' : 'blackAlpha'}>
              <Thead>
                <Tr>
                  <Th ps={4}>Checklist item</Th>
                  <Th>Deadline</Th>
                  <Th pe={4} isNumeric>
                    Complete
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {info.checklist.map((item, i) => (
                  <Tr key={i}>
                    <Td ps={4}>
                      <HStack>
                        <Text fontWeight="semibold">{item.question}</Text>
                        {item.deadline &&
                          Date.now() >= issueInfo.deadlines[item.deadline].time &&
                          !info.answers[i] && <Badge colorScheme="red">Overdue</Badge>}
                      </HStack>
                    </Td>
                    <Td>
                      {item.deadline ? (
                        <>
                          <Show above="lg">
                            {issueInfo.deadlines[item.deadline].name} (
                            {prettyTime(issueInfo.deadlines[item.deadline].time)})
                          </Show>
                          <Hide above="lg">{issueInfo.deadlines[item.deadline].name}</Hide>
                        </>
                      ) : (
                        'None'
                      )}
                    </Td>
                    <Td pe={4} isNumeric>
                      <Checkbox
                        isDisabled={uploading}
                        isChecked={answers ? answers[i] : info.answers[i]}
                        onChange={e => {
                          const newAnswers = [...answers];
                          newAnswers[i] = e.currentTarget.checked;
                          setAnswers(newAnswers);
                        }}
                      />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Flex direction="row" justify="space-between" align="flex-end" w="100%" mt={[6, 6, 2]}>
            <FormControl>
              <FormLabel>Note (optional)</FormLabel>
              <Input
                size="md"
                isDisabled={uploading}
                value={comment}
                onChange={e => setComment(e.currentTarget.value)}
              />
            </FormControl>
            <Button
              ms={4}
              size="lg"
              colorScheme="green"
              isLoading={uploading}
              loadingText="Uploading..."
              isDisabled={uploading || !pdfLoad}
              onClick={async () => {
                setUploading(true);
              }}
            >
              Upload
            </Button>
          </Flex>
        </Flex>
      </Stack>
    </Box>
  );
};

const DoubletruckPage = () => {
  const { issueID } = useParams();
  const [allIssues, setAllIssues] = useGlobalState('issues');
  const navigate = useNavigate();
  const issueInfo = allIssues[issueID];

  useEffect(() => {
    const ctrl = new AbortController();
    if (!issueInfo) {
      issue(issueID, ctrl.signal).then(
        res => {
          setAllIssues({
            ...allIssues,
            [issueID]: res
          });
        },
        () => {
          navigate('/issues', { replace: true });
        }
      );
    } else if (!issueInfo.doubletruck) {
      navigate('/issue/' + issueID, { replace: true });
    } else {
      navigate('/issue/' + issueID, {
        replace: true,
        state: {
          spread: issueInfo.numPages / 4,
          pageOffset: 0
        }
      });
    }
    return () => ctrl.abort();
  }, [issueInfo]);

  return (
    <Flex w="100%" h="calc(var(--dvh) - 80px)" justify="center" align="center">
      <Loading />
    </Flex>
  );
};

type DoubletruckChecklistQuestion = {
  q: ChecklistQuestion;
  l?: number;
  r?: number;
};

const DoubletruckUpload = () => {
  const { issueID } = useParams();
  const [allIssues, setAllIssues] = useGlobalState('issues');
  const [allPages, setAllPages] = useGlobalState('pages');
  const { colorMode } = useColorMode();
  const [file, setFile] = useState<File | null>(null);
  const [lPDF, setLPDF] = useState<Blob | null>(null);
  const [rPDF, setRPDF] = useState<Blob | null>(null);
  const [fileID, setFileID] = useState('');
  const [inddFile, setINDDFile] = useState<File | null>(null);
  const [lPDFLoad, setLPDFLoad] = useState(false);
  const [rPDFLoad, setRPDFLoad] = useState(false);
  const [loadErr, setLoadErr] = useState('');
  const [comment, setComment] = useState('');
  const [answers, setAnswers] = useState<(boolean | null)[] | null>(null);
  const [lPDFUploadProgress, setLPDFUploadProgress] = useState<{
    current: number;
    total: number;
  }>({
    current: 0,
    total: 0
  });
  const [rPDFUploadProgress, setRPDFUploadProgress] = useState<{
    current: number;
    total: number;
  }>({
    current: 0,
    total: 0
  });
  const [inddUploadProgress, setINDDUploadProgress] = useState<{
    current: number;
    total: number;
  }>({
    current: 0,
    total: 0
  });
  const redirecting = useRef(false);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate();
  const pdfRef = useRef<HTMLInputElement>();
  const inddRef = useRef<HTMLInputElement>();

  const issueInfo = allIssues[issueID];
  const [leftID, rightID] = useMemo(() => {
    if (!issueInfo) return [null, null];
    const vals = Object.values(issueInfo.pages);
    const l = vals.find(v => v.page == issueInfo.numPages / 2);
    const r = vals.find(v => v.page == issueInfo.numPages / 2 + 1);
    return [l?.id, r?.id];
  }, [issueInfo]);
  const left = allPages[leftID];
  const right = allPages[rightID];

  const questions = useMemo(() => {
    if (!left || !right) return null;
    const out: DoubletruckChecklistQuestion[] = [];
    const lRem: DoubletruckChecklistQuestion[] = [];
    const rRem: DoubletruckChecklistQuestion[] = [];
    const byHash: Record<string, number[]> = {};
    const hash = (q: ChecklistQuestion) => JSON.stringify(q.question) + (q.deadline || null);
    for (let i = 0; i < left.checklist.length; ++i) {
      const h = hash(left.checklist[i]);
      if (!byHash[h]) byHash[h] = [];
      byHash[h].push(i);
    }
    for (let i = 0; i < right.checklist.length; ++i) {
      const h = hash(right.checklist[i]);
      if (byHash[h]?.length) {
        out.push({
          q: { ...right.checklist[i] },
          l: byHash[h].shift(),
          r: i
        });
      } else {
        rRem.push({
          q: {
            question: right.checklist[i].question + ' (' + right.name + ')',
            deadline: right.checklist[i].deadline
          },
          r: i
        });
      }
    }
    for (const h in byHash) {
      for (const i of byHash[h]) {
        lRem.push({
          q: {
            question: left.checklist[i].question + ' (' + left.name + ')',
            deadline: left.checklist[i].deadline
          },
          l: i
        });
      }
    }
    return out.concat(lRem).concat(rRem);
  }, [left, right]);

  useBlocker({
    enabled: uploading,
    onBlock: nav => {
      if (
        redirecting.current ||
        window.confirm('Your revision is still uploading. Are you sure you want to leave?')
      ) {
        return nav.confirm();
      }
      nav.cancel();
    }
  });

  useEffect(() => {
    const ctrl = new AbortController();
    if (!left && leftID) {
      page(issueID, leftID, ctrl.signal).then(
        res => {
          setAllPages(allPages => ({
            ...allPages,
            [leftID]: res
          }));
        },
        () => {
          navigate(
            '/issue/' +
              issueID +
              (issueInfo.pages[rightID].canEdit ? '/page/' + rightID + '/upload' : ''),
            { replace: true }
          );
        }
      );
    }
    return () => ctrl.abort();
  }, [leftID]);

  useEffect(() => {
    const ctrl = new AbortController();
    if (!right && rightID) {
      page(issueID, rightID, ctrl.signal).then(
        res => {
          setAllPages(allPages => ({
            ...allPages,
            [rightID]: res
          }));
        },
        () => {
          navigate(
            '/issue/' +
              issueID +
              (issueInfo.pages[leftID].canEdit ? '/page/' + leftID + '/upload' : ''),
            { replace: true }
          );
        }
      );
    }
    return () => ctrl.abort();
  }, [rightID]);

  useEffect(() => {
    if (left && right) {
      const curAnswers: (boolean | null)[] = [];
      for (const question of questions) {
        curAnswers.push(
          question.l != null
            ? question.r != null
              ? left.answers[question.l] == right.answers[question.r]
                ? left.answers[question.l]
                : null
              : left.answers[question.l]
            : right.answers[question.r]
        );
      }
      setAnswers(curAnswers);
    }
  }, [left, right]);

  useEffect(() => {
    const ctrl = new AbortController();
    if (!issueInfo) {
      issue(issueID, ctrl.signal).then(
        res => {
          setAllIssues({
            ...allIssues,
            [issueID]: res
          });
        },
        () => {
          navigate('/issues', { replace: true });
        }
      );
    } else if (!issueInfo.doubletruck) {
      navigate('/issue/' + issueID, { replace: true });
    } else if (!leftID || !rightID) {
      navigate('/issue/' + issueID + '/page/doubletruck', { replace: true });
    }
    return () => ctrl.abort();
  }, [issueInfo]);

  const pdfLoadErr = (err: string) => {
    setLoadErr(err);
    setLPDFLoad(false);
    setRPDFLoad(false);
    setFile(null);
    setLPDF(null);
    setRPDF(null);
    setFileID('');
  };

  useEffect(() => {
    const ctrl = new AbortController();
    if (file != null) {
      const fn = file.name.toLowerCase();
      if (fn.endsWith('.pdf')) {
        const run = async () => {
          let pdf: PDFDocument;
          try {
            pdf = await PDFDocument.load(await file.arrayBuffer());
          } catch (err) {
            pdfLoadErr(file.name + ' failed to load');
            return;
          }
          if (ctrl.signal.aborted) return;
          const pageCount = pdf.getPageCount();
          if (pageCount != 2) {
            pdfLoadErr(
              `${file.name} has ${pageCount} page${pageCount == 1 ? '' : 's'} but should have 2`
            );
            return;
          }
          const left = await PDFDocument.create();
          const right = await PDFDocument.create();
          const [[lPage], [rPage]] = await Promise.all([
            left.copyPages(pdf, [0]),
            right.copyPages(pdf, [1])
          ]);
          if (ctrl.signal.aborted) return;
          left.addPage(lPage);
          right.addPage(rPage);
          const [lBuf, rBuf] = await Promise.all([left.save(), right.save()]);
          if (ctrl.signal.aborted) return;
          setLPDF(new Blob([lBuf], { type: 'application/pdf' }));
          setRPDF(new Blob([rBuf], { type: 'application/pdf' }));
        };
        run();
      } else {
        const run = async () => {
          const left = await PDFDocument.create();
          const right = await PDFDocument.create();
          const buf = await file.arrayBuffer();
          let leftImage: PDFImage, rightImage: PDFImage;
          try {
            [leftImage, rightImage] = await Promise.all(
              fn.endsWith('.png')
                ? [left.embedPng(buf), right.embedPng(buf)]
                : [left.embedJpg(buf), right.embedJpg(buf)]
            );
          } catch (err) {
            pdfLoadErr(file.name + ' failed to load');
            return;
          }
          if (ctrl.signal.aborted) return;
          const leftPage = left.addPage([792, 1224]);
          leftPage.drawImage(leftImage, {
            x: 0,
            y: 0,
            width: 1584,
            height: 1224
          });
          const rightPage = right.addPage([792, 1224]);
          rightPage.drawImage(rightImage, {
            x: -792,
            y: 0,
            width: 1584,
            height: 1224
          });
          const [outLeft, outRight] = await Promise.all([left.save(), right.save()]);
          if (ctrl.signal.aborted) return;
          setLPDF(new Blob([outLeft], { type: 'application/pdf' }));
          setRPDF(new Blob([outRight], { type: 'application/pdf' }));
        };
        run();
      }
    }
    return () => ctrl.abort();
  }, [fileID]);

  useEffect(() => {
    const ctrl = new AbortController();
    const run = async () => {
      try {
        let uploadLAnswers = Promise.resolve();
        let uploadRAnswers = Promise.resolve();
        const lAnswers = left.answers.slice();
        const rAnswers = right.answers.slice();
        for (let i = 0; i < questions.length; ++i) {
          if (answers[i] == null) continue;
          if (questions[i].l != null) {
            lAnswers[questions[i].l] = answers[i];
          }
          if (questions[i].r != null) {
            rAnswers[questions[i].r] = answers[i];
          }
        }
        if (lAnswers.some((v, i) => v != left.answers[i])) {
          uploadLAnswers = api('/assets/issue/' + issueID + '/page/' + leftID + '/answers', {
            method: 'POST',
            body: { answers: lAnswers },
            signal: ctrl.signal
          });
        }
        if (rAnswers.some((v, i) => v != right.answers[i])) {
          uploadRAnswers = api('/assets/issue/' + issueID + '/page/' + rightID + '/answers', {
            method: 'POST',
            body: { answers: rAnswers },
            signal: ctrl.signal
          });
        }
        let uploadINDD: Promise<{ id: string; url: string; rev?: number }>;
        if (inddFile != null) {
          const inddData = new FormData();
          inddData.append('file', inddFile);
          uploadINDD = upload<{ id: string; url: string; rev?: number }>(
            '/api/assets/issue/' +
              issueID +
              '/page/' +
              leftID +
              '/indd?rev=' +
              left.revisions.length,
            inddData,
            (current, total) => {
              setINDDUploadProgress({ current, total });
            },
            inddFile.size || 1,
            ctrl.signal
          ).then(async res => {
            await api('/assets/issue/' + issueID + '/page/' + rightID + '/inddlink', {
              method: 'POST',
              body: {
                to: leftID,
                rev: right.revisions.length
              }
            });
            return res;
          });
        }
        const lPDFData = new FormData();
        lPDFData.append('file', lPDF);
        const uploadLPDF = upload<PageRevision>(
          '/api/assets/issue/' +
            issueID +
            '/page/' +
            leftID +
            '/pdf?comment=' +
            encodeURIComponent(comment),
          lPDFData,
          (current, total) => {
            setLPDFUploadProgress({ current, total });
          },
          lPDF.size,
          ctrl.signal
        );
        const rPDFData = new FormData();
        rPDFData.append('file', rPDF);
        const uploadRPDF = upload<PageRevision>(
          '/api/assets/issue/' +
            issueID +
            '/page/' +
            rightID +
            '/pdf?comment=' +
            encodeURIComponent(comment),
          rPDFData,
          (current, total) => {
            setRPDFUploadProgress({ current, total });
          },
          rPDF.size,
          ctrl.signal
        );
        await uploadLAnswers;
        await uploadRAnswers;
        const inddResult = await uploadINDD;
        const lRev = await uploadLPDF;
        const rRev = await uploadRPDF;

        if (ctrl.signal.aborted) {
          setUploading(false);
          return;
        }

        const newIssues = { ...allIssues };

        const lPageMeta = newIssues[issueID].pages[leftID];
        if (inddResult) lPageMeta.indd = inddResult.id;
        lPageMeta.pdf = lRev.id;
        lPageMeta.modified = lRev.timestamp;

        const rPageMeta = newIssues[issueID].pages[rightID];
        if (inddResult) rPageMeta.indd = inddResult.id;
        rPageMeta.pdf = rRev.id;
        rPageMeta.modified = rRev.timestamp;
        setAllIssues(newIssues);

        const newLPageInfo = {
          ...left,
          answers: lAnswers,
          revisions: left.revisions.concat(lRev)
        };

        const newRPageInfo = {
          ...right,
          answers: rAnswers,
          revisions: right.revisions.concat(rRev)
        };

        if (inddResult) {
          newLPageInfo.inddID = inddResult.id;
          newLPageInfo.inddURL = inddResult.url;
          newLPageInfo.inddRev = inddResult.rev;

          newRPageInfo.inddID = inddResult.id;
          newRPageInfo.inddURL = inddResult.url;
          newRPageInfo.inddRev = right.revisions.length;
        }

        setAllPages({
          ...allPages,
          [leftID]: newLPageInfo,
          [rightID]: newRPageInfo
        });

        redirecting.current = true;
        navigate('/issue/' + issueID + '/page/doubletruck');
      } catch (err) {
        // todo: toast?
      }
      setUploading(false);
    };
    if (uploading) run();
    return () => ctrl.abort();
  }, [uploading]);

  const pdfLoad = lPDFLoad && rPDFLoad;

  if (!left || !right || !issueInfo?.doubletruck) {
    return (
      <Flex w="100%" h="calc(var(--dvh) - 80px)" justify="center" align="center">
        <Loading />
      </Flex>
    );
  }

  return (
    <Box p={6}>
      <Heading lineHeight={1} pb={2}>
        Update {left.name}, {right.name}
      </Heading>
      <Stack
        direction={['column', 'column', 'column', 'row']}
        spacing={4}
        mt={2}
        align="flex-start"
      >
        <Stack direction="column">
          <Card
            variant="filled"
            h={[
              'calc(calc(0.7727 * var(--dvw)) - 49.45px)',
              'calc(calc(0.7727 * var(--dvw)) - 49.45px)',
              'calc(calc(0.7727 * var(--dvw)) - 49.45px)',
              'calc(calc(0.5 * var(--dvh)) - 94px)'
            ]}
            w={[
              'calc(var(--dvw) - 64px)',
              'calc(var(--dvw) - 64px)',
              'calc(var(--dvw) - 64px)',
              'calc(calc(0.6471 * var(--dvh)) - 121.65px)'
            ]}
            borderStyle="dashed"
            borderWidth={file ? '0px' : '2px'}
            borderRadius={0}
            borderColor={colorMode == 'dark' ? null : 'blackAlpha.500'}
            transition="box-shadow 200ms"
            bgColor={file ? 'white' : null}
            boxShadow={colorMode == 'dark' || !pdfLoad ? null : 'lg'}
            _hover={
              uploading
                ? null
                : {
                    boxShadow: colorMode == 'dark' ? 'dark-lg' : 'lg'
                  }
            }
            _active={
              uploading
                ? null
                : {
                    boxShadow: colorMode == 'dark' ? 'dark-lg' : 'xl'
                  }
            }
            as="button"
            cursor={uploading ? 'not-allowed' : null}
            disabled={uploading}
            onClick={() => pdfRef.current.click()}
            onDragOver={e => e.preventDefault()}
            onDrop={e => {
              e.preventDefault();
              if (
                !uploading &&
                e.dataTransfer.files.length > 0 &&
                convPDFRE.test(e.dataTransfer.files[0].name)
              ) {
                e.preventDefault();
                setLPDFLoad(false);
                setRPDFLoad(false);
                setLoadErr('');
                setFile(e.dataTransfer.files[0]);
                setLPDF(null);
                setRPDF(null);
                setFileID(Math.random().toString(36).slice(2, 15));
              }
            }}
          >
            <Flex
              w="100%"
              h="100%"
              p={file ? 0 : 2}
              align="center"
              justify="center"
              overflow="hidden"
            >
              {file ? (
                <>
                  <HStack
                    spacing={0}
                    visibility={lPDFLoad && rPDFLoad ? null : 'hidden'}
                    w="100%"
                    h="100%"
                  >
                    <Box h="100%" w="50%">
                      <PDFPage
                        key={fileID + '.l'}
                        data={lPDF}
                        onLoad={() => {
                          setLPDFLoad(true);
                        }}
                        onErr={err => {
                          if (!loadErr) {
                            pdfLoadErr(file.name + ' ' + err);
                          }
                        }}
                      />
                    </Box>
                    <Box h="100%" w="50%">
                      <PDFPage
                        key={fileID + '.r'}
                        data={rPDF}
                        onLoad={() => {
                          setRPDFLoad(true);
                        }}
                        onErr={err => {
                          if (!loadErr) {
                            pdfLoadErr(file.name + ' ' + err);
                          }
                        }}
                      />
                    </Box>
                  </HStack>
                  {(!lPDFLoad || !rPDFLoad) && (
                    <Flex
                      pos="absolute"
                      top="0"
                      left="0"
                      w="100%"
                      h="100%"
                      align="center"
                      justify="center"
                      bgColor="white"
                    >
                      <Spinner
                        color="primary.700"
                        size="xl"
                        thickness="4px"
                        emptyColor="gray.200"
                      />
                    </Flex>
                  )}
                  <Flex
                    pos="absolute"
                    bottom="0"
                    left="0"
                    w="100%"
                    p={4}
                    justify="center"
                    direction="row"
                  >
                    <Text
                      textAlign="center"
                      px={2}
                      bgColor={colorMode == 'dark' ? 'gray.600' : 'gray.100'}
                      borderRadius={2}
                      wordBreak="break-all"
                    >
                      {file.name}
                    </Text>
                  </Flex>
                  {uploading && (
                    <Flex
                      pos="absolute"
                      top="0"
                      left="0"
                      w="100%"
                      h="100%"
                      justify="center"
                      align="center"
                      bgColor="blackAlpha.700"
                    >
                      <CircularProgress
                        color="primary.500"
                        trackColor={colorMode == 'dark' ? 'whiteAlpha.700' : 'gray.200'}
                        value={
                          (lPDFUploadProgress.total || rPDFUploadProgress.total) &&
                          ((lPDFUploadProgress.current + rPDFUploadProgress.current) /
                            (lPDFUploadProgress.total + rPDFUploadProgress.total)) *
                            100
                        }
                      >
                        <CircularProgressLabel color="white">
                          {Math.floor(
                            (lPDFUploadProgress.total || rPDFUploadProgress.total) &&
                              ((lPDFUploadProgress.current + rPDFUploadProgress.current) /
                                (lPDFUploadProgress.total + rPDFUploadProgress.total)) *
                                100
                          )}
                          %
                        </CircularProgressLabel>
                      </CircularProgress>
                    </Flex>
                  )}
                </>
              ) : (
                <VStack w="100%">
                  <ArrowUpIcon boxSize={8} />
                  <Text fontSize="lg" w="100%">
                    {loadErr || 'Select PDF file or page sketch'}
                  </Text>
                </VStack>
              )}
            </Flex>
          </Card>
          <Card
            variant="filled"
            h={[
              'calc(calc(0.7727 * var(--dvw)) - 49.45px)',
              'calc(calc(0.7727 * var(--dvw)) - 49.45px)',
              'calc(calc(0.7727 * var(--dvw)) - 49.45px)',
              'calc(calc(0.5 * var(--dvh)) - 94px)'
            ]}
            w={[
              'calc(var(--dvw) - 64px)',
              'calc(var(--dvw) - 64px)',
              'calc(var(--dvw) - 64px)',
              'calc(calc(0.6471 * var(--dvh)) - 121.65px)'
            ]}
            borderStyle="dashed"
            borderWidth="2px"
            borderRadius={0}
            borderColor={colorMode == 'dark' ? null : 'blackAlpha.500'}
            transition="box-shadow 200ms"
            _hover={
              uploading
                ? null
                : {
                    boxShadow: colorMode == 'dark' ? 'dark-lg' : 'lg'
                  }
            }
            _active={
              uploading
                ? null
                : {
                    boxShadow: colorMode == 'dark' ? 'dark-lg' : 'xl'
                  }
            }
            as="button"
            cursor={uploading ? 'not-allowed' : null}
            disabled={uploading}
            onClick={() => inddRef.current.click()}
            onDragOver={e => e.preventDefault()}
            onDrop={e => {
              e.preventDefault();
              if (
                !uploading &&
                e.dataTransfer.files.length > 0 &&
                e.dataTransfer.files[0].name.endsWith('.indd')
              ) {
                setINDDFile(e.dataTransfer.files[0]);
              }
            }}
          >
            <Flex w="100%" h="100%" p={2} align="center" justify="center" overflow="hidden">
              <VStack w="100%">
                <ArrowUpIcon boxSize={8} />
                <Text fontSize="lg" w="100%">
                  {inddFile ? inddFile.name : 'Select InDesign file (optional)'}
                </Text>
              </VStack>
              {uploading && inddFile && (
                <Flex
                  pos="absolute"
                  top="0"
                  left="0"
                  w="100%"
                  h="100%"
                  justify="center"
                  align="center"
                  bgColor="blackAlpha.700"
                >
                  <CircularProgress
                    color="primary.500"
                    trackColor={colorMode == 'dark' ? 'whiteAlpha.700' : 'gray.200'}
                    value={
                      inddUploadProgress.total &&
                      (inddUploadProgress.current / inddUploadProgress.total) * 100
                    }
                  >
                    <CircularProgressLabel color="white">
                      {Math.floor(
                        inddUploadProgress.total &&
                          (inddUploadProgress.current / inddUploadProgress.total) * 100
                      )}
                      %
                    </CircularProgressLabel>
                  </CircularProgress>
                </Flex>
              )}
            </Flex>
          </Card>
          <Input
            type="file"
            accept=".pdf,.png,.jpeg,.jpg"
            display="none"
            ref={pdfRef}
            onChange={e => {
              if (!e.currentTarget.files.length || !convPDFRE.test(e.currentTarget.files[0].name)) {
                return;
              }
              setLPDFLoad(false);
              setRPDFLoad(false);
              setLoadErr('');
              setFile(e.currentTarget.files[0]);
              setLPDF(null);
              setRPDF(null);
              setFileID(Math.random().toString(36).slice(2, 15));
            }}
          />
          <Input
            type="file"
            accept=".indd"
            display="none"
            ref={inddRef}
            onChange={e => {
              if (!e.currentTarget.files.length) {
                setINDDFile(null);
                return;
              }
              if (!e.currentTarget.files[0].name.endsWith('.indd')) return;
              setINDDFile(e.currentTarget.files[0]);
            }}
          />
        </Stack>
        <Flex
          direction="column"
          justify="space-between"
          w="100%"
          h={[null, null, null, 'calc(var(--dvh) - 180px)']}
          minH={[
            'calc(var(--dvh) - calc(calc(calc(0.7727 * var(--dvw)) - 49.45px) + 194px))',
            'calc(var(--dvh) - calc(calc(calc(0.7727 * var(--dvw)) - 49.45px) + 194px))',
            'calc(var(--dvh) - calc(calc(calc(0.7727 * var(--dvw)) - 49.45px) + 194px))',
            null
          ]}
        >
          <TableContainer
            maxH={[null, null, null, 'calc(var(--dvh) - 260px)']}
            minH="120px"
            w={[
              '100%',
              '100%',
              '100%',
              'calc(var(--dvw) - calc(calc(calc(0.6471 * var(--dvh)) - 121.65px) + 64px))'
            ]}
            overflowY="auto"
          >
            <Table colorScheme={colorMode == 'dark' ? 'whiteAlpha' : 'blackAlpha'}>
              <Thead>
                <Tr>
                  <Th ps={4}>Checklist item</Th>
                  <Th>Deadline</Th>
                  <Th pe={4} isNumeric>
                    Complete
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {questions.map((item, i) => {
                  const curAnswer = item.l != null ? left.answers[item.l] : right.answers[item.r];
                  return (
                    <Tr key={i}>
                      <Td ps={4}>
                        <HStack>
                          <Text fontWeight="semibold">{item.q.question}</Text>
                          {item.q.deadline &&
                            Date.now() >= issueInfo.deadlines[item.q.deadline].time &&
                            !curAnswer && <Badge colorScheme="red">Overdue</Badge>}
                        </HStack>
                      </Td>
                      <Td>
                        {item.q.deadline ? (
                          <>
                            <Show above="xl">
                              {issueInfo.deadlines[item.q.deadline].name} (
                              {prettyTime(issueInfo.deadlines[item.q.deadline].time)})
                            </Show>
                            <Hide above="xl">{issueInfo.deadlines[item.q.deadline].name}</Hide>
                          </>
                        ) : (
                          'None'
                        )}
                      </Td>
                      <Td pe={4} isNumeric>
                        <Checkbox
                          isDisabled={uploading}
                          isChecked={(answers ? answers[i] : curAnswer) || false}
                          onChange={e => {
                            const newAnswers = [...answers];
                            newAnswers[i] = e.currentTarget.checked;
                            setAnswers(newAnswers);
                          }}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <Flex direction="row" justify="space-between" align="flex-end" w="100%" mt={[6, 6, 2]}>
            <FormControl>
              <FormLabel>Note (optional)</FormLabel>
              <Input
                size="md"
                isDisabled={uploading}
                value={comment}
                onChange={e => setComment(e.currentTarget.value)}
              />
            </FormControl>
            <Button
              ms={4}
              size="lg"
              colorScheme="green"
              isLoading={uploading}
              loadingText="Uploading..."
              isDisabled={uploading || !pdfLoad}
              onClick={async () => {
                setUploading(true);
              }}
            >
              Upload
            </Button>
          </Flex>
        </Flex>
      </Stack>
    </Box>
  );
};

export { IssuePage, IssuePageUpload, DoubletruckPage, DoubletruckUpload };
