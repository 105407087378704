import createGlobalState from 'react-universal-state';
import { Invites, MyUserInfo, Roles, Users, PageInfo, IssueInfo } from './api';

const { hook: useGlobalState } = createGlobalState({
  userInfo: null as MyUserInfo | null,
  roles: null as Roles | null,
  users: null as Users | null,
  invites: null as Invites | null,
  currentIssue: null as string | null,
  pages: {} as Record<string, PageInfo>,
  issues: {} as Record<string, IssueInfo>,
  forceColorMode: localStorage.getItem('force-color') as 'dark' | 'light' | null
});

export { useGlobalState };
