import { Spinner, SpinnerProps, useColorMode } from '@chakra-ui/react';

const Loading = (props: SpinnerProps) => {
  const { colorMode } = useColorMode();

  return (
    <Spinner
      color="primary.700"
      size="xl"
      thickness="4px"
      emptyColor={colorMode == 'dark' ? 'whiteAlpha.700' : 'gray.200'}
      {...props}
    />
  );
};

export default Loading;
