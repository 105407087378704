import { Badge, BadgeProps } from '@chakra-ui/react';
import { PermissionLevel, permissionLevelColors, permissionLevelNames } from '../util/api';

const PermissionBadge = ({
  permission,
  superuser,
  ...props
}: { permission: PermissionLevel; superuser?: boolean } & Omit<BadgeProps, 'children'>) => {
  return (
    <Badge colorScheme={superuser ? 'red' : permissionLevelColors[permission]} {...props}>
      {superuser ? 'Admin' : permissionLevelNames[permission]}
    </Badge>
  );
};

export default PermissionBadge;
