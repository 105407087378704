import { useEffect, useRef, useState } from 'react';
import { getDoc } from '../util/google';
import PDFPage from './pdf-page';

const DrivePage = ({ fileID, onLoad }: { fileID: string; onLoad?: () => unknown }) => {
  const [data, setData] = useState<Blob>(null);

  useEffect(() => {
    setData(null);
    const ctrl = new AbortController();
    getDoc(fileID, ctrl.signal).then(setData);
    return () => ctrl.abort();
  }, [fileID]);

  return <PDFPage data={data} onLoad={onLoad} key={fileID} />;
};

export default DrivePage;
