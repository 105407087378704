var __getOwnPropNames = Object.getOwnPropertyNames;
var __esm = (fn, res) => function __init() {
  return fn && (res = (0, fn[__getOwnPropNames(fn)[0]])(fn = 0)), res;
};

// ../../../react-shim.js
import React from "react";
var init_react_shim = __esm({
  "../../../react-shim.js"() {
    "use strict";
  }
});

// src/index.ts
init_react_shim();

// src/range-slider.tsx
init_react_shim();
import { createContext } from "@chakra-ui/react-context";
import {
  chakra,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig,
  useTheme
} from "@chakra-ui/system";

// ../../legacy/utils/src/dom.ts
init_react_shim();
var dataAttr = (condition) => condition ? "" : void 0;
var ariaAttr = (condition) => condition ? true : void 0;
var cx = (...classNames) => classNames.filter(Boolean).join(" ");

// ../../legacy/utils/src/function.ts
init_react_shim();
function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn == null ? void 0 : fn(event);
      return event == null ? void 0 : event.defaultPrevented;
    });
  };
}

// src/range-slider.tsx
import { useMemo as useMemo2 } from "react";

// src/use-range-slider.ts
init_react_shim();
import { usePanEvent } from "@chakra-ui/react-use-pan-event";
import { useControllableState } from "@chakra-ui/react-use-controllable-state";
import { useCallbackRef } from "@chakra-ui/react-use-callback-ref";
import { useUpdateEffect } from "@chakra-ui/react-use-update-effect";
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";
import {
  percentToValue,
  roundValueToStep,
  valueToPercent,
  clampValue
} from "@chakra-ui/number-utils";
import { useCallback, useMemo, useRef, useState, useId } from "react";

// src/slider-utils.ts
init_react_shim();
function getIds(id) {
  return {
    root: `slider-root-${id}`,
    getThumb: (i) => `slider-thumb-${id}-${i}`,
    getInput: (i) => `slider-input-${id}-${i}`,
    track: `slider-track-${id}`,
    innerTrack: `slider-filled-track-${id}`,
    getMarker: (i) => `slider-marker-${id}-${i}`,
    output: `slider-output-${id}`
  };
}
function orient(options) {
  const { orientation, vertical, horizontal } = options;
  return orientation === "vertical" ? vertical : horizontal;
}
var zeroSize = { width: 0, height: 0 };
var normalize = (a) => a || zeroSize;
function getStyles(options) {
  const { orientation, thumbPercents, thumbRects, isReversed } = options;
  const getThumbStyle = (i) => {
    const rect = thumbRects[i] ?? zeroSize;
    return {
      position: "absolute",
      userSelect: "none",
      WebkitUserSelect: "none",
      MozUserSelect: "none",
      msUserSelect: "none",
      touchAction: "none",
      ...orient({
        orientation,
        vertical: {
          bottom: `calc(${thumbPercents[i]}% - ${rect.height / 2}px)`
        },
        horizontal: {
          left: `calc(${thumbPercents[i]}% - ${rect.width / 2}px)`
        }
      })
    };
  };
  const size = orientation === "vertical" ? thumbRects.reduce((a, b) => normalize(a).height > normalize(b).height ? a : b, zeroSize) : thumbRects.reduce((a, b) => normalize(a).width > normalize(b).width ? a : b, zeroSize);
  const rootStyle = {
    position: "relative",
    touchAction: "none",
    WebkitTapHighlightColor: "rgba(0,0,0,0)",
    userSelect: "none",
    outline: 0,
    ...orient({
      orientation,
      vertical: size ? {
        paddingLeft: size.width / 2,
        paddingRight: size.width / 2
      } : {},
      horizontal: size ? {
        paddingTop: size.height / 2,
        paddingBottom: size.height / 2
      } : {}
    })
  };
  const trackStyle = {
    position: "absolute",
    ...orient({
      orientation,
      vertical: {
        left: "50%",
        transform: "translateX(-50%)",
        height: "100%"
      },
      horizontal: {
        top: "50%",
        transform: "translateY(-50%)",
        width: "100%"
      }
    })
  };
  const isSingleThumb = thumbPercents.length === 1;
  const fallback = [0, isReversed ? 100 - thumbPercents[0] : thumbPercents[0]];
  const range = isSingleThumb ? fallback : thumbPercents;
  let start = range[0];
  if (!isSingleThumb && isReversed) {
    start = 100 - start;
  }
  const percent = Math.abs(range[range.length - 1] - range[0]);
  const innerTrackStyle = {
    ...trackStyle,
    ...orient({
      orientation,
      vertical: isReversed ? { height: `${percent}%`, top: `${start}%` } : { height: `${percent}%`, bottom: `${start}%` },
      horizontal: isReversed ? { width: `${percent}%`, right: `${start}%` } : { width: `${percent}%`, left: `${start}%` }
    })
  };
  return { trackStyle, innerTrackStyle, rootStyle, getThumbStyle };
}
function getIsReversed(options) {
  const { isReversed, direction, orientation } = options;
  if (direction === "ltr" || orientation === "vertical") {
    return isReversed;
  }
  return !isReversed;
}

// src/use-range-slider.ts
import { useSizes } from "@chakra-ui/react-use-size";
function useRangeSlider(props) {
  const {
    min = 0,
    max = 100,
    onChange,
    value: valueProp,
    defaultValue,
    isReversed: isReversedProp,
    direction = "ltr",
    orientation = "horizontal",
    id: idProp,
    isDisabled,
    isReadOnly,
    onChangeStart: onChangeStartProp,
    onChangeEnd: onChangeEndProp,
    step = 1,
    getAriaValueText: getAriaValueTextProp,
    "aria-valuetext": ariaValueText,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    name,
    focusThumbOnChange = true,
    minStepsBetweenThumbs = 0,
    ...htmlProps
  } = props;
  const onChangeStart = useCallbackRef(onChangeStartProp);
  const onChangeEnd = useCallbackRef(onChangeEndProp);
  const getAriaValueText = useCallbackRef(getAriaValueTextProp);
  const isReversed = getIsReversed({
    isReversed: isReversedProp,
    direction,
    orientation
  });
  const [valueState, setValue] = useControllableState({
    value: valueProp,
    defaultValue: defaultValue ?? [25, 75],
    onChange
  });
  if (!Array.isArray(valueState)) {
    throw new TypeError(`[range-slider] You passed an invalid value for \`value\` or \`defaultValue\`, expected \`Array\` but got \`${typeof valueState}\``);
  }
  const [isDragging, setDragging] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const isInteractive = !(isDisabled || isReadOnly);
  const initialValue = useRef(valueState);
  const value = valueState.map((val) => clampValue(val, min, max));
  const spacing = minStepsBetweenThumbs * step;
  const valueBounds = getValueBounds(value, min, max, spacing);
  const stateRef = useRef({
    eventSource: null,
    value: [],
    valueBounds: []
  });
  stateRef.current.value = value;
  stateRef.current.valueBounds = valueBounds;
  const reversedValue = value.map((val) => max - val + min);
  const thumbValues = isReversed ? reversedValue : value;
  const thumbPercents = thumbValues.map((val) => valueToPercent(val, min, max));
  const isVertical = orientation === "vertical";
  const trackRef = useRef(null);
  const rootRef = useRef(null);
  const thumbRects = useSizes({
    getNodes() {
      const rootNode = rootRef.current;
      const thumbNodes = rootNode == null ? void 0 : rootNode.querySelectorAll("[role=slider]");
      return thumbNodes ? Array.from(thumbNodes) : [];
    }
  });
  const reactId = useId();
  const uuid = idProp ?? reactId;
  const ids = getIds(uuid);
  const getValueFromPointer = useCallback((event) => {
    var _a;
    if (!trackRef.current)
      return;
    stateRef.current.eventSource = "pointer";
    const rect = trackRef.current.getBoundingClientRect();
    const { clientX, clientY } = ((_a = event.touches) == null ? void 0 : _a[0]) ?? event;
    const diff = isVertical ? rect.bottom - clientY : clientX - rect.left;
    const length = isVertical ? rect.height : rect.width;
    let percent = diff / length;
    if (isReversed)
      percent = 1 - percent;
    return percentToValue(percent, min, max);
  }, [isVertical, isReversed, max, min]);
  const tenSteps = (max - min) / 10;
  const oneStep = step || (max - min) / 100;
  const actions = useMemo(() => ({
    setValueAtIndex(index, val) {
      if (!isInteractive)
        return;
      const bounds = stateRef.current.valueBounds[index];
      val = parseFloat(roundValueToStep(val, bounds.min, oneStep));
      val = clampValue(val, bounds.min, bounds.max);
      const next = [...stateRef.current.value];
      next[index] = val;
      setValue(next);
    },
    setActiveIndex,
    stepUp(index, step2 = oneStep) {
      const valueAtIndex = stateRef.current.value[index];
      const next = isReversed ? valueAtIndex - step2 : valueAtIndex + step2;
      actions.setValueAtIndex(index, next);
    },
    stepDown(index, step2 = oneStep) {
      const valueAtIndex = stateRef.current.value[index];
      const next = isReversed ? valueAtIndex + step2 : valueAtIndex - step2;
      actions.setValueAtIndex(index, next);
    },
    reset() {
      setValue(initialValue.current);
    }
  }), [oneStep, isReversed, setValue, isInteractive]);
  const onKeyDown = useCallback((event) => {
    const eventKey = event.key;
    const keyMap = {
      ArrowRight: () => actions.stepUp(activeIndex),
      ArrowUp: () => actions.stepUp(activeIndex),
      ArrowLeft: () => actions.stepDown(activeIndex),
      ArrowDown: () => actions.stepDown(activeIndex),
      PageUp: () => actions.stepUp(activeIndex, tenSteps),
      PageDown: () => actions.stepDown(activeIndex, tenSteps),
      Home: () => {
        const { min: value2 } = valueBounds[activeIndex];
        actions.setValueAtIndex(activeIndex, value2);
      },
      End: () => {
        const { max: value2 } = valueBounds[activeIndex];
        actions.setValueAtIndex(activeIndex, value2);
      }
    };
    const action = keyMap[eventKey];
    if (action) {
      event.preventDefault();
      event.stopPropagation();
      action(event);
      stateRef.current.eventSource = "keyboard";
    }
  }, [actions, activeIndex, tenSteps, valueBounds]);
  const { getThumbStyle, rootStyle, trackStyle, innerTrackStyle } = useMemo(() => getStyles({
    isReversed,
    orientation,
    thumbRects,
    thumbPercents
  }), [isReversed, orientation, thumbPercents, thumbRects]);
  const focusThumb = useCallback((index) => {
    var _a;
    const idx = index ?? activeIndex;
    if (idx !== -1 && focusThumbOnChange) {
      const id = ids.getThumb(idx);
      const thumb = (_a = rootRef.current) == null ? void 0 : _a.ownerDocument.getElementById(id);
      if (thumb) {
        setTimeout(() => thumb.focus());
      }
    }
  }, [focusThumbOnChange, activeIndex, ids]);
  useUpdateEffect(() => {
    if (stateRef.current.eventSource === "keyboard") {
      onChangeEnd == null ? void 0 : onChangeEnd(stateRef.current.value);
    }
  }, [value, onChangeEnd]);
  const onPanSessionStart = (event) => {
    const pointValue = getValueFromPointer(event) || 0;
    const distances = stateRef.current.value.map((val) => Math.abs(val - pointValue));
    const closest = Math.min(...distances);
    let index = distances.indexOf(closest);
    const thumbsAtPosition = distances.filter((distance) => distance === closest);
    const isThumbStacked = thumbsAtPosition.length > 1;
    if (isThumbStacked && pointValue > stateRef.current.value[index]) {
      index = index + thumbsAtPosition.length - 1;
    }
    setActiveIndex(index);
    actions.setValueAtIndex(index, pointValue);
    focusThumb(index);
  };
  const onPan = (event) => {
    if (activeIndex == -1)
      return;
    const pointValue = getValueFromPointer(event) || 0;
    setActiveIndex(activeIndex);
    actions.setValueAtIndex(activeIndex, pointValue);
    focusThumb(activeIndex);
  };
  usePanEvent(rootRef, {
    onPanSessionStart(event) {
      if (!isInteractive)
        return;
      setDragging(true);
      onPanSessionStart(event);
      onChangeStart == null ? void 0 : onChangeStart(stateRef.current.value);
    },
    onPanSessionEnd() {
      if (!isInteractive)
        return;
      setDragging(false);
      onChangeEnd == null ? void 0 : onChangeEnd(stateRef.current.value);
    },
    onPan(event) {
      if (!isInteractive)
        return;
      onPan(event);
    }
  });
  const getRootProps = useCallback((props2 = {}, ref = null) => {
    return {
      ...props2,
      ...htmlProps,
      id: ids.root,
      ref: mergeRefs(ref, rootRef),
      tabIndex: -1,
      "aria-disabled": ariaAttr(isDisabled),
      "data-focused": dataAttr(isFocused),
      style: { ...props2.style, ...rootStyle }
    };
  }, [htmlProps, isDisabled, isFocused, rootStyle, ids]);
  const getTrackProps = useCallback((props2 = {}, ref = null) => {
    return {
      ...props2,
      ref: mergeRefs(ref, trackRef),
      id: ids.track,
      "data-disabled": dataAttr(isDisabled),
      style: { ...props2.style, ...trackStyle }
    };
  }, [isDisabled, trackStyle, ids]);
  const getInnerTrackProps = useCallback((props2 = {}, ref = null) => {
    return {
      ...props2,
      ref,
      id: ids.innerTrack,
      style: {
        ...props2.style,
        ...innerTrackStyle
      }
    };
  }, [innerTrackStyle, ids]);
  const getThumbProps = useCallback((props2, ref = null) => {
    const { index, ...rest } = props2;
    const valueAtIndex = value[index];
    if (valueAtIndex == null) {
      throw new TypeError(`[range-slider > thumb] Cannot find value at index \`${index}\`. The \`value\` or \`defaultValue\` length is : ${value.length}`);
    }
    const bounds = valueBounds[index];
    return {
      ...rest,
      ref,
      role: "slider",
      tabIndex: isInteractive ? 0 : void 0,
      id: ids.getThumb(index),
      "data-active": dataAttr(isDragging && activeIndex === index),
      "aria-valuetext": (getAriaValueText == null ? void 0 : getAriaValueText(valueAtIndex)) ?? (ariaValueText == null ? void 0 : ariaValueText[index]),
      "aria-valuemin": bounds.min,
      "aria-valuemax": bounds.max,
      "aria-valuenow": valueAtIndex,
      "aria-orientation": orientation,
      "aria-disabled": ariaAttr(isDisabled),
      "aria-readonly": ariaAttr(isReadOnly),
      "aria-label": ariaLabel == null ? void 0 : ariaLabel[index],
      "aria-labelledby": (ariaLabel == null ? void 0 : ariaLabel[index]) ? void 0 : ariaLabelledBy == null ? void 0 : ariaLabelledBy[index],
      style: { ...props2.style, ...getThumbStyle(index) },
      onKeyDown: callAllHandlers(props2.onKeyDown, onKeyDown),
      onFocus: callAllHandlers(props2.onFocus, () => {
        setFocused(true);
        setActiveIndex(index);
      }),
      onBlur: callAllHandlers(props2.onBlur, () => {
        setFocused(false);
        setActiveIndex(-1);
      })
    };
  }, [
    ids,
    value,
    valueBounds,
    isInteractive,
    isDragging,
    activeIndex,
    getAriaValueText,
    ariaValueText,
    orientation,
    isDisabled,
    isReadOnly,
    ariaLabel,
    ariaLabelledBy,
    getThumbStyle,
    onKeyDown,
    setFocused
  ]);
  const getOutputProps = useCallback((props2 = {}, ref = null) => {
    return {
      ...props2,
      ref,
      id: ids.output,
      htmlFor: value.map((v, i) => ids.getThumb(i)).join(" "),
      "aria-live": "off"
    };
  }, [ids, value]);
  const getMarkerProps = useCallback((props2, ref = null) => {
    const { value: v, ...rest } = props2;
    const isInRange = !(v < min || v > max);
    const isHighlighted = v >= value[0] && v <= value[value.length - 1];
    let percent = valueToPercent(v, min, max);
    percent = isReversed ? 100 - percent : percent;
    const markerStyle = {
      position: "absolute",
      pointerEvents: "none",
      ...orient({
        orientation,
        vertical: { bottom: `${percent}%` },
        horizontal: { left: `${percent}%` }
      })
    };
    return {
      ...rest,
      ref,
      id: ids.getMarker(props2.value),
      role: "presentation",
      "aria-hidden": true,
      "data-disabled": dataAttr(isDisabled),
      "data-invalid": dataAttr(!isInRange),
      "data-highlighted": dataAttr(isHighlighted),
      style: {
        ...props2.style,
        ...markerStyle
      }
    };
  }, [isDisabled, isReversed, max, min, orientation, value, ids]);
  const getInputProps = useCallback((props2, ref = null) => {
    const { index, ...rest } = props2;
    return {
      ...rest,
      ref,
      id: ids.getInput(index),
      type: "hidden",
      value: value[index],
      name: Array.isArray(name) ? name[index] : `${name}-${index}`
    };
  }, [name, value, ids]);
  return {
    state: {
      value,
      isFocused,
      isDragging,
      getThumbPercent: (index) => thumbPercents[index],
      getThumbMinValue: (index) => valueBounds[index].min,
      getThumbMaxValue: (index) => valueBounds[index].max
    },
    actions,
    getRootProps,
    getTrackProps,
    getInnerTrackProps,
    getThumbProps,
    getMarkerProps,
    getInputProps,
    getOutputProps
  };
}
function getValueBounds(arr, min, max, spacing) {
  return arr.map((v, i) => {
    const _min = i === 0 ? min : arr[i - 1] + spacing;
    const _max = i === arr.length - 1 ? max : arr[i + 1] - spacing;
    return { min: _min, max: _max };
  });
}

// src/range-slider.tsx
var [RangeSliderProvider, useRangeSliderContext] = createContext({
  name: "SliderContext",
  errorMessage: "useSliderContext: `context` is undefined. Seems you forgot to wrap all slider components within <RangeSlider />"
});
var [RangeSliderStylesProvider, useRangeSliderStyles] = createContext({
  name: `RangeSliderStylesContext`,
  errorMessage: `useRangeSliderStyles returned is 'undefined'. Seems you forgot to wrap the components in "<RangeSlider />" `
});
var RangeSlider = forwardRef(function RangeSlider2(props, ref) {
  const styles = useMultiStyleConfig("Slider", props);
  const ownProps = omitThemingProps(props);
  const { direction } = useTheme();
  ownProps.direction = direction;
  const { getRootProps, ...context } = useRangeSlider(ownProps);
  const ctx = useMemo2(() => ({ ...context, name: props.name }), [context, props.name]);
  return /* @__PURE__ */ React.createElement(RangeSliderProvider, {
    value: ctx
  }, /* @__PURE__ */ React.createElement(RangeSliderStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(chakra.div, {
    ...getRootProps({}, ref),
    className: "chakra-slider",
    __css: styles.container
  }, props.children)));
});
RangeSlider.defaultProps = {
  orientation: "horizontal"
};
RangeSlider.displayName = "RangeSlider";
var RangeSliderThumb = forwardRef(function RangeSliderThumb2(props, ref) {
  const { getThumbProps, getInputProps, name } = useRangeSliderContext();
  const styles = useRangeSliderStyles();
  const thumbProps = getThumbProps(props, ref);
  return /* @__PURE__ */ React.createElement(chakra.div, {
    ...thumbProps,
    className: cx("chakra-slider__thumb", props.className),
    __css: styles.thumb
  }, thumbProps.children, name && /* @__PURE__ */ React.createElement("input", {
    ...getInputProps({ index: props.index })
  }));
});
RangeSliderThumb.displayName = "RangeSliderThumb";
var RangeSliderTrack = forwardRef(function RangeSliderTrack2(props, ref) {
  const { getTrackProps } = useRangeSliderContext();
  const styles = useRangeSliderStyles();
  const trackProps = getTrackProps(props, ref);
  return /* @__PURE__ */ React.createElement(chakra.div, {
    ...trackProps,
    className: cx("chakra-slider__track", props.className),
    __css: styles.track,
    "data-testid": "chakra-range-slider-track"
  });
});
RangeSliderTrack.displayName = "RangeSliderTrack";
var RangeSliderFilledTrack = forwardRef(function RangeSliderFilledTrack2(props, ref) {
  const { getInnerTrackProps } = useRangeSliderContext();
  const styles = useRangeSliderStyles();
  const trackProps = getInnerTrackProps(props, ref);
  return /* @__PURE__ */ React.createElement(chakra.div, {
    ...trackProps,
    className: "chakra-slider__filled-track",
    __css: styles.filledTrack
  });
});
RangeSliderFilledTrack.displayName = "RangeSliderFilledTrack";
var RangeSliderMark = forwardRef(function RangeSliderMark2(props, ref) {
  const { getMarkerProps } = useRangeSliderContext();
  const markProps = getMarkerProps(props, ref);
  return /* @__PURE__ */ React.createElement(chakra.div, {
    ...markProps,
    className: cx("chakra-slider__marker", props.className)
  });
});
RangeSliderMark.displayName = "RangeSliderMark";

// src/slider.tsx
init_react_shim();
import { createContext as createContext2 } from "@chakra-ui/react-context";
import {
  chakra as chakra2,
  forwardRef as forwardRef2,
  omitThemingProps as omitThemingProps2,
  useMultiStyleConfig as useMultiStyleConfig2,
  useTheme as useTheme2
} from "@chakra-ui/system";

// src/use-slider.ts
init_react_shim();
import { usePanEvent as usePanEvent2 } from "@chakra-ui/react-use-pan-event";
import { useCallbackRef as useCallbackRef2 } from "@chakra-ui/react-use-callback-ref";
import { useUpdateEffect as useUpdateEffect2 } from "@chakra-ui/react-use-update-effect";
import { useControllableState as useControllableState2 } from "@chakra-ui/react-use-controllable-state";
import { useSize } from "@chakra-ui/react-use-size";
import { mergeRefs as mergeRefs2 } from "@chakra-ui/react-use-merge-refs";
import { useLatestRef } from "@chakra-ui/react-use-latest-ref";
import {
  clampValue as clampValue2,
  percentToValue as percentToValue2,
  roundValueToStep as roundValueToStep2,
  valueToPercent as valueToPercent2
} from "@chakra-ui/number-utils";
import { useCallback as useCallback2, useMemo as useMemo3, useRef as useRef2, useId as useId2, useState as useState2 } from "react";
function useSlider(props) {
  const {
    min = 0,
    max = 100,
    onChange,
    value: valueProp,
    defaultValue,
    isReversed: isReversedProp,
    direction = "ltr",
    orientation = "horizontal",
    id: idProp,
    isDisabled,
    isReadOnly,
    onChangeStart: onChangeStartProp,
    onChangeEnd: onChangeEndProp,
    step = 1,
    getAriaValueText: getAriaValueTextProp,
    "aria-valuetext": ariaValueText,
    "aria-label": ariaLabel,
    "aria-labelledby": ariaLabelledBy,
    name,
    focusThumbOnChange = true,
    ...htmlProps
  } = props;
  const onChangeStart = useCallbackRef2(onChangeStartProp);
  const onChangeEnd = useCallbackRef2(onChangeEndProp);
  const getAriaValueText = useCallbackRef2(getAriaValueTextProp);
  const isReversed = getIsReversed({
    isReversed: isReversedProp,
    direction,
    orientation
  });
  const [computedValue, setValue] = useControllableState2({
    value: valueProp,
    defaultValue: defaultValue ?? getDefaultValue(min, max),
    onChange
  });
  const [isDragging, setDragging] = useState2(false);
  const [isFocused, setFocused] = useState2(false);
  const isInteractive = !(isDisabled || isReadOnly);
  const tenSteps = (max - min) / 10;
  const oneStep = step || (max - min) / 100;
  const value = clampValue2(computedValue, min, max);
  const reversedValue = max - value + min;
  const trackValue = isReversed ? reversedValue : value;
  const thumbPercent = valueToPercent2(trackValue, min, max);
  const isVertical = orientation === "vertical";
  const stateRef = useLatestRef({
    min,
    max,
    step,
    isDisabled,
    value,
    isInteractive,
    isReversed,
    isVertical,
    eventSource: null,
    focusThumbOnChange,
    orientation
  });
  const trackRef = useRef2(null);
  const thumbRef = useRef2(null);
  const rootRef = useRef2(null);
  const reactId = useId2();
  const uuid = idProp ?? reactId;
  const [thumbId, trackId] = [`slider-thumb-${uuid}`, `slider-track-${uuid}`];
  const getValueFromPointer = useCallback2((event) => {
    var _a;
    if (!trackRef.current)
      return;
    const state2 = stateRef.current;
    state2.eventSource = "pointer";
    const trackRect = trackRef.current.getBoundingClientRect();
    const { clientX, clientY } = ((_a = event.touches) == null ? void 0 : _a[0]) ?? event;
    const diff = isVertical ? trackRect.bottom - clientY : clientX - trackRect.left;
    const length = isVertical ? trackRect.height : trackRect.width;
    let percent = diff / length;
    if (isReversed) {
      percent = 1 - percent;
    }
    let nextValue = percentToValue2(percent, state2.min, state2.max);
    if (state2.step) {
      nextValue = parseFloat(roundValueToStep2(nextValue, state2.min, state2.step));
    }
    nextValue = clampValue2(nextValue, state2.min, state2.max);
    return nextValue;
  }, [isVertical, isReversed, stateRef]);
  const constrain = useCallback2((value2) => {
    const state2 = stateRef.current;
    if (!state2.isInteractive)
      return;
    value2 = parseFloat(roundValueToStep2(value2, state2.min, oneStep));
    value2 = clampValue2(value2, state2.min, state2.max);
    setValue(value2);
  }, [oneStep, setValue, stateRef]);
  const actions = useMemo3(() => ({
    stepUp(step2 = oneStep) {
      const next = isReversed ? value - step2 : value + step2;
      constrain(next);
    },
    stepDown(step2 = oneStep) {
      const next = isReversed ? value + step2 : value - step2;
      constrain(next);
    },
    reset() {
      constrain(defaultValue || 0);
    },
    stepTo(value2) {
      constrain(value2);
    }
  }), [constrain, isReversed, value, oneStep, defaultValue]);
  const onKeyDown = useCallback2((event) => {
    const state2 = stateRef.current;
    const keyMap = {
      ArrowRight: () => actions.stepUp(),
      ArrowUp: () => actions.stepUp(),
      ArrowLeft: () => actions.stepDown(),
      ArrowDown: () => actions.stepDown(),
      PageUp: () => actions.stepUp(tenSteps),
      PageDown: () => actions.stepDown(tenSteps),
      Home: () => constrain(state2.min),
      End: () => constrain(state2.max)
    };
    const action = keyMap[event.key];
    if (action) {
      event.preventDefault();
      event.stopPropagation();
      action(event);
      state2.eventSource = "keyboard";
    }
  }, [actions, constrain, tenSteps, stateRef]);
  const valueText = (getAriaValueText == null ? void 0 : getAriaValueText(value)) ?? ariaValueText;
  const thumbSize = useSize(thumbRef);
  const { getThumbStyle, rootStyle, trackStyle, innerTrackStyle } = useMemo3(() => {
    const state2 = stateRef.current;
    const thumbRect = thumbSize ?? { width: 0, height: 0 };
    return getStyles({
      isReversed,
      orientation: state2.orientation,
      thumbRects: [thumbRect],
      thumbPercents: [thumbPercent]
    });
  }, [isReversed, thumbSize, thumbPercent, stateRef]);
  const focusThumb = useCallback2(() => {
    const state2 = stateRef.current;
    if (state2.focusThumbOnChange) {
      setTimeout(() => {
        var _a;
        return (_a = thumbRef.current) == null ? void 0 : _a.focus();
      });
    }
  }, [stateRef]);
  useUpdateEffect2(() => {
    const state2 = stateRef.current;
    focusThumb();
    if (state2.eventSource === "keyboard") {
      onChangeEnd == null ? void 0 : onChangeEnd(state2.value);
    }
  }, [value, onChangeEnd]);
  function setValueFromPointer(event) {
    const nextValue = getValueFromPointer(event);
    if (nextValue != null && nextValue !== stateRef.current.value) {
      setValue(nextValue);
    }
  }
  usePanEvent2(rootRef, {
    onPanSessionStart(event) {
      const state2 = stateRef.current;
      if (!state2.isInteractive)
        return;
      setDragging(true);
      focusThumb();
      setValueFromPointer(event);
      onChangeStart == null ? void 0 : onChangeStart(state2.value);
    },
    onPanSessionEnd() {
      const state2 = stateRef.current;
      if (!state2.isInteractive)
        return;
      setDragging(false);
      onChangeEnd == null ? void 0 : onChangeEnd(state2.value);
    },
    onPan(event) {
      const state2 = stateRef.current;
      if (!state2.isInteractive)
        return;
      setValueFromPointer(event);
    }
  });
  const getRootProps = useCallback2((props2 = {}, ref = null) => {
    return {
      ...props2,
      ...htmlProps,
      ref: mergeRefs2(ref, rootRef),
      tabIndex: -1,
      "aria-disabled": ariaAttr(isDisabled),
      "data-focused": dataAttr(isFocused),
      style: {
        ...props2.style,
        ...rootStyle
      }
    };
  }, [htmlProps, isDisabled, isFocused, rootStyle]);
  const getTrackProps = useCallback2((props2 = {}, ref = null) => {
    return {
      ...props2,
      ref: mergeRefs2(ref, trackRef),
      id: trackId,
      "data-disabled": dataAttr(isDisabled),
      style: {
        ...props2.style,
        ...trackStyle
      }
    };
  }, [isDisabled, trackId, trackStyle]);
  const getInnerTrackProps = useCallback2((props2 = {}, ref = null) => {
    return {
      ...props2,
      ref,
      style: {
        ...props2.style,
        ...innerTrackStyle
      }
    };
  }, [innerTrackStyle]);
  const getThumbProps = useCallback2((props2 = {}, ref = null) => {
    return {
      ...props2,
      ref: mergeRefs2(ref, thumbRef),
      role: "slider",
      tabIndex: isInteractive ? 0 : void 0,
      id: thumbId,
      "data-active": dataAttr(isDragging),
      "aria-valuetext": valueText,
      "aria-valuemin": min,
      "aria-valuemax": max,
      "aria-valuenow": value,
      "aria-orientation": orientation,
      "aria-disabled": ariaAttr(isDisabled),
      "aria-readonly": ariaAttr(isReadOnly),
      "aria-label": ariaLabel,
      "aria-labelledby": ariaLabel ? void 0 : ariaLabelledBy,
      style: {
        ...props2.style,
        ...getThumbStyle(0)
      },
      onKeyDown: callAllHandlers(props2.onKeyDown, onKeyDown),
      onFocus: callAllHandlers(props2.onFocus, () => setFocused(true)),
      onBlur: callAllHandlers(props2.onBlur, () => setFocused(false))
    };
  }, [
    isInteractive,
    thumbId,
    isDragging,
    valueText,
    min,
    max,
    value,
    orientation,
    isDisabled,
    isReadOnly,
    ariaLabel,
    ariaLabelledBy,
    getThumbStyle,
    onKeyDown
  ]);
  const getMarkerProps = useCallback2((props2, ref = null) => {
    const isInRange = !(props2.value < min || props2.value > max);
    const isHighlighted = value >= props2.value;
    const markerPercent = valueToPercent2(props2.value, min, max);
    const markerStyle = {
      position: "absolute",
      pointerEvents: "none",
      ...orient2({
        orientation,
        vertical: {
          bottom: isReversed ? `${100 - markerPercent}%` : `${markerPercent}%`
        },
        horizontal: {
          left: isReversed ? `${100 - markerPercent}%` : `${markerPercent}%`
        }
      })
    };
    return {
      ...props2,
      ref,
      role: "presentation",
      "aria-hidden": true,
      "data-disabled": dataAttr(isDisabled),
      "data-invalid": dataAttr(!isInRange),
      "data-highlighted": dataAttr(isHighlighted),
      style: {
        ...props2.style,
        ...markerStyle
      }
    };
  }, [isDisabled, isReversed, max, min, orientation, value]);
  const getInputProps = useCallback2((props2 = {}, ref = null) => {
    return {
      ...props2,
      ref,
      type: "hidden",
      value,
      name
    };
  }, [name, value]);
  const state = { value, isFocused, isDragging };
  return {
    state,
    actions,
    getRootProps,
    getTrackProps,
    getInnerTrackProps,
    getThumbProps,
    getMarkerProps,
    getInputProps
  };
}
function orient2(options) {
  const { orientation, vertical, horizontal } = options;
  return orientation === "vertical" ? vertical : horizontal;
}
function getDefaultValue(min, max) {
  return max < min ? min : min + (max - min) / 2;
}

// src/slider.tsx
var [SliderProvider, useSliderContext] = createContext2({
  name: "SliderContext",
  hookName: "useSliderContext",
  providerName: "<Slider />"
});
var [SliderStylesProvider, useSliderStyles] = createContext2({
  name: `SliderStylesContext`,
  hookName: `useSliderStyles`,
  providerName: "<Slider />"
});
var Slider = forwardRef2((props, ref) => {
  const styles = useMultiStyleConfig2("Slider", props);
  const ownProps = omitThemingProps2(props);
  const { direction } = useTheme2();
  ownProps.direction = direction;
  const { getInputProps, getRootProps, ...context } = useSlider(ownProps);
  const rootProps = getRootProps();
  const inputProps = getInputProps({}, ref);
  return /* @__PURE__ */ React.createElement(SliderProvider, {
    value: context
  }, /* @__PURE__ */ React.createElement(SliderStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(chakra2.div, {
    ...rootProps,
    className: cx("chakra-slider", props.className),
    __css: styles.container
  }, props.children, /* @__PURE__ */ React.createElement("input", {
    ...inputProps
  }))));
});
Slider.defaultProps = {
  orientation: "horizontal"
};
Slider.displayName = "Slider";
var SliderThumb = forwardRef2((props, ref) => {
  const { getThumbProps } = useSliderContext();
  const styles = useSliderStyles();
  const thumbProps = getThumbProps(props, ref);
  return /* @__PURE__ */ React.createElement(chakra2.div, {
    ...thumbProps,
    className: cx("chakra-slider__thumb", props.className),
    __css: styles.thumb
  });
});
SliderThumb.displayName = "SliderThumb";
var SliderTrack = forwardRef2((props, ref) => {
  const { getTrackProps } = useSliderContext();
  const styles = useSliderStyles();
  const trackProps = getTrackProps(props, ref);
  return /* @__PURE__ */ React.createElement(chakra2.div, {
    ...trackProps,
    className: cx("chakra-slider__track", props.className),
    __css: styles.track
  });
});
SliderTrack.displayName = "SliderTrack";
var SliderFilledTrack = forwardRef2((props, ref) => {
  const { getInnerTrackProps } = useSliderContext();
  const styles = useSliderStyles();
  const trackProps = getInnerTrackProps(props, ref);
  return /* @__PURE__ */ React.createElement(chakra2.div, {
    ...trackProps,
    className: cx("chakra-slider__filled-track", props.className),
    __css: styles.filledTrack
  });
});
SliderFilledTrack.displayName = "SliderFilledTrack";
var SliderMark = forwardRef2((props, ref) => {
  const { getMarkerProps } = useSliderContext();
  const styles = useSliderStyles();
  const markProps = getMarkerProps(props, ref);
  return /* @__PURE__ */ React.createElement(chakra2.div, {
    ...markProps,
    className: cx("chakra-slider__marker", props.className),
    __css: styles.mark
  });
});
SliderMark.displayName = "SliderMark";
export {
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderMark,
  RangeSliderProvider,
  RangeSliderThumb,
  RangeSliderTrack,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderProvider,
  SliderThumb,
  SliderTrack,
  useRangeSlider,
  useRangeSliderContext,
  useRangeSliderStyles,
  useSlider,
  useSliderContext,
  useSliderStyles
};
