// ../../../react-shim.js
import React from "react";

// ../../utilities/shared-utils/src/index.ts
var cx = (...classNames) => classNames.filter(Boolean).join(" ");
function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn == null ? void 0 : fn(event);
      return event == null ? void 0 : event.defaultPrevented;
    });
  };
}

// src/tab-indicator.tsx
import { chakra as chakra2, forwardRef as forwardRef2 } from "@chakra-ui/system";

// src/tabs.tsx
import { createContext as createContext2 } from "@chakra-ui/react-context";
import {
  chakra,
  forwardRef,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";
import { useMemo } from "react";

// src/use-tabs.ts
import { useClickable } from "@chakra-ui/clickable";
import { createDescendantContext } from "@chakra-ui/descendant";
import { createContext } from "@chakra-ui/react-context";
import { useSafeLayoutEffect } from "@chakra-ui/react-use-safe-layout-effect";
import { useControllableState } from "@chakra-ui/react-use-controllable-state";
import { getValidChildren } from "@chakra-ui/react-children-utils";
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";
import { lazyDisclosure } from "@chakra-ui/lazy-utils";
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  useId,
  createElement
} from "react";
var [
  TabsDescendantsProvider,
  useTabsDescendantsContext,
  useTabsDescendants,
  useTabsDescendant
] = createDescendantContext();
function useTabs(props) {
  const {
    defaultIndex,
    onChange,
    index,
    isManual,
    isLazy,
    lazyBehavior = "unmount",
    orientation = "horizontal",
    direction = "ltr",
    ...htmlProps
  } = props;
  const [focusedIndex, setFocusedIndex] = useState(defaultIndex ?? 0);
  const [selectedIndex, setSelectedIndex] = useControllableState({
    defaultValue: defaultIndex ?? 0,
    value: index,
    onChange
  });
  useEffect(() => {
    if (index != null) {
      setFocusedIndex(index);
    }
  }, [index]);
  const descendants = useTabsDescendants();
  const uuid = useId();
  const uid = props.id ?? uuid;
  const id = `tabs-${uid}`;
  return {
    id,
    selectedIndex,
    focusedIndex,
    setSelectedIndex,
    setFocusedIndex,
    isManual,
    isLazy,
    lazyBehavior,
    orientation,
    descendants,
    direction,
    htmlProps
  };
}
var [TabsProvider, useTabsContext] = createContext({
  name: "TabsContext",
  errorMessage: "useTabsContext: `context` is undefined. Seems you forgot to wrap all tabs components within <Tabs />"
});
function useTabList(props) {
  const { focusedIndex, orientation, direction } = useTabsContext();
  const descendants = useTabsDescendantsContext();
  const onKeyDown = useCallback((event) => {
    const nextTab = () => {
      var _a;
      const next = descendants.nextEnabled(focusedIndex);
      if (next)
        (_a = next.node) == null ? void 0 : _a.focus();
    };
    const prevTab = () => {
      var _a;
      const prev = descendants.prevEnabled(focusedIndex);
      if (prev)
        (_a = prev.node) == null ? void 0 : _a.focus();
    };
    const firstTab = () => {
      var _a;
      const first = descendants.firstEnabled();
      if (first)
        (_a = first.node) == null ? void 0 : _a.focus();
    };
    const lastTab = () => {
      var _a;
      const last = descendants.lastEnabled();
      if (last)
        (_a = last.node) == null ? void 0 : _a.focus();
    };
    const isHorizontal = orientation === "horizontal";
    const isVertical = orientation === "vertical";
    const eventKey = event.key;
    const ArrowStart = direction === "ltr" ? "ArrowLeft" : "ArrowRight";
    const ArrowEnd = direction === "ltr" ? "ArrowRight" : "ArrowLeft";
    const keyMap = {
      [ArrowStart]: () => isHorizontal && prevTab(),
      [ArrowEnd]: () => isHorizontal && nextTab(),
      ArrowDown: () => isVertical && nextTab(),
      ArrowUp: () => isVertical && prevTab(),
      Home: firstTab,
      End: lastTab
    };
    const action = keyMap[eventKey];
    if (action) {
      event.preventDefault();
      action(event);
    }
  }, [descendants, focusedIndex, orientation, direction]);
  return {
    ...props,
    role: "tablist",
    "aria-orientation": orientation,
    onKeyDown: callAllHandlers(props.onKeyDown, onKeyDown)
  };
}
function useTab(props) {
  const { isDisabled, isFocusable, ...htmlProps } = props;
  const { setSelectedIndex, isManual, id, setFocusedIndex, selectedIndex } = useTabsContext();
  const { index, register } = useTabsDescendant({
    disabled: isDisabled && !isFocusable
  });
  const isSelected = index === selectedIndex;
  const onClick = () => {
    setSelectedIndex(index);
  };
  const onFocus = () => {
    setFocusedIndex(index);
    const isDisabledButFocusable = isDisabled && isFocusable;
    const shouldSelect = !isManual && !isDisabledButFocusable;
    if (shouldSelect) {
      setSelectedIndex(index);
    }
  };
  const clickableProps = useClickable({
    ...htmlProps,
    ref: mergeRefs(register, props.ref),
    isDisabled,
    isFocusable,
    onClick: callAllHandlers(props.onClick, onClick)
  });
  const type = "button";
  return {
    ...clickableProps,
    id: makeTabId(id, index),
    role: "tab",
    tabIndex: isSelected ? 0 : -1,
    type,
    "aria-selected": isSelected,
    "aria-controls": makeTabPanelId(id, index),
    onFocus: isDisabled ? void 0 : callAllHandlers(props.onFocus, onFocus)
  };
}
var [TabPanelProvider, useTabPanelContext] = createContext({});
function useTabPanels(props) {
  const context = useTabsContext();
  const { id, selectedIndex } = context;
  const validChildren = getValidChildren(props.children);
  const children = validChildren.map((child, index) => createElement(TabPanelProvider, {
    key: index,
    value: {
      isSelected: index === selectedIndex,
      id: makeTabPanelId(id, index),
      tabId: makeTabId(id, index),
      selectedIndex
    }
  }, child));
  return { ...props, children };
}
function useTabPanel(props) {
  const { children, ...htmlProps } = props;
  const { isLazy, lazyBehavior } = useTabsContext();
  const { isSelected, id, tabId } = useTabPanelContext();
  const hasBeenSelected = useRef(false);
  if (isSelected) {
    hasBeenSelected.current = true;
  }
  const shouldRenderChildren = lazyDisclosure({
    wasSelected: hasBeenSelected.current,
    isSelected,
    enabled: isLazy,
    mode: lazyBehavior
  });
  return {
    tabIndex: 0,
    ...htmlProps,
    children: shouldRenderChildren ? children : null,
    role: "tabpanel",
    "aria-labelledby": tabId,
    hidden: !isSelected,
    id
  };
}
function useTabIndicator() {
  const context = useTabsContext();
  const descendants = useTabsDescendantsContext();
  const { selectedIndex, orientation } = context;
  const isHorizontal = orientation === "horizontal";
  const isVertical = orientation === "vertical";
  const [rect, setRect] = useState(() => {
    if (isHorizontal)
      return { left: 0, width: 0 };
    if (isVertical)
      return { top: 0, height: 0 };
    return void 0;
  });
  const [hasMeasured, setHasMeasured] = useState(false);
  useSafeLayoutEffect(() => {
    if (selectedIndex == null)
      return;
    const tab = descendants.item(selectedIndex);
    if (tab == null)
      return;
    if (isHorizontal) {
      setRect({ left: tab.node.offsetLeft, width: tab.node.offsetWidth });
    }
    if (isVertical) {
      setRect({ top: tab.node.offsetTop, height: tab.node.offsetHeight });
    }
    const id = requestAnimationFrame(() => {
      setHasMeasured(true);
    });
    return () => {
      if (id) {
        cancelAnimationFrame(id);
      }
    };
  }, [selectedIndex, isHorizontal, isVertical, descendants]);
  return {
    position: "absolute",
    transitionProperty: "left, right, top, bottom, height, width",
    transitionDuration: hasMeasured ? "200ms" : "0ms",
    transitionTimingFunction: "cubic-bezier(0, 0, 0.2, 1)",
    ...rect
  };
}
function makeTabId(id, index) {
  return `${id}--tab-${index}`;
}
function makeTabPanelId(id, index) {
  return `${id}--tabpanel-${index}`;
}

// src/tabs.tsx
var [TabsStylesProvider, useTabsStyles] = createContext2({
  name: `TabsStylesContext`,
  errorMessage: `useTabsStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Tabs />" `
});
var Tabs = forwardRef(function Tabs2(props, ref) {
  const styles = useMultiStyleConfig("Tabs", props);
  const { children, className, ...rest } = omitThemingProps(props);
  const { htmlProps, descendants, ...ctx } = useTabs(rest);
  const context = useMemo(() => ctx, [ctx]);
  const { isFitted: _, ...rootProps } = htmlProps;
  return /* @__PURE__ */ React.createElement(TabsDescendantsProvider, {
    value: descendants
  }, /* @__PURE__ */ React.createElement(TabsProvider, {
    value: context
  }, /* @__PURE__ */ React.createElement(TabsStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(chakra.div, {
    className: cx("chakra-tabs", className),
    ref,
    ...rootProps,
    __css: styles.root
  }, children))));
});
Tabs.displayName = "Tabs";

// src/tab-indicator.tsx
var TabIndicator = forwardRef2(function TabIndicator2(props, ref) {
  const indicatorStyle = useTabIndicator();
  const style = {
    ...props.style,
    ...indicatorStyle
  };
  const styles = useTabsStyles();
  return /* @__PURE__ */ React.createElement(chakra2.div, {
    ref,
    ...props,
    className: cx("chakra-tabs__tab-indicator", props.className),
    style,
    __css: styles.indicator
  });
});
TabIndicator.displayName = "TabIndicator";

// src/tab-list.tsx
import {
  chakra as chakra3,
  forwardRef as forwardRef3
} from "@chakra-ui/system";
var TabList = forwardRef3(function TabList2(props, ref) {
  const tablistProps = useTabList({ ...props, ref });
  const styles = useTabsStyles();
  const tablistStyles = {
    display: "flex",
    ...styles.tablist
  };
  return /* @__PURE__ */ React.createElement(chakra3.div, {
    ...tablistProps,
    className: cx("chakra-tabs__tablist", props.className),
    __css: tablistStyles
  });
});
TabList.displayName = "TabList";

// src/tab-panel.tsx
import { chakra as chakra4, forwardRef as forwardRef4 } from "@chakra-ui/system";
var TabPanel = forwardRef4(function TabPanel2(props, ref) {
  const panelProps = useTabPanel({ ...props, ref });
  const styles = useTabsStyles();
  return /* @__PURE__ */ React.createElement(chakra4.div, {
    outline: "0",
    ...panelProps,
    className: cx("chakra-tabs__tab-panel", props.className),
    __css: styles.tabpanel
  });
});
TabPanel.displayName = "TabPanel";

// src/tab-panels.tsx
import { chakra as chakra5, forwardRef as forwardRef5 } from "@chakra-ui/system";
var TabPanels = forwardRef5(function TabPanels2(props, ref) {
  const panelsProps = useTabPanels(props);
  const styles = useTabsStyles();
  return /* @__PURE__ */ React.createElement(chakra5.div, {
    ...panelsProps,
    width: "100%",
    ref,
    className: cx("chakra-tabs__tab-panels", props.className),
    __css: styles.tabpanels
  });
});
TabPanels.displayName = "TabPanels";

// src/tab.tsx
import {
  chakra as chakra6,
  forwardRef as forwardRef6
} from "@chakra-ui/system";
var Tab = forwardRef6(function Tab2(props, ref) {
  const styles = useTabsStyles();
  const tabProps = useTab({ ...props, ref });
  const tabStyles = {
    outline: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...styles.tab
  };
  return /* @__PURE__ */ React.createElement(chakra6.button, {
    ...tabProps,
    className: cx("chakra-tabs__tab", props.className),
    __css: tabStyles
  });
});
Tab.displayName = "Tab";
export {
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TabsDescendantsProvider,
  TabsProvider,
  useTab,
  useTabIndicator,
  useTabList,
  useTabPanel,
  useTabPanels,
  useTabs,
  useTabsContext,
  useTabsDescendant,
  useTabsDescendants,
  useTabsDescendantsContext,
  useTabsStyles
};
