// ../../../react-shim.js
import React from "react";

// src/popover.tsx
import {
  omitThemingProps,
  useMultiStyleConfig,
  useTheme
} from "@chakra-ui/system";

// ../../utilities/shared-utils/src/index.ts
var cx = (...classNames) => classNames.filter(Boolean).join(" ");
function runIfFn(valueOrFn, ...args) {
  return isFunction(valueOrFn) ? valueOrFn(...args) : valueOrFn;
}
var isFunction = (value) => typeof value === "function";
function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn == null ? void 0 : fn(event);
      return event == null ? void 0 : event.defaultPrevented;
    });
  };
}
function callAll(...fns) {
  return function mergedFn(arg) {
    fns.forEach((fn) => {
      fn == null ? void 0 : fn(arg);
    });
  };
}

// src/popover-context.ts
import { createContext } from "@chakra-ui/react-context";
var [PopoverProvider, usePopoverContext] = createContext({
  name: "PopoverContext",
  errorMessage: "usePopoverContext: `context` is undefined. Seems you forgot to wrap all popover components within `<Popover />`"
});
var [PopoverStylesProvider, usePopoverStyles] = createContext({
  name: `PopoverStylesContext`,
  errorMessage: `usePopoverStyles returned is 'undefined'. Seems you forgot to wrap the components in "<Popover />" `
});

// src/use-popover.ts
import { useAnimationState } from "@chakra-ui/react-use-animation-state";
import { useFocusOnPointerDown } from "@chakra-ui/react-use-focus-on-pointer-down";
import {
  useFocusOnHide,
  useFocusOnShow
} from "@chakra-ui/react-use-focus-effect";
import { useDisclosure } from "@chakra-ui/react-use-disclosure";
import { popperCSSVars, usePopper } from "@chakra-ui/popper";
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";
import { lazyDisclosure } from "@chakra-ui/lazy-utils";
import { useCallback, useEffect, useId, useRef, useState } from "react";
var TRIGGER = {
  click: "click",
  hover: "hover"
};
function usePopover(props = {}) {
  const {
    closeOnBlur = true,
    closeOnEsc = true,
    initialFocusRef,
    id,
    returnFocusOnClose = true,
    autoFocus = true,
    arrowSize,
    arrowShadowColor,
    trigger = TRIGGER.click,
    openDelay = 200,
    closeDelay = 200,
    isLazy,
    lazyBehavior = "unmount",
    computePositionOnMount,
    ...popperProps
  } = props;
  const { isOpen, onClose, onOpen, onToggle } = useDisclosure(props);
  const anchorRef = useRef(null);
  const triggerRef = useRef(null);
  const popoverRef = useRef(null);
  const isHoveringRef = useRef(false);
  const hasBeenOpened = useRef(false);
  if (isOpen) {
    hasBeenOpened.current = true;
  }
  const [hasHeader, setHasHeader] = useState(false);
  const [hasBody, setHasBody] = useState(false);
  const uuid = useId();
  const uid = id ?? uuid;
  const [triggerId, popoverId, headerId, bodyId] = [
    "popover-trigger",
    "popover-content",
    "popover-header",
    "popover-body"
  ].map((id2) => `${id2}-${uid}`);
  const {
    referenceRef,
    getArrowProps,
    getPopperProps,
    getArrowInnerProps,
    forceUpdate
  } = usePopper({
    ...popperProps,
    enabled: isOpen || !!computePositionOnMount
  });
  const animated = useAnimationState({ isOpen, ref: popoverRef });
  useFocusOnPointerDown({
    enabled: isOpen,
    ref: triggerRef
  });
  useFocusOnHide(popoverRef, {
    focusRef: triggerRef,
    visible: isOpen,
    shouldFocus: returnFocusOnClose && trigger === TRIGGER.click
  });
  useFocusOnShow(popoverRef, {
    focusRef: initialFocusRef,
    visible: isOpen,
    shouldFocus: autoFocus && trigger === TRIGGER.click
  });
  const shouldRenderChildren = lazyDisclosure({
    wasSelected: hasBeenOpened.current,
    enabled: isLazy,
    mode: lazyBehavior,
    isSelected: animated.present
  });
  const getPopoverProps = useCallback((props2 = {}, _ref = null) => {
    const popoverProps = {
      ...props2,
      style: {
        ...props2.style,
        transformOrigin: popperCSSVars.transformOrigin.varRef,
        [popperCSSVars.arrowSize.var]: arrowSize ? `${arrowSize}px` : void 0,
        [popperCSSVars.arrowShadowColor.var]: arrowShadowColor
      },
      ref: mergeRefs(popoverRef, _ref),
      children: shouldRenderChildren ? props2.children : null,
      id: popoverId,
      tabIndex: -1,
      role: "dialog",
      onKeyDown: callAllHandlers(props2.onKeyDown, (event) => {
        if (closeOnEsc && event.key === "Escape") {
          onClose();
        }
      }),
      onBlur: callAllHandlers(props2.onBlur, (event) => {
        const relatedTarget = getRelatedTarget(event);
        const targetIsPopover = contains(popoverRef.current, relatedTarget);
        const targetIsTrigger = contains(triggerRef.current, relatedTarget);
        const isValidBlur = !targetIsPopover && !targetIsTrigger;
        if (isOpen && closeOnBlur && isValidBlur) {
          onClose();
        }
      }),
      "aria-labelledby": hasHeader ? headerId : void 0,
      "aria-describedby": hasBody ? bodyId : void 0
    };
    if (trigger === TRIGGER.hover) {
      popoverProps.role = "tooltip";
      popoverProps.onMouseEnter = callAllHandlers(props2.onMouseEnter, () => {
        isHoveringRef.current = true;
      });
      popoverProps.onMouseLeave = callAllHandlers(props2.onMouseLeave, (event) => {
        if (event.nativeEvent.relatedTarget === null) {
          return;
        }
        isHoveringRef.current = false;
        setTimeout(() => onClose(), closeDelay);
      });
    }
    return popoverProps;
  }, [
    shouldRenderChildren,
    popoverId,
    hasHeader,
    headerId,
    hasBody,
    bodyId,
    trigger,
    closeOnEsc,
    onClose,
    isOpen,
    closeOnBlur,
    closeDelay,
    arrowShadowColor,
    arrowSize
  ]);
  const getPopoverPositionerProps = useCallback((props2 = {}, forwardedRef = null) => getPopperProps({
    ...props2,
    style: {
      visibility: isOpen ? "visible" : "hidden",
      ...props2.style
    }
  }, forwardedRef), [isOpen, getPopperProps]);
  const getAnchorProps = useCallback((props2, _ref = null) => {
    return {
      ...props2,
      ref: mergeRefs(_ref, anchorRef, referenceRef)
    };
  }, [anchorRef, referenceRef]);
  const openTimeout = useRef();
  const closeTimeout = useRef();
  const maybeReferenceRef = useCallback((node) => {
    if (anchorRef.current == null) {
      referenceRef(node);
    }
  }, [referenceRef]);
  const getTriggerProps = useCallback((props2 = {}, _ref = null) => {
    const triggerProps = {
      ...props2,
      ref: mergeRefs(triggerRef, _ref, maybeReferenceRef),
      id: triggerId,
      "aria-haspopup": "dialog",
      "aria-expanded": isOpen,
      "aria-controls": popoverId
    };
    if (trigger === TRIGGER.click) {
      triggerProps.onClick = callAllHandlers(props2.onClick, onToggle);
    }
    if (trigger === TRIGGER.hover) {
      triggerProps.onFocus = callAllHandlers(props2.onFocus, () => {
        if (openTimeout.current === void 0) {
          onOpen();
        }
      });
      triggerProps.onBlur = callAllHandlers(props2.onBlur, (event) => {
        const relatedTarget = getRelatedTarget(event);
        const isValidBlur = !contains(popoverRef.current, relatedTarget);
        if (isOpen && closeOnBlur && isValidBlur) {
          onClose();
        }
      });
      triggerProps.onKeyDown = callAllHandlers(props2.onKeyDown, (event) => {
        if (event.key === "Escape") {
          onClose();
        }
      });
      triggerProps.onMouseEnter = callAllHandlers(props2.onMouseEnter, () => {
        isHoveringRef.current = true;
        openTimeout.current = window.setTimeout(() => onOpen(), openDelay);
      });
      triggerProps.onMouseLeave = callAllHandlers(props2.onMouseLeave, () => {
        isHoveringRef.current = false;
        if (openTimeout.current) {
          clearTimeout(openTimeout.current);
          openTimeout.current = void 0;
        }
        closeTimeout.current = window.setTimeout(() => {
          if (isHoveringRef.current === false) {
            onClose();
          }
        }, closeDelay);
      });
    }
    return triggerProps;
  }, [
    triggerId,
    isOpen,
    popoverId,
    trigger,
    maybeReferenceRef,
    onToggle,
    onOpen,
    closeOnBlur,
    onClose,
    openDelay,
    closeDelay
  ]);
  useEffect(() => {
    return () => {
      if (openTimeout.current) {
        clearTimeout(openTimeout.current);
      }
      if (closeTimeout.current) {
        clearTimeout(closeTimeout.current);
      }
    };
  }, []);
  const getHeaderProps = useCallback((props2 = {}, ref = null) => ({
    ...props2,
    id: headerId,
    ref: mergeRefs(ref, (node) => {
      setHasHeader(!!node);
    })
  }), [headerId]);
  const getBodyProps = useCallback((props2 = {}, ref = null) => ({
    ...props2,
    id: bodyId,
    ref: mergeRefs(ref, (node) => {
      setHasBody(!!node);
    })
  }), [bodyId]);
  return {
    forceUpdate,
    isOpen,
    onAnimationComplete: animated.onComplete,
    onClose,
    getAnchorProps,
    getArrowProps,
    getArrowInnerProps,
    getPopoverPositionerProps,
    getPopoverProps,
    getTriggerProps,
    getHeaderProps,
    getBodyProps
  };
}
function contains(parent, child) {
  return parent === child || (parent == null ? void 0 : parent.contains(child));
}
function getRelatedTarget(event) {
  const activeEl = event.currentTarget.ownerDocument.activeElement;
  return event.relatedTarget ?? activeEl;
}

// src/popover.tsx
function Popover(props) {
  const styles = useMultiStyleConfig("Popover", props);
  const { children, ...rest } = omitThemingProps(props);
  const theme = useTheme();
  const context = usePopover({ ...rest, direction: theme.direction });
  return /* @__PURE__ */ React.createElement(PopoverProvider, {
    value: context
  }, /* @__PURE__ */ React.createElement(PopoverStylesProvider, {
    value: styles
  }, runIfFn(children, {
    isOpen: context.isOpen,
    onClose: context.onClose,
    forceUpdate: context.forceUpdate
  })));
}
Popover.displayName = "Popover";

// src/popover-anchor.tsx
import { Children, cloneElement } from "react";
function PopoverAnchor(props) {
  const child = Children.only(props.children);
  const { getAnchorProps } = usePopoverContext();
  return cloneElement(child, getAnchorProps(child.props, child.ref));
}
PopoverAnchor.displayName = "PopoverAnchor";

// src/popover-arrow.tsx
import { chakra } from "@chakra-ui/system";
function PopoverArrow(props) {
  const { bg, bgColor, backgroundColor } = props;
  const { getArrowProps, getArrowInnerProps } = usePopoverContext();
  const styles = usePopoverStyles();
  const arrowBg = bg ?? bgColor ?? backgroundColor;
  return /* @__PURE__ */ React.createElement(chakra.div, {
    ...getArrowProps(),
    className: "chakra-popover__arrow-positioner"
  }, /* @__PURE__ */ React.createElement(chakra.div, {
    className: cx("chakra-popover__arrow", props.className),
    ...getArrowInnerProps(props),
    __css: {
      ...styles.arrow,
      "--popper-arrow-bg": arrowBg ? `colors.${arrowBg}, ${arrowBg}` : void 0
    }
  }));
}
PopoverArrow.displayName = "PopoverArrow";

// src/popover-body.tsx
import { chakra as chakra2, forwardRef } from "@chakra-ui/system";
var PopoverBody = forwardRef(function PopoverBody2(props, ref) {
  const { getBodyProps } = usePopoverContext();
  const styles = usePopoverStyles();
  return /* @__PURE__ */ React.createElement(chakra2.div, {
    ...getBodyProps(props, ref),
    className: cx("chakra-popover__body", props.className),
    __css: styles.body
  });
});
PopoverBody.displayName = "PopoverBody";

// src/popover-close-button.tsx
import { CloseButton } from "@chakra-ui/close-button";
import { forwardRef as forwardRef2 } from "@chakra-ui/system";
var PopoverCloseButton = forwardRef2(function PopoverCloseButton2(props, ref) {
  const { onClose } = usePopoverContext();
  const styles = usePopoverStyles();
  return /* @__PURE__ */ React.createElement(CloseButton, {
    size: "sm",
    onClick: onClose,
    className: cx("chakra-popover__close-btn", props.className),
    __css: styles.closeButton,
    ref,
    ...props
  });
});
PopoverCloseButton.displayName = "PopoverCloseButton";

// src/popover-content.tsx
import {
  chakra as chakra4,
  forwardRef as forwardRef4
} from "@chakra-ui/system";

// src/popover-transition.tsx
import { chakra as chakra3, forwardRef as forwardRef3 } from "@chakra-ui/system";
import { motion } from "framer-motion";
import React2 from "react";
function mergeVariants(variants) {
  if (!variants)
    return;
  return {
    enter: {
      ...variants.enter,
      visibility: "visible"
    },
    exit: {
      ...variants.exit,
      transitionEnd: {
        visibility: "hidden"
      }
    }
  };
}
var scaleFade = {
  exit: {
    opacity: 0,
    scale: 0.95,
    transition: {
      duration: 0.1,
      ease: [0.4, 0, 1, 1]
    }
  },
  enter: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.15,
      ease: [0, 0, 0.2, 1]
    }
  }
};
var MotionSection = chakra3(motion.section);
var PopoverTransition = forwardRef3(function PopoverTransition2(props, ref) {
  const { variants = scaleFade, ...rest } = props;
  const { isOpen } = usePopoverContext();
  return /* @__PURE__ */ React2.createElement(MotionSection, {
    ref,
    variants: mergeVariants(variants),
    initial: false,
    animate: isOpen ? "enter" : "exit",
    ...rest
  });
});
PopoverTransition.displayName = "PopoverTransition";

// src/popover-content.tsx
var PopoverContent = forwardRef4(function PopoverContent2(props, ref) {
  const { rootProps, motionProps, ...contentProps } = props;
  const { getPopoverProps, getPopoverPositionerProps, onAnimationComplete } = usePopoverContext();
  const styles = usePopoverStyles();
  const contentStyles = {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    ...styles.content
  };
  return /* @__PURE__ */ React.createElement(chakra4.div, {
    ...getPopoverPositionerProps(rootProps),
    __css: styles.popper,
    className: "chakra-popover__popper"
  }, /* @__PURE__ */ React.createElement(PopoverTransition, {
    ...motionProps,
    ...getPopoverProps(contentProps, ref),
    onAnimationComplete: callAll(onAnimationComplete, contentProps.onAnimationComplete),
    className: cx("chakra-popover__content", props.className),
    __css: contentStyles
  }));
});
PopoverContent.displayName = "PopoverContent";

// src/popover-footer.tsx
import { chakra as chakra5 } from "@chakra-ui/system";
function PopoverFooter(props) {
  const styles = usePopoverStyles();
  return /* @__PURE__ */ React.createElement(chakra5.footer, {
    ...props,
    className: cx("chakra-popover__footer", props.className),
    __css: styles.footer
  });
}
PopoverFooter.displayName = "PopoverFooter";

// src/popover-header.tsx
import { chakra as chakra6, forwardRef as forwardRef5 } from "@chakra-ui/system";
var PopoverHeader = forwardRef5(function PopoverHeader2(props, ref) {
  const { getHeaderProps } = usePopoverContext();
  const styles = usePopoverStyles();
  return /* @__PURE__ */ React.createElement(chakra6.header, {
    ...getHeaderProps(props, ref),
    className: cx("chakra-popover__header", props.className),
    __css: styles.header
  });
});
PopoverHeader.displayName = "PopoverHeader";

// src/popover-trigger.tsx
import { Children as Children2, cloneElement as cloneElement2 } from "react";
function PopoverTrigger(props) {
  const child = Children2.only(props.children);
  const { getTriggerProps } = usePopoverContext();
  return cloneElement2(child, getTriggerProps(child.props, child.ref));
}
PopoverTrigger.displayName = "PopoverTrigger";
export {
  Popover,
  PopoverAnchor,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  usePopover,
  usePopoverContext,
  usePopoverStyles
};
