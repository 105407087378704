import { TagCloseButton, TagProps } from '@chakra-ui/react';
import { useState } from 'react';
import { Role } from '../util/api';
import RoleTag from './role-tag';

const RemovableRole = ({
  role,
  onRemove
}: { role: Role; onRemove: () => unknown } & Omit<TagProps, 'role' | 'children'>) => {
  const [loading, setLoading] = useState(false);

  return (
    <RoleTag role={role}>
      <TagCloseButton
        isDisabled={loading}
        onClick={async () => {
          setLoading(true);
          await onRemove();
          setLoading(false);
        }}
      />
    </RoleTag>
  );
};

export default RemovableRole;
