import { createRoot } from 'react-dom/client';
import App from './app';
import './index.css';

if (process.env.NODE_ENV == 'production' && navigator.serviceWorker) {
  navigator.serviceWorker
    .register(new URL('sw.ts', import.meta.url), { type: 'module' })
    .then(reg => {
      if (reg.waiting) {
        location.reload();
        return;
      }
      reg.addEventListener('updatefound', () => {
        const { installing } = reg;
        installing.addEventListener('statechange', () => {
          if (installing.state == 'installed') {
            if (navigator.serviceWorker.controller) {
              location.reload();
            }
          }
        });
      });
    });
}

const root = createRoot(document.getElementById('root'));

root.render(<App />);
