import {
  NavLink as RouterLink,
  unstable_HistoryRouter,
  HistoryRouterProps
} from 'react-router-dom';
import { Routes, Route, useLocation, Navigate } from 'react-router';
import {
  Avatar,
  Button,
  ChakraProvider,
  Flex,
  Heading,
  Hide,
  HStack,
  IconButton,
  Link,
  LinkProps,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Show,
  Text,
  useColorMode,
  VStack
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { APIError, issueInfo, issue, myInfo, PermissionLevel } from '../util/api';
import { useGlobalState } from '../util/state';
import theme, { darkMatch } from '../util/theme';
import Me from '../pages/me';
import Users from '../pages/users';
import { Logo } from '../components/icon';
import Loading from '../components/loading';
import DefaultIssue, { AllIssues, Issue, NewIssue } from '../pages/issue';
import * as React from 'react';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import {
  DoubletruckPage,
  DoubletruckUpload,
  IssuePage,
  IssuePageUpload
} from '../pages/issue/page';
import IssueDeadlines from '../pages/issue/deadlines';
import { createBrowserHistory } from 'history';
import Home from '../pages/home';

const HistoryRouter = unstable_HistoryRouter;

const HeaderLink = ({ to, children, ...props }: { to: string } & LinkProps) => {
  return (
    <Link
      as={RouterLink}
      to={to}
      color="white"
      _hover={{
        textDecoration: 'none',
        opacity: '0.87'
      }}
      _activeLink={{ fontWeight: 'semibold' }}
      {...props}
    >
      <Text fontSize="2xl">{children}</Text>
    </Link>
  );
};

const NavMenu = ({ children }: { children?: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <>
      <Show above="md">
        <Flex flexGrow={1} justify="space-evenly">
          {children}
        </Flex>
      </Show>
      <Hide above="md">
        <IconButton
          aria-label="Open navigation menu"
          icon={open ? <CloseIcon /> : <HamburgerIcon boxSize={6} />}
          variant="unstyled"
          color="white"
          onClick={() => setOpen(!open)}
        />
        <VStack
          zIndex="10"
          transition="transform 200ms ease"
          transform={open ? '' : 'scaleY(0)'}
          transformOrigin="top"
          pos="absolute"
          top="calc(80px + var(--root-top-pad))"
          left="0"
          w="100%"
          overflow="hidden"
          bg="primary.700"
          pt="-8px"
          pb={3}
        >
          {children}
        </VStack>
      </Hide>
    </>
  );
};

const App = () => {
  const [userInfo, setUserInfo] = useGlobalState('userInfo');
  const [curIssueID, setCurIssueID] = useGlobalState('currentIssue');
  const [issues, setIssues] = useGlobalState('issues');
  const [offline, setOffline] = useState(false);
  const [forceColorMode] = useGlobalState('forceColorMode');
  const { setColorMode } = useColorMode();

  useEffect(() => {
    myInfo().then(setUserInfo, err => {
      if (err instanceof APIError) {
        location.replace('/api/auth/user?target=' + encodeURIComponent(location.pathname));
      } else {
        setOffline(true);
      }
    });
  }, []);

  useEffect(() => {
    if (forceColorMode != null) {
      localStorage.setItem('force-color', forceColorMode);
      setColorMode(forceColorMode);
    } else {
      localStorage.removeItem('force-color');
      const onChange = () => {
        setColorMode(darkMatch.matches ? 'dark' : 'light');
      };
      onChange();
      darkMatch.addEventListener('change', onChange);
      return () => darkMatch.removeEventListener('change', onChange);
    }
  }, [forceColorMode]);

  useEffect(() => {
    if (userInfo && userInfo.permission >= PermissionLevel.Reporter) {
      issueInfo().then(async info => {
        const newIssues = { ...issues };
        for (const k of info.issues) {
          newIssues[k] ||= null;
        }
        setIssues(newIssues);
        setCurIssueID(info.currentIssue || '');
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (curIssueID) {
      issue(curIssueID).then(value =>
        setIssues({
          ...issues,
          [curIssueID]: value
        })
      );
    }
  }, [curIssueID]);

  const loading = (
    <Flex w="var(--dvw)" h="var(--dvh)" justify="center" align="center">
      <Loading />
      <Modal isOpen={offline} onClose={() => {}} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>You're offline</ModalHeader>
          <ModalBody>
            It looks like you might be offline. Try reloading the page once you're connected.
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                location.reload();
              }}
            >
              Reload
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );

  return userInfo ? (
    userInfo.permission >= PermissionLevel.Reporter ? (
      curIssueID == null || (curIssueID && !issues[curIssueID]) ? (
        loading
      ) : (
        <>
          <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            w="calc(100% + calc(var(--root-left-pad) + var(--root-right-pad)))"
            bg="primary.700"
            pb={2}
            pt="calc(8px + var(--root-top-pad))"
            pl="calc(var(--root-left-pad) + 8px)"
            pr="calc(var(--root-right-pad) + 8px)"
            ml="calc(-1 * var(--root-left-pad))"
          >
            <Link as={RouterLink} to="/">
              <Logo color="white" boxSize={16} />
            </Link>
            <NavMenu>
              {curIssueID && (
                <HeaderLink to={`/issue/${curIssueID}`}>{issues[curIssueID].name}</HeaderLink>
              )}
              <HeaderLink to="/issues">Issues</HeaderLink>
              <HeaderLink to="/users">Users</HeaderLink>
            </NavMenu>
            <Avatar
              name={userInfo?.name}
              src={userInfo?.avatarURL}
              showBorder
              borderColor="transparent"
              borderWidth="6px"
              bg="transparent"
              boxSize={16}
              _hover={{
                background: 'rgba(40, 40, 40, 0.3)'
              }}
              as={RouterLink}
              to="/me"
            ></Avatar>
          </Flex>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/me" element={<Me />} />
            <Route path="/users">
              <Route index element={<Users />} />
              {userInfo.permission >= PermissionLevel.Strategic && (
                <Route path="pending" element={<Users section="pending" />} />
              )}
              {userInfo.permission >= PermissionLevel.Strategic && (
                <Route path="deleted" element={<Users section="deleted" />} />
              )}
            </Route>
            <Route path="/issues">
              <Route index element={<AllIssues />} />
              {userInfo.permission >= PermissionLevel.Strategic && (
                <Route path="new" element={<NewIssue />} />
              )}
            </Route>
            <Route path="/issue">
              <Route index element={<DefaultIssue />} />
              <Route path=":issueID">
                <Route index element={<Issue />}></Route>
                <Route path="page">
                  <Route path="doubletruck">
                    <Route index element={<DoubletruckPage />} />
                    <Route path="upload" element={<DoubletruckUpload />} />
                  </Route>
                  <Route path=":pageID">
                    <Route index element={<IssuePage />} />
                    <Route path="upload" element={<IssuePageUpload />} />
                  </Route>
                </Route>
                <Route path="deadlines">
                  <Route index element={<IssueDeadlines />} />
                  <Route path=":deadlineID" element={<IssueDeadlines />} />
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </>
      )
    ) : (
      <>
        <Modal isOpen onClose={() => {}} isCentered>
          <ModalOverlay />
          <ModalContent>
            {userInfo.permission == PermissionLevel.Blacklist ? (
              <>
                <ModalHeader>Your account has been deleted</ModalHeader>
                <ModalBody>
                  Your account ({userInfo.email}) has been removed from Winged Post Organizer.
                  Contact a strategic member or your advisor if you think this is a mistake.
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      location.assign('/api/auth/user?select');
                    }}
                  >
                    Switch account
                  </Button>
                </ModalFooter>
              </>
            ) : (
              <>
                <ModalHeader>Your account is unverified</ModalHeader>
                <ModalBody>
                  You haven't yet been given a position within Winged Post. Ask a strategic member
                  or your advisor to give you access.
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      location.assign('/api/auth/user?select');
                    }}
                  >
                    Switch account
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </>
    )
  ) : (
    loading
  );
};

const history = createBrowserHistory({ window });

const AppWrapper = () => {
  return (
    <ChakraProvider
      theme={theme}
      colorModeManager={{
        type: 'localStorage',
        get() {
          return undefined;
        },
        set() {}
      }}
    >
      <HistoryRouter history={history as unknown as HistoryRouterProps['history']}>
        <App />
      </HistoryRouter>
    </ChakraProvider>
  );
};

export default AppWrapper;
