import { useCallback, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Heading,
  HStack,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Spinner,
  Tag,
  Text,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { GooglePicker, PickerBuilder, useDrivePicker } from './picker';
import { api, PermissionLevel, roles } from '../../util/api';
import { devKey } from '../../util/google';
import { useGlobalState } from '../../util/state';
import PermissionBadge from '../../components/permission-badge';
import RoleTag from '../../components/role-tag';
import AddRoleMenu from '../../components/add-role-menu';
import RemovableRole from '../../components/removable-role';

type RootInfo = {
  url: string;
  id: string;
  name: string;
};

type Token = {
  token: string;
  scopes: string[];
};

const SuperuserSettings = () => {
  const [token, setToken] = useState<Token | null | false>(false);
  const [startPicker, setStartPicker] = useState(false);
  const [rootInfo, setRootInfo] = useState<RootInfo | null>(null);

  const builder = useCallback<PickerBuilder>(
    picker => {
      const myDrive = new picker.DocsView()
        .setParent('root')
        .setMimeTypes('application/vnd.google-apps.folder')
        .setSelectFolderEnabled(true);

      const sharedWithMe = new picker.DocsView()
        .setMimeTypes('application/vnd.google-apps.folder')
        .setSelectFolderEnabled(true)
        .setOwnedByMe(false);

      const builder = new picker.PickerBuilder()
        .setDeveloperKey(devKey)
        .setOAuthToken(token && token.token)
        .addView(myDrive)
        .addView(sharedWithMe);

      return builder.build();
    },
    [token]
  );

  useEffect(() => {
    if (startPicker) {
      api<Token>('/auth/superuser/access-token').then(token => {
        setToken(token);
        setVisible(true);
      });
    }
  }, [startPicker]);

  useEffect(() => {
    api<Token>('/auth/superuser/access-token').then(setToken);
  }, []);

  const pickerCallback = useCallback<GooglePicker.PickerCallback>(async info => {
    if (info.action == 'picked') {
      const [folder] = info.docs;
      const newInfo = {
        url: folder.url,
        id: folder.id,
        name: folder.name
      };
      try {
        await api('/assets/gdrive-root', { method: 'POST', body: newInfo });
        setRootInfo(newInfo);
      } catch (err) {
        // TODO: toast?
      }
    }
  }, []);

  useEffect(() => {
    api<RootInfo>('/assets/gdrive-root').then(setRootInfo);
  }, []);

  const [visible, setVisible] = useDrivePicker(builder, pickerCallback);

  useEffect(() => {
    if (!visible) setStartPicker(false);
  }, [visible]);

  return (
    <>
      {token ? (
        <>
          <Heading size="sm">Winged Post folder</Heading>
          <HStack mt={1}>
            {rootInfo && (
              <Link href={rootInfo.url} isExternal display="flex" alignItems="center">
                {rootInfo.name} <ExternalLinkIcon ms="4px" />
              </Link>
            )}
            <Button
              isDisabled={startPicker}
              onClick={() => {
                setStartPicker(true);
              }}
              size="xs"
            >
              {rootInfo ? 'Change' : 'Select'}
            </Button>
          </HStack>
        </>
      ) : token == null ? (
        <Button as="a" href="/api/auth/superuser?target=%2Fme" size={['sm', 'sm', 'md']}>
          Setup Google Drive (required)
        </Button>
      ) : (
        <Flex justify="center" align="center">
          <Spinner />
        </Flex>
      )}
      {token && !token.scopes.includes('https://www.googleapis.com/auth/gmail.send') && (
        <>
          <Heading size="sm" mt={4}>
            Email reminders/invites
          </Heading>
          {process.env.WPO_GOOGLE_VERIFICATION == 'verified' ? (
            <Button mt={1} as="a" href="/api/auth/superuser?mail&target=%2Fme" size="xs">
              Enable email features
            </Button>
          ) : (
            <Popover>
              <PopoverTrigger>
                <Button mt={1} size="xs">
                  Enable email features
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverArrow />
                <PopoverBody>
                  <Text fontWeight="bold">
                    You will likely see a warning that this app is unverified after logging in.
                  </Text>
                  Please click "Advanced" and proceed anyway to enable email invites and automatic
                  deadline reminders.
                </PopoverBody>
                <PopoverFooter>
                  <Button as="a" href="/api/auth/superuser?mail&target=%2Fme" size="sm">
                    Continue
                  </Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          )}
        </>
      )}
    </>
  );
};

const Me = () => {
  const [info, setInfo] = useGlobalState('userInfo');
  const [allUsers, setAllUsers] = useGlobalState('users');
  const [allRoles, setAllRoles] = useGlobalState('roles');
  const [forceColorMode, setForceColorMode] = useGlobalState('forceColorMode');

  useEffect(() => {
    const ctrl = new AbortController();
    if (!allRoles) {
      roles(ctrl.signal).then(setAllRoles);
    }
    return () => ctrl.abort();
  }, []);

  return (
    <Flex alignItems="center" justifyContent="center" minH="calc(var(--dvh) - 80px)">
      <Card w="lg" maxW="calc(0.9 * var(--dvw))" my="calc(0.05 * var(--dvh))">
        <CardHeader display="flex" flexDirection="row" alignItems="center">
          <Avatar name={info.name} src={info.avatarURL} boxSize={['64px', '64px', '80px']} />
          <Box ps="1rem">
            <Heading size="xl" pb="2px">
              {info.name}
            </Heading>
            <Wrap align="end" spacing="4px">
              {allRoles && (
                <>
                  {info.roles.map(id => (
                    <WrapItem key={id}>
                      {info.permission >= PermissionLevel.Strategic ? (
                        <RemovableRole
                          role={allRoles[id]}
                          onRemove={async () => {
                            const roles = info.roles.filter(v => v != id);
                            try {
                              await api('/users', {
                                method: 'PATCH',
                                body: { id: info.id, roles }
                              });
                              if (allUsers) {
                                const users = { ...allUsers };
                                users[info.id].roles = roles;
                                setAllUsers(users);
                              }
                              setInfo({ ...info, roles });
                            } catch (err) {
                              // todo: toast?
                            }
                          }}
                        />
                      ) : (
                        <RoleTag role={allRoles[id]} />
                      )}
                    </WrapItem>
                  ))}
                  {info.permission >= PermissionLevel.Strategic && (
                    <WrapItem>
                      <AddRoleMenu
                        roles={Object.values(allRoles).filter(v => !info.roles.includes(v.id))}
                        canEdit
                        onSelect={async role => {
                          const roles = info.roles.concat(role.id);
                          try {
                            await api('/users', {
                              method: 'PATCH',
                              body: { id: info.id, roles }
                            });
                            if (allUsers) {
                              const users = { ...allUsers };
                              users[info.id].roles = roles;
                              setAllUsers(users);
                            }
                            setInfo({ ...info, roles });
                          } catch (err) {
                            // todo: toast?
                          }
                        }}
                      />
                    </WrapItem>
                  )}
                </>
              )}
            </Wrap>
            <PermissionBadge superuser={info.superuser} permission={info.permission} />
            <Text fontSize="sm">{info.email}</Text>
          </Box>
        </CardHeader>
        <Divider />
        <CardBody>
          <Heading size="sm">Account options</Heading>
          <Button
            mt={1}
            onClick={() => {
              location.assign('/api/auth/user?select');
            }}
            size="xs"
          >
            Switch account
          </Button>
          <Heading size="sm" mt={5}>
            Theme
          </Heading>
          <RadioGroup
            value={forceColorMode == null ? 'auto' : forceColorMode}
            onChange={mode => {
              setForceColorMode((mode == 'auto' ? null : mode) as 'dark' | 'light');
            }}
          >
            <HStack>
              <Radio value="light">Light mode</Radio>
              <Radio value="dark">Dark mode</Radio>
              <Radio value="auto">Auto</Radio>
            </HStack>
          </RadioGroup>
        </CardBody>
        {info.superuser && (
          <>
            <Divider />
            <CardBody>
              <SuperuserSettings />
            </CardBody>
          </>
        )}
      </Card>
    </Flex>
  );
};

export default Me;
