import { Tag, TagCloseButton, TagLabel, TagProps } from '@chakra-ui/react';
import { useState } from 'react';
import { Role } from '../util/api';
import { colorHash } from '../util/theme';

const RoleTag = ({ role, children, ...props }: { role: Role } & Omit<TagProps, 'role'>) => {
  return (
    <Tag colorScheme={colorHash(role.id)} {...props}>
      <TagLabel>{role.name}</TagLabel>
      {children}
    </Tag>
  );
};

export default RoleTag;
