// ../../../react-shim.js
import React from "react";

// src/accordion-button.tsx
import {
  chakra,
  forwardRef
} from "@chakra-ui/system";

// ../../utilities/shared-utils/src/index.ts
var cx = (...classNames) => classNames.filter(Boolean).join(" ");
function isDev() {
  return process.env.NODE_ENV !== "production";
}
var warn = (options) => {
  const { condition, message } = options;
  if (condition && isDev()) {
    console.warn(message);
  }
};
function callAllHandlers(...fns) {
  return function func(event) {
    fns.some((fn) => {
      fn == null ? void 0 : fn(event);
      return event == null ? void 0 : event.defaultPrevented;
    });
  };
}

// src/accordion-context.ts
import { createDescendantContext } from "@chakra-ui/descendant";
import { createContext } from "@chakra-ui/react-context";
var [AccordionStylesProvider, useAccordionStyles] = createContext({
  name: "AccordionStylesContext",
  hookName: "useAccordionStyles",
  providerName: "<Accordion />"
});
var [AccordionItemProvider, useAccordionItemContext] = createContext({
  name: "AccordionItemContext",
  hookName: "useAccordionItemContext",
  providerName: "<AccordionItem />"
});
var [
  AccordionDescendantsProvider,
  useAccordionDescendantsContext,
  useAccordionDescendants,
  useAccordionDescendant
] = createDescendantContext();

// src/accordion-button.tsx
var AccordionButton = forwardRef(function AccordionButton2(props, ref) {
  const { getButtonProps } = useAccordionItemContext();
  const buttonProps = getButtonProps(props, ref);
  const styles = useAccordionStyles();
  const buttonStyles = {
    display: "flex",
    alignItems: "center",
    width: "100%",
    outline: 0,
    ...styles.button
  };
  return /* @__PURE__ */ React.createElement(chakra.button, {
    ...buttonProps,
    className: cx("chakra-accordion__button", props.className),
    __css: buttonStyles
  });
});
AccordionButton.displayName = "AccordionButton";

// src/accordion-icon.tsx
import { Icon } from "@chakra-ui/icon";

// src/use-accordion.ts
import { createContext as createContext2 } from "@chakra-ui/react-context";
import { useControllableState } from "@chakra-ui/react-use-controllable-state";
import { mergeRefs } from "@chakra-ui/react-use-merge-refs";
import { useCallback, useEffect, useId, useRef, useState } from "react";
function useAccordion(props) {
  const {
    onChange,
    defaultIndex,
    index: indexProp,
    allowMultiple,
    allowToggle,
    ...htmlProps
  } = props;
  allowMultipleWarning(props);
  allowMultipleAndAllowToggleWarning(props);
  const descendants = useAccordionDescendants();
  const [focusedIndex, setFocusedIndex] = useState(-1);
  useEffect(() => {
    return () => {
      setFocusedIndex(-1);
    };
  }, []);
  const [index, setIndex] = useControllableState({
    value: indexProp,
    defaultValue() {
      if (allowMultiple)
        return defaultIndex ?? [];
      return defaultIndex ?? -1;
    },
    onChange
  });
  const getAccordionItemProps = (idx) => {
    let isOpen = false;
    if (idx !== null) {
      isOpen = Array.isArray(index) ? index.includes(idx) : index === idx;
    }
    const onChange2 = (isOpen2) => {
      if (idx === null)
        return;
      if (allowMultiple && Array.isArray(index)) {
        const nextState = isOpen2 ? index.concat(idx) : index.filter((i) => i !== idx);
        setIndex(nextState);
      } else if (isOpen2) {
        setIndex(idx);
      } else if (allowToggle) {
        setIndex(-1);
      }
    };
    return { isOpen, onChange: onChange2 };
  };
  return {
    index,
    setIndex,
    htmlProps,
    getAccordionItemProps,
    focusedIndex,
    setFocusedIndex,
    descendants
  };
}
var [AccordionProvider, useAccordionContext] = createContext2({
  name: "AccordionContext",
  hookName: "useAccordionContext",
  providerName: "Accordion"
});
function useAccordionItem(props) {
  const { isDisabled, isFocusable, id, ...htmlProps } = props;
  const { getAccordionItemProps, setFocusedIndex } = useAccordionContext();
  const buttonRef = useRef(null);
  const reactId = useId();
  const uid = id ?? reactId;
  const buttonId = `accordion-button-${uid}`;
  const panelId = `accordion-panel-${uid}`;
  focusableNotDisabledWarning(props);
  const { register, index, descendants } = useAccordionDescendant({
    disabled: isDisabled && !isFocusable
  });
  const { isOpen, onChange } = getAccordionItemProps(index === -1 ? null : index);
  warnIfOpenAndDisabled({ isOpen, isDisabled });
  const onOpen = () => {
    onChange == null ? void 0 : onChange(true);
  };
  const onClose = () => {
    onChange == null ? void 0 : onChange(false);
  };
  const onClick = useCallback(() => {
    onChange == null ? void 0 : onChange(!isOpen);
    setFocusedIndex(index);
  }, [index, setFocusedIndex, isOpen, onChange]);
  const onKeyDown = useCallback((event) => {
    const keyMap = {
      ArrowDown: () => {
        const next = descendants.nextEnabled(index);
        next == null ? void 0 : next.node.focus();
      },
      ArrowUp: () => {
        const prev = descendants.prevEnabled(index);
        prev == null ? void 0 : prev.node.focus();
      },
      Home: () => {
        const first = descendants.firstEnabled();
        first == null ? void 0 : first.node.focus();
      },
      End: () => {
        const last = descendants.lastEnabled();
        last == null ? void 0 : last.node.focus();
      }
    };
    const action = keyMap[event.key];
    if (action) {
      event.preventDefault();
      action(event);
    }
  }, [descendants, index]);
  const onFocus = useCallback(() => {
    setFocusedIndex(index);
  }, [setFocusedIndex, index]);
  const getButtonProps = useCallback(function getButtonProps2(props2 = {}, ref = null) {
    return {
      ...props2,
      type: "button",
      ref: mergeRefs(register, buttonRef, ref),
      id: buttonId,
      disabled: !!isDisabled,
      "aria-expanded": !!isOpen,
      "aria-controls": panelId,
      onClick: callAllHandlers(props2.onClick, onClick),
      onFocus: callAllHandlers(props2.onFocus, onFocus),
      onKeyDown: callAllHandlers(props2.onKeyDown, onKeyDown)
    };
  }, [
    buttonId,
    isDisabled,
    isOpen,
    onClick,
    onFocus,
    onKeyDown,
    panelId,
    register
  ]);
  const getPanelProps = useCallback(function getPanelProps2(props2 = {}, ref = null) {
    return {
      ...props2,
      ref,
      role: "region",
      id: panelId,
      "aria-labelledby": buttonId,
      hidden: !isOpen
    };
  }, [buttonId, isOpen, panelId]);
  return {
    isOpen,
    isDisabled,
    isFocusable,
    onOpen,
    onClose,
    getButtonProps,
    getPanelProps,
    htmlProps
  };
}
function allowMultipleWarning(props) {
  const index = props.index || props.defaultIndex;
  const condition = index != null && !Array.isArray(index) && props.allowMultiple;
  warn({
    condition: !!condition,
    message: `If 'allowMultiple' is passed, then 'index' or 'defaultIndex' must be an array. You passed: ${typeof index},`
  });
}
function allowMultipleAndAllowToggleWarning(props) {
  warn({
    condition: !!(props.allowMultiple && props.allowToggle),
    message: `If 'allowMultiple' is passed, 'allowToggle' will be ignored. Either remove 'allowToggle' or 'allowMultiple' depending on whether you want multiple accordions visible or not`
  });
}
function focusableNotDisabledWarning(props) {
  warn({
    condition: !!(props.isFocusable && !props.isDisabled),
    message: `Using only 'isFocusable', this prop is reserved for situations where you pass 'isDisabled' but you still want the element to receive focus (A11y). Either remove it or pass 'isDisabled' as well.
    `
  });
}
function warnIfOpenAndDisabled(props) {
  warn({
    condition: props.isOpen && !!props.isDisabled,
    message: "Cannot open a disabled accordion item"
  });
}

// src/accordion-icon.tsx
function AccordionIcon(props) {
  const { isOpen, isDisabled } = useAccordionItemContext();
  const { reduceMotion } = useAccordionContext();
  const _className = cx("chakra-accordion__icon", props.className);
  const styles = useAccordionStyles();
  const iconStyles = {
    opacity: isDisabled ? 0.4 : 1,
    transform: isOpen ? "rotate(-180deg)" : void 0,
    transition: reduceMotion ? void 0 : "transform 0.2s",
    transformOrigin: "center",
    ...styles.icon
  };
  return /* @__PURE__ */ React.createElement(Icon, {
    viewBox: "0 0 24 24",
    "aria-hidden": true,
    className: _className,
    __css: iconStyles,
    ...props
  }, /* @__PURE__ */ React.createElement("path", {
    fill: "currentColor",
    d: "M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
  }));
}
AccordionIcon.displayName = "AccordionIcon";

// src/accordion-item.tsx
import {
  chakra as chakra2,
  forwardRef as forwardRef2
} from "@chakra-ui/system";
import { useMemo } from "react";
var AccordionItem = forwardRef2(function AccordionItem2(props, ref) {
  const { children, className } = props;
  const { htmlProps, ...context } = useAccordionItem(props);
  const styles = useAccordionStyles();
  const containerStyles = {
    ...styles.container,
    overflowAnchor: "none"
  };
  const ctx = useMemo(() => context, [context]);
  return /* @__PURE__ */ React.createElement(AccordionItemProvider, {
    value: ctx
  }, /* @__PURE__ */ React.createElement(chakra2.div, {
    ref,
    ...htmlProps,
    className: cx("chakra-accordion__item", className),
    __css: containerStyles
  }, typeof children === "function" ? children({
    isExpanded: !!context.isOpen,
    isDisabled: !!context.isDisabled
  }) : children));
});
AccordionItem.displayName = "AccordionItem";

// src/accordion-panel.tsx
import { chakra as chakra3, forwardRef as forwardRef3 } from "@chakra-ui/system";
import { Collapse } from "@chakra-ui/transition";
var AccordionPanel = forwardRef3(function AccordionPanel2(props, ref) {
  const { className, motionProps, ...rest } = props;
  const { reduceMotion } = useAccordionContext();
  const { getPanelProps, isOpen } = useAccordionItemContext();
  const panelProps = getPanelProps(rest, ref);
  const _className = cx("chakra-accordion__panel", className);
  const styles = useAccordionStyles();
  if (!reduceMotion) {
    delete panelProps.hidden;
  }
  const child = /* @__PURE__ */ React.createElement(chakra3.div, {
    ...panelProps,
    __css: styles.panel,
    className: _className
  });
  if (!reduceMotion) {
    return /* @__PURE__ */ React.createElement(Collapse, {
      in: isOpen,
      ...motionProps
    }, child);
  }
  return child;
});
AccordionPanel.displayName = "AccordionPanel";

// src/accordion.tsx
import {
  chakra as chakra4,
  forwardRef as forwardRef4,
  omitThemingProps,
  useMultiStyleConfig
} from "@chakra-ui/system";
import { useMemo as useMemo2 } from "react";
var Accordion = forwardRef4(function Accordion2({ children, reduceMotion, ...props }, ref) {
  const styles = useMultiStyleConfig("Accordion", props);
  const ownProps = omitThemingProps(props);
  const { htmlProps, descendants, ...context } = useAccordion(ownProps);
  const ctx = useMemo2(() => ({ ...context, reduceMotion: !!reduceMotion }), [context, reduceMotion]);
  return /* @__PURE__ */ React.createElement(AccordionDescendantsProvider, {
    value: descendants
  }, /* @__PURE__ */ React.createElement(AccordionProvider, {
    value: ctx
  }, /* @__PURE__ */ React.createElement(AccordionStylesProvider, {
    value: styles
  }, /* @__PURE__ */ React.createElement(chakra4.div, {
    ref,
    ...htmlProps,
    className: cx("chakra-accordion", props.className),
    __css: styles.root
  }, children))));
});
Accordion.displayName = "Accordion";

// src/use-accordion-item-state.ts
function useAccordionItemState() {
  const { isOpen, isDisabled, onClose, onOpen } = useAccordionItemContext();
  return { isOpen, onClose, isDisabled, onOpen };
}
export {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionProvider,
  useAccordion,
  useAccordionContext,
  useAccordionItem,
  useAccordionItemState,
  useAccordionStyles
};
