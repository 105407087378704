export type APIOptions = {
  method?: 'GET' | 'PUT' | 'POST' | 'PATCH' | 'DELETE';
  body?: unknown;
  query?: Record<string, string>;
  signal?: AbortSignal;
};

export class APIError extends Error {
  constructor(public status: number, message: string) {
    super(message);
  }
}

export async function api<T>(path: string, opts: APIOptions = {}) {
  const queryParams = opts.query ? new URLSearchParams(opts.query).toString() : '';
  const finalPath = '/api' + path + queryParams;
  let body: BodyInit;
  let headers: HeadersInit = {};
  if (opts.body instanceof ReadableStream || opts.body instanceof FormData) {
    body = opts.body;
  } else {
    body = JSON.stringify(opts.body);
    headers['Content-Type'] = 'application/json';
  }
  try {
    const res = await fetch(finalPath, {
      headers,
      method: opts.method,
      body,
      signal: opts.signal,
      redirect: 'follow'
    });
    if (res.status >= 200 && res.status < 300) {
      return (await res.json()) as T;
    }
    throw new APIError(res.status, `Request to ${finalPath} failed with code ${res.status}`);
  } catch (err) {
    if (!opts.signal?.aborted) {
      throw err;
    }
    return null;
  }
}

// TODO: remove
window['api'] = api;

export const enum PermissionLevel {
  Blacklist,
  None,
  Reporter,
  Editor,
  Strategic,
  Advisor
}

export const permissionLevelNames: Record<PermissionLevel, string> & string[] = [
  'Banned',
  'Unverified',
  'Reporter',
  'Editor',
  'Strategic',
  'Advisor'
];

export const permissionLevelColors: Record<PermissionLevel, string> & string[] = [
  'gray',
  'gray',
  'primary',
  'blue',
  'yellow',
  'red'
];

export type UserInfo = {
  id: string;
  name: string;
  email: string;
  avatarURL: string;
  roles: string[];
  permission: PermissionLevel;
};

export type MyUserInfo = UserInfo & {
  superuser: boolean;
};

export async function myInfo() {
  return api<MyUserInfo>('/auth/me');
}

export type Role = {
  id: string;
  name: string;
};

export type Roles = Record<string, Role>;

export async function roles(signal?: AbortSignal) {
  return api<Roles>('/users/roles', { signal });
}

export type Users = Record<string, UserInfo>;

export async function users(signal?: AbortSignal) {
  return api<Users>('/users', { signal });
}

export type Invite = {
  email: string;
  permission: PermissionLevel;
  roles: string[];
};

export type Invites = Record<string, Invite>;

export async function invites(signal?: AbortSignal) {
  return api<Invites>('/users/invites', { signal });
}

export async function issueInfo() {
  return api<{ issues: string[]; currentIssue: string | null }>('/assets/issue');
}

export type BasicPageInfo = {
  id: string;
  page: number;
  name: string;
  slug: string;
  indd?: string;
  modified: number;
  pdf?: string;
  canEdit: boolean;
};

export type Deadline = {
  id: string;
  name: string;
  time: number;
};

export type IssueInfo = {
  id: string;
  name: string;
  prefix: string;
  suffix: string;
  folio: number;
  startTime: number;
  doubletruck: boolean;
  deadlines: Record<string, Deadline>;
  numPages: number;
  pages: Record<string, BasicPageInfo>;
};

export type ChecklistQuestion = {
  question: string;
  deadline?: string;
};

export type PageRevision = {
  id: string;
  url: string;
  timestamp: number;
  author: string;
  comment: string;
};

export type PageInfo = {
  id: string;
  inddID?: string;
  inddURL?: string;
  inddRev?: number;
  name: string;
  slug: string;
  page: number;
  roles: string[];
  checklist: ChecklistQuestion[];
  answers: boolean[];
  revisions: PageRevision[];
};

export async function issue(id: string, signal?: AbortSignal) {
  return api<IssueInfo>('/assets/issue/' + id, { signal });
}

export async function page(issue: string, id: string, signal?: AbortSignal) {
  return api<PageInfo>('/assets/issue/' + issue + '/page/' + id, { signal });
}
