import {
  AddIcon,
  ArrowForwardIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  CopyIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
  WarningTwoIcon
} from '@chakra-ui/icons';
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardProps,
  Checkbox,
  DarkMode,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Hide,
  HStack,
  IconButton,
  Input,
  Link,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Select,
  Show,
  Skeleton,
  SkeletonText,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useDisclosure,
  useMediaQuery,
  VStack,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import { useEffect, useRef, useState, useImperativeHandle, Ref, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import AddRoleMenu from '../../components/add-role-menu';
import Loading from '../../components/loading';
import DrivePage from '../../components/drive-page';
import RemovableRole from '../../components/removable-role';
import RoleTag from '../../components/role-tag';
import {
  api,
  issue,
  issueInfo,
  IssueInfo,
  page as getPage,
  BasicPageInfo,
  PermissionLevel,
  roles,
  APIError,
  Deadline,
  page
} from '../../util/api';
import { useGlobalState } from '../../util/state';
import { PDFDocument } from 'pdf-lib';
import { getDoc } from '../../util/google';
import { useBlocker } from '../../util/use-blocker';
import { FullscreenIcon } from '../../components/icon';

const DeleteIssueModal = ({
  id,
  isOpen,
  onClose
}: {
  id: string;
  isOpen: boolean;
  onClose: () => unknown;
}) => {
  const [allIssues, setAllIssues] = useGlobalState('issues');
  const [currentIssue, setCurrentIssue] = useGlobalState('currentIssue');
  const [conf, setConf] = useState('');
  const [loading, setLoading] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered returnFocusOnClose={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Confirm deletion</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text as="span">
            Are you sure you want to delete {allIssues[id].name}? Type CONFIRM below if you're sure.
          </Text>
          <Text as="span" fontWeight="bold">
            {' '}
            This will delete all pages and deadlines in this issue and cannot be undone.
          </Text>
          <Input mt={2} value={conf} onChange={e => setConf(e.currentTarget.value)} />
        </ModalBody>
        <ModalFooter>
          <Button
            isLoading={loading}
            colorScheme="red"
            variant="outline"
            isDisabled={conf != 'CONFIRM' || loading}
            onClick={async () => {
              setLoading(true);
              try {
                await api('/assets/issue/' + id, {
                  method: 'DELETE'
                });
                const newIssues = { ...allIssues };
                delete newIssues[id];
                setAllIssues(newIssues);
                if (id == currentIssue) setCurrentIssue('');
                onClose();
              } catch (err) {
                // todo: toast?
              }
              setLoading(false);
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const pageW =
  'min(calc(calc(0.48 * var(--dvw)) - 64px), calc(calc(0.6471 * var(--dvh)) - 156.59px))';
const pageH = 'min(calc(calc(0.7418 * var(--dvw)) - 99px), calc(var(--dvh) - 242px))';
const sliderW = `calc(calc(2 * ${pageW}) + 8px)`;
const singlePageW = `min(${sliderW}, calc(calc(0.6471 * var(--dvh)) - 156.59px))`;
const singlePageH = 'min(calc(calc(1.4836 * var(--dvw)) - 185.45px), calc(var(--dvh) - 242px))';
const fullscreenPageW = `min(calc(calc(0.5 * var(--dvw)) - 56px), calc(calc(0.6471 * var(--dvh)) - 10.35px))`;
const fullscreenPageH = `min(calc(var(--dvh) - 16px), calc(calc(0.7727 * var(--dvw)) - 86.55px))`;

const NewIssuePage = ({
  onCreate,
  page,
  others,
  deadlines,
  w,
  h
}: {
  onCreate: (info: CreatePageInfo<string>) => boolean | Promise<boolean>;
  page: number;
  others: BasicPageInfo[];
  deadlines: Record<string, Deadline>;
} & Pick<CardProps, 'w' | 'h'>) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [allRoles] = useGlobalState('roles');
  const [allPages] = useGlobalState('pages');
  const [roles, setRoles] = useState<string[]>([]);
  const [checklist, setChecklist] = useState<CreateChecklistQuestion<string>[]>([]);
  const [newQuestion, setNewQuestion] = useState<string | null>('');
  const [newDeadline, setNewDeadline] = useState<string | undefined>();
  const containerRef = useRef<HTMLElement>();

  useEffect(() => {
    setName('');
    setSlug('');
    setRoles([]);
    setChecklist([]);
    setNewQuestion('');
    setNewDeadline(undefined);
  }, [page]);

  const otherChecklists = others.map(v => allPages[v.id]).filter(v => v && v.checklist.length > 0);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent
          maxW="calc(0.9 * var(--dvw))"
          w="4xl"
          maxH="calc(0.85 * var(--dvh))"
          overflow="visible"
          ref={containerRef}
        >
          <Box w="100%" h="100%" overflowY="auto" position="relative">
            <ModalHeader>Page {page}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Page name</FormLabel>
                  <Input value={name} onChange={e => setName(e.currentTarget.value)} />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Filename slug</FormLabel>
                  <Input
                    placeholder="e.g. FP1, STEM16"
                    value={slug}
                    onChange={e => setSlug(e.currentTarget.value)}
                  />
                </FormControl>
                <Box w="100%">
                  <FormLabel>Roles</FormLabel>
                  <Wrap>
                    {allRoles &&
                      roles.map(role => (
                        <WrapItem key={role}>
                          <RemovableRole
                            role={allRoles[role]}
                            onRemove={() => {
                              setRoles(roles.filter(v => v != role));
                            }}
                          />
                        </WrapItem>
                      ))}
                    {allRoles && (
                      <AddRoleMenu
                        roles={Object.keys(allRoles)
                          .filter(v => !roles.includes(v))
                          .map(v => allRoles[v])}
                        canEdit
                        containerRef={containerRef}
                        onSelect={role => {
                          setRoles(roles.concat(role.id));
                        }}
                      />
                    )}
                  </Wrap>
                </Box>
                <TableContainer w="100%">
                  <Table colorScheme={colorMode == 'dark' ? 'whiteAlpha' : 'blackAlpha'}>
                    <Thead>
                      <Tr>
                        <Th ps={1}>Checklist item</Th>
                        <Th>Deadline</Th>
                        <Th isNumeric pe={1}>
                          {otherChecklists.length ? (
                            <Menu placement="bottom-end">
                              <MenuButton as={Button} size="sm">
                                Copy from page
                              </MenuButton>
                              <Portal containerRef={containerRef}>
                                <MenuList maxH="min(calc(50vh - 24px), 300px)" overflowY="auto">
                                  {otherChecklists.map(v => (
                                    <MenuItem
                                      as="div"
                                      cursor="pointer"
                                      key={v.id}
                                      onClick={async () => {
                                        setChecklist(
                                          v.checklist.map(v => ({
                                            question: v.question,
                                            deadline: v.deadline
                                          }))
                                        );
                                      }}
                                    >
                                      <Text>{v.name}</Text>
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </Portal>
                            </Menu>
                          ) : null}
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {checklist.map((item, i) => (
                        <EditableChecklistQuestion
                          key={i}
                          info={item}
                          onChange={item => {
                            const questions = [...checklist];
                            questions[i] = item;
                            setChecklist(questions);
                          }}
                          onDelete={() => {
                            setChecklist(checklist.filter((_, j) => i != j));
                          }}
                          deadlines={deadlines}
                        />
                      ))}
                      <Tr>
                        <Td ps={1}>
                          <Input
                            placeholder="e.g. Layout done"
                            value={newQuestion}
                            onChange={e => setNewQuestion(e.currentTarget.value)}
                          />
                        </Td>
                        <Td>
                          <Select
                            value={newDeadline == null ? 'none' : newDeadline}
                            onChange={evt => {
                              setNewDeadline(
                                evt.currentTarget.value == 'none'
                                  ? undefined
                                  : evt.currentTarget.value
                              );
                            }}
                          >
                            <option value="none">None</option>
                            {Object.keys(deadlines).map(key => (
                              <option key={key} value={key}>
                                {deadlines[key].name} ({prettyTime(deadlines[key].time)})
                              </option>
                            ))}
                          </Select>
                        </Td>
                        <Td isNumeric pe={1}>
                          <Button
                            leftIcon={<AddIcon />}
                            size="sm"
                            isDisabled={!newQuestion}
                            onClick={() => {
                              setChecklist(
                                checklist.concat({
                                  question: newQuestion,
                                  deadline: newDeadline
                                })
                              );
                              setNewQuestion('');
                              setNewDeadline(undefined);
                            }}
                          >
                            Add item
                          </Button>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                <Button
                  isDisabled={!name || !slug || loading}
                  isLoading={loading}
                  loadingText="Creating..."
                  colorScheme="green"
                  onClick={async () => {
                    setLoading(true);
                    if (
                      await onCreate({
                        name,
                        slug,
                        roles,
                        checklist
                      })
                    ) {
                      onClose();
                    }
                    setLoading(false);
                  }}
                >
                  Create
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
      <Card
        as="button"
        borderRadius={0}
        w={w}
        h={h}
        borderStyle="dashed"
        variant="filled"
        borderWidth="2px"
        borderColor={colorMode == 'dark' ? null : 'blackAlpha.500'}
        transition="box-shadow 200ms"
        _hover={{
          boxShadow: colorMode == 'dark' ? 'dark-lg' : 'lg'
        }}
        _active={{
          boxShadow: colorMode == 'dark' ? 'dark-lg' : 'xl'
        }}
        onClick={onOpen}
      >
        <Flex direction="column" align="center" w="100%" h="100%">
          <Flex direction="column" justify="center" align="center" w="100%" h="100%">
            <AddIcon boxSize={[6, 9]} />
            <Heading size={['md', 'lg']} mt={2} px={2}>
              Add page
            </Heading>
          </Flex>
          <Text pb={2}>{page}</Text>
        </Flex>
      </Card>
    </>
  );
};

const PageDisplay = ({
  issue,
  info,
  w,
  h,
  hide,
  noLink
}: {
  issue: string;
  info: BasicPageInfo;
  hide?: boolean;
  noLink?: boolean;
} & Pick<CardProps, 'w' | 'h'>) => {
  const { colorMode } = useColorMode();

  return (
    <Card
      as={noLink ? null : RouterLink}
      to={noLink ? null : `/issue/${issue}/page/${info.id}`}
      borderRadius={0}
      w={w}
      h={h}
      variant="filled"
      bgColor={info.pdf ? 'white' : null}
      transition="box-shadow 200ms"
      boxShadow={noLink || colorMode == 'dark' ? null : 'md'}
      _hover={{
        boxShadow: noLink ? null : colorMode == 'dark' ? 'dark-lg' : info.pdf ? 'xl' : 'lg'
      }}
      display={hide ? 'none' : null}
    >
      {info.pdf ? (
        <DrivePage fileID={info.pdf} />
      ) : (
        <Flex direction="column" align="center" w="100%" h="100%">
          <Flex direction="column" justify="center" align="center" grow="1">
            <Heading size={['md', 'lg']} px={2} textAlign="center">
              {info.name} {info.name != info.slug && <>({info.slug})</>}
            </Heading>
          </Flex>
          <Text pb={2}>{info.page}</Text>
        </Flex>
      )}
    </Card>
  );
};

const requestFullscreen =
  Element.prototype.requestFullscreen ||
  Element.prototype['webkitRequestFullscreen'] ||
  Element.prototype['webkitRequestFullScreen'] ||
  Element.prototype['mozRequestFullscreen'];

const exitFullscreen =
  document.exitFullscreen ||
  document['webkitExitFullscreen'] ||
  document['webkitCancelFullScreen'] ||
  document['mozExitFullscreen'];

const Issue = () => {
  const { issueID: id } = useParams();
  const [allIssues, setAllIssues] = useGlobalState('issues');
  const [allPages, setAllPages] = useGlobalState('pages');
  const [allRoles, setAllRoles] = useGlobalState('roles');
  const [currentIssue, setCurrentIssue] = useGlobalState('currentIssue');
  const [userInfo] = useGlobalState('userInfo');
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const delDisclosure = useDisclosure();
  const layoutDisclosure = useDisclosure();
  const [downloading, setDownloading] = useState(false);
  const info = allIssues[id];

  const updateIssue = (data: Partial<IssueInfo>) => {
    setAllIssues({
      ...allIssues,
      [id]: {
        ...info,
        ...data
      }
    });
  };

  const [updateName, setUpdateName] = useState(info ? info.name : '');
  const [updateFolio, setUpdateFolio] = useState(info ? toISODate(info.folio) : '');
  const [updatePrefix, setUpdatePrefix] = useState(info ? info.prefix : '');
  const [updateSuffix, setUpdateSuffix] = useState(info ? info.suffix : '');
  const [updateDoubletruck, setUpdateDoubletruck] = useState(info ? info.doubletruck : false);
  const [updateCurrent, setUpdateCurrent] = useState(id == currentIssue);
  const [resizeSwitch, setResizeSwitch] = useState(false);

  const [spread, setSpread] = useState(location.state ? (location.state.spread as number) : 0);
  const [pageOffset, setPageOffset] = useState(
    location.state ? (location.state.pageOffset as number) : 1
  );
  const [ignorePages] = useMediaQuery('(min-aspect-ratio: 4 / 5)', {
    fallback: window.innerWidth / window.innerHeight >= 0.8
  });
  const seenPages = useMemo(() => new Set<string>(), [info, resizeSwitch]);
  const [fullscreen, setFullscreen] = useState(false);
  const fullscreenRef = useRef<HTMLDivElement>();

  useEffect(() => {
    const fullscreenHandler = () => {
      if (document.fullscreenElement == fullscreenRef.current) {
        setFullscreen(true);
        screen.orientation?.lock?.('landscape').catch(() => {});
      } else {
        setFullscreen(false);
        screen.orientation?.unlock?.();
      }
    };

    document.addEventListener('fullscreenchange', fullscreenHandler, {
      passive: true
    });
    document.addEventListener('webkitfullscreenchange', fullscreenHandler, {
      passive: true
    });
    document.addEventListener('mozfullscreenchange', fullscreenHandler, {
      passive: true
    });

    return () => {
      document.removeEventListener('fullscreenchange', fullscreenHandler);
      document.removeEventListener('webkitfullscreenchange', fullscreenHandler);
      document.removeEventListener('mozfullscreenchange', fullscreenHandler);
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      const keydownHandler = (e: KeyboardEvent) => {
        if (e.target instanceof HTMLInputElement) return;
        if (e.key == 'W' && e.shiftKey) {
          if (fullscreen) {
            exitFullscreen.call(document);
          } else {
            requestFullscreen.call(fullscreenRef.current, { navigationUI: 'hide' });
          }
        }
      };

      window.addEventListener('keydown', keydownHandler, { passive: true });

      return () => window.removeEventListener('keydown', keydownHandler);
    }
  }, [isOpen, fullscreen]);

  const pageValues: (BasicPageInfo | null)[] = useMemo(() => {
    if (!info) return [];
    const values = Array.from({ length: info.numPages }, () => null);
    for (const id in info.pages) {
      const page = info.pages[id];
      values[page.page - 1] = page;
    }
    return values;
  }, [info && info.numPages, info && info.pages]);

  useEffect(() => {
    const ctrl = new AbortController();
    if (!allRoles) {
      roles(ctrl.signal).then(setAllRoles);
    }

    return () => ctrl.abort();
  }, []);

  useEffect(() => {
    const onResize = () => {
      if (seenPages.size) setResizeSwitch(v => !v);
    };
    window.addEventListener('resize', onResize, { passive: true });
    return () => window.removeEventListener('resize', onResize);
  }, [seenPages]);

  useEffect(() => {
    if (
      !location.state ||
      location.state.spread != spread ||
      location.state.pageOffset != pageOffset
    ) {
      const timeout = setTimeout(() => {
        navigate(location.pathname, {
          replace: true,
          state: {
            spread,
            pageOffset
          }
        });
      }, 250);

      return () => clearTimeout(timeout);
    }
  }, [location.state, spread, pageOffset]);

  useEffect(() => {
    setUpdateCurrent(info ? info.id == currentIssue : false);
  }, [info, currentIssue]);

  useEffect(() => {
    setUpdateName(info ? info.name : '');
    setUpdateFolio(info ? toISODate(info.folio) : '');
    setUpdatePrefix(info ? info.prefix : '');
    setUpdateSuffix(info ? info.suffix : '');
    setUpdateDoubletruck(info ? info.doubletruck : false);
    const ctrl = new AbortController();
    if (info) {
      if (userInfo.permission >= PermissionLevel.Strategic) {
        for (const page in info.pages) {
          if (!allPages[page]) {
            getPage(info.id, page, ctrl.signal).then(v => {
              setAllPages(pages => ({ ...pages, [page]: v }));
            });
          }
        }
      }
      const newSpread = Math.min(spread, Math.floor(info.numPages / 2));
      setSpread(newSpread);
      if (newSpread == 0) setPageOffset(1);
      else if (newSpread * 2 + pageOffset > info.numPages) setPageOffset(0);
    } else {
      issue(id, ctrl.signal).then(
        info => {
          setAllIssues({ ...allIssues, [id]: info });
        },
        () => {
          navigate('/issues', { replace: true });
        }
      );
    }
    return () => ctrl.abort();
  }, [info]);

  useEffect(() => {
    setSpread(location.state ? (location.state.spread as number) : 0);
    setPageOffset(location.state ? (location.state.pageOffset as number) : 1);
  }, [id]);

  const p0 = spread * 2 - 1;
  const p1 = spread * 2;
  const p = p0 + pageOffset;

  useEffect(() => {
    if (ignorePages) {
      if (pageValues[p0]) seenPages.add(pageValues[p0].id);
      if (pageValues[p1]) seenPages.add(pageValues[p1].id);
    } else {
      if (pageValues[p]) seenPages.add(pageValues[p].id);
    }
  }, [p0, p1, p, pageValues, ignorePages, seenPages]);

  const prevDisabled = spread == 0;
  const goPrev = () => {
    if (ignorePages) {
      setSpread(spread - 1);
      setPageOffset(1);
    } else {
      if (pageOffset == 1) {
        setPageOffset(0);
      } else {
        setSpread(spread - 1);
        setPageOffset(1);
      }
    }
  };

  const goNext = () => {
    if (ignorePages) {
      setSpread(spread + 1);
      setPageOffset(0);
    } else {
      if (pageOffset == 0) {
        setPageOffset(1);
      } else {
        setSpread(spread + 1);
        setPageOffset(0);
      }
    }
  };

  useEffect(() => {
    if (info && !isOpen) {
      const keydownHandler = (e: KeyboardEvent) => {
        if (e.target instanceof HTMLInputElement) return;

        if (e.key == 'ArrowLeft') {
          if (!prevDisabled) {
            goPrev();
          }
        } else if (e.key == 'ArrowRight') {
          if (!nextDisabled) {
            goNext();
          }
        }
      };

      window.addEventListener('keydown', keydownHandler, { passive: true });
      return () => window.removeEventListener('keydown', keydownHandler);
    }
  }, [info, spread, pageOffset, ignorePages, isOpen]);

  if (!info) {
    return (
      <Flex w="100%" h="calc(var(--dvh) - 80px)" justify="center" align="center">
        <Loading />
      </Flex>
    );
  }

  const maxSpread = Math.floor(info.numPages / 2);
  const nextDisabled = spread == maxSpread && (ignorePages || p == info.numPages - 1);

  const deadlineValues = Object.values(info.deadlines).sort((a, b) => a.time - b.time);
  const nextDeadline = deadlineValues.find(v => Date.now() <= v.time);

  const onCreatePage = async (page: number, pageInfo: CreatePageInfo<string>) => {
    try {
      const pageID = await api<string>('/assets/issue/' + id + '/page', {
        method: 'POST',
        body: {
          ...pageInfo,
          page
        }
      });
      updateIssue({
        pages: {
          ...info.pages,
          [pageID]: {
            id: pageID,
            name: pageInfo.name,
            slug: pageInfo.slug,
            page,
            modified: info.startTime,
            canEdit: true
          }
        }
      });
      setAllPages({
        ...allPages,
        [pageID]: {
          id: pageID,
          ...pageInfo,
          page,
          answers: pageInfo.checklist.map(() => false),
          revisions: []
        }
      });
      return true;
    } catch (err) {
      // todo: toast?
      return false;
    }
  };

  const curW = ignorePages ? pageW : singlePageW;
  const curH = ignorePages ? pageH : singlePageH;

  const pageDisplay = (num: number) =>
    num < 0 || num >= info.numPages ? (
      <NonPage key={num == p1 ? 'non.1' : 'non'} w={curW} h={curH} />
    ) : pageValues[num] ? (
      <PageDisplay key={pageValues[num].id} issue={id} info={pageValues[num]} w={curW} h={curH} />
    ) : userInfo.permission >= PermissionLevel.Strategic ? (
      <NewIssuePage
        key={num == p1 ? 'new.1' : 'new'}
        onCreate={info => onCreatePage(num + 1, info)}
        deadlines={info.deadlines}
        others={pageValues.filter((v, i) => v && i != num)}
        page={num + 1}
        w={curW}
        h={curH}
      />
    ) : (
      <NonPage key={num == p1 ? 'non.1' : 'non'} w={curW} h={curH} />
    );

  const fullscreenPageDisplay = (num: number) =>
    pageValues[num] ? (
      <PageDisplay
        key={pageValues[num].id}
        issue={id}
        info={pageValues[num]}
        w={fullscreenPageW}
        h={fullscreenPageH}
        noLink
      />
    ) : (
      <NonPage key={num == p1 ? 'non.1' : 'non'} w={fullscreenPageW} h={fullscreenPageH} />
    );

  const downloadAll = async () => {
    setDownloading(true);
    try {
      const rootDoc = await PDFDocument.create();

      const pages = await Promise.all(
        pageValues.map(async v => {
          if (!v || !v.pdf) return null;

          const doc = await getDoc(v.pdf);
          const pdf = await PDFDocument.load(await doc.arrayBuffer());
          const [page] = await rootDoc.copyPages(pdf, [0]);
          return page;
        })
      );

      // best guess for the size = first existing page
      const baseDims = pages.find(v => v).getSize();

      for (const page of pages) {
        if (!page) {
          rootDoc.addPage([baseDims.width, baseDims.height]);
        } else {
          const scaleX = baseDims.width / page.getWidth();
          const scaleY = baseDims.height / page.getHeight();
          if (scaleX != 1 || scaleY != 1) {
            page.scale(scaleX, scaleY);
          }
          rootDoc.addPage(page);
        }
      }

      const result = await rootDoc.save();
      const blob = new Blob([result], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = info.name + '.pdf';
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (err) {
      // todo: toast?
    }
    setDownloading(false);
  };

  return (
    <Flex p={6} direction="column" minH="calc(var(--dvh) - 80px)">
      <Flex direction="row" justify="space-between" align="center" mb={4}>
        <Wrap spacing={4} align="center" pe={4} overflow="visible">
          <WrapItem>
            <Heading lineHeight={1}>{info.name}</Heading>
          </WrapItem>
          {userInfo.permission >= PermissionLevel.Strategic && (
            <WrapItem>
              <ButtonGroup>
                <IconButton
                  aria-label="Edit issue"
                  variant="outline"
                  icon={<EditIcon />}
                  onClick={onOpen}
                />
                <IconButton
                  aria-label="Delete issue"
                  variant="outline"
                  colorScheme="red"
                  icon={<DeleteIcon />}
                  onClick={delDisclosure.onOpen}
                />
                <Drawer isOpen={isOpen} onClose={onClose} placement="left">
                  <DrawerOverlay />
                  <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Edit issue</DrawerHeader>
                    <DrawerBody>
                      <VStack spacing={4} width="100%" alignSelf="flex-start" align="flex-start">
                        <FormControl isRequired>
                          <FormLabel>Issue name</FormLabel>
                          <Input
                            placeholder="e.g. Issue 5"
                            value={updateName}
                            onChange={e => setUpdateName(e.currentTarget.value)}
                          />
                        </FormControl>
                        <FormControl
                          isRequired
                          isInvalid={updateFolio && isNaN(Date.parse(updateFolio))}
                        >
                          <FormLabel>Folio</FormLabel>
                          <Input
                            value={updateFolio}
                            type="date"
                            onChange={e => setUpdateFolio(e.currentTarget.value)}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Filename prefix</FormLabel>
                          <Input
                            placeholder="e.g. I5_"
                            value={updatePrefix}
                            onChange={e => setUpdatePrefix(e.currentTarget.value)}
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Filename suffix</FormLabel>
                          <Input
                            placeholder={`e.g. _${curYear}-${curYear + 1}`}
                            value={updateSuffix}
                            onChange={e => setUpdateSuffix(e.currentTarget.value)}
                          />
                        </FormControl>
                        <Checkbox
                          isChecked={updateDoubletruck}
                          isDisabled={pageValues.length % 4 != 0}
                          onChange={e => setUpdateDoubletruck(e.currentTarget.checked)}
                        >
                          Use doubletruck layout
                        </Checkbox>
                        <Checkbox
                          isChecked={updateCurrent}
                          onChange={e => setUpdateCurrent(e.currentTarget.checked)}
                        >
                          Set as current issue
                        </Checkbox>
                      </VStack>
                    </DrawerBody>
                    <DrawerFooter>
                      <Button
                        isDisabled={
                          !updateName || !updateFolio || isNaN(Date.parse(updateFolio)) || loading
                        }
                        isLoading={loading}
                        loadingText="Saving..."
                        onClick={async () => {
                          if (
                            info.name != updateName ||
                            info.folio != Date.parse(updateFolio.replace(/-/g, '/')) ||
                            info.prefix != updatePrefix ||
                            info.suffix != updateSuffix ||
                            info.doubletruck != updateDoubletruck ||
                            (currentIssue == info.id) != updateCurrent
                          ) {
                            setLoading(true);
                            const update = {
                              name: updateName,
                              folio: Date.parse(updateFolio.replace(/-/g, '/')),
                              prefix: updatePrefix,
                              suffix: updateSuffix,
                              doubletruck: updateDoubletruck,
                              current: updateCurrent
                            };
                            try {
                              await api('/assets/issue/' + info.id, {
                                method: 'PATCH',
                                body: update
                              });
                              updateIssue(update);
                              if (updateCurrent) setCurrentIssue(info.id);
                              else if (currentIssue == info.id) setCurrentIssue('');
                            } catch (err) {
                              // todo: toast?
                            }
                            setLoading(false);
                          }
                          onClose();
                        }}
                      >
                        Save
                      </Button>
                    </DrawerFooter>
                  </DrawerContent>
                </Drawer>
                <DeleteIssueModal
                  id={info.id}
                  isOpen={delDisclosure.isOpen}
                  onClose={delDisclosure.onClose}
                />
              </ButtonGroup>
            </WrapItem>
          )}
        </Wrap>
        {nextDeadline ? (
          <HStack>
            <Show above="md">
              <Text textAlign="end">
                Next deadline:{' '}
                <Text as="span" fontWeight="semibold">
                  {nextDeadline.name} ({prettyTime(nextDeadline.time)})
                </Text>
              </Text>
              <Button as={RouterLink} to={`/issue/${id}/deadlines/${nextDeadline.id}`} size="xs">
                View
              </Button>
            </Show>
            <Hide above="md">
              <Button as={RouterLink} to={`/issue/${id}/deadlines`} size="sm">
                View deadlines
              </Button>
            </Hide>
          </HStack>
        ) : (
          <Button as={RouterLink} to={`/issue/${id}/deadlines`} size="sm">
            View deadlines
          </Button>
        )}
      </Flex>
      <Flex align="center" justify="center" flexGrow={1}>
        <VStack>
          <HStack spacing={2}>
            <IconButton
              aria-label={ignorePages ? 'Previous pages' : 'Previous page'}
              variant="ghost"
              colorScheme="gray"
              icon={<ChevronLeftIcon boxSize={8} />}
              isDisabled={prevDisabled}
              onClick={goPrev}
            />
            <HStack spacing={2} pos="relative" style={{ marginInlineStart: '0' }}>
              <Box display="none" />
              {[...seenPages]
                .filter(page =>
                  ignorePages
                    ? pageValues[p0]?.id != page && pageValues[p1]?.id != page
                    : pageValues[p]?.id != page
                )
                .map(page => (
                  <PageDisplay
                    key={page}
                    issue={id}
                    info={info.pages[page]}
                    w={curW}
                    h={curH}
                    hide
                  />
                ))
                .concat(ignorePages ? [pageDisplay(p0), pageDisplay(p1)] : [pageDisplay(p)])}
              <IconButton
                size="sm"
                variant="unstyled"
                bgColor="blackAlpha.500"
                _hover={{
                  bgColor: 'blackAlpha.600'
                }}
                _active={{
                  bgColor: 'blackAlpha.700'
                }}
                color="white"
                aria-label="Fullscreen"
                pos="absolute"
                bottom={2}
                right={2}
                icon={<FullscreenIcon boxSize={6} />}
                onClick={() => {
                  requestFullscreen.call(fullscreenRef.current, {
                    navigationUI: 'hide'
                  });
                }}
              />
            </HStack>
            <IconButton
              aria-label={ignorePages ? 'Next pages' : 'Next page'}
              variant="ghost"
              colorScheme="gray"
              icon={<ChevronRightIcon boxSize={8} />}
              isDisabled={nextDisabled}
              onClick={goNext}
            />
          </HStack>
          <Slider
            aria-label="Select page"
            min={0}
            max={ignorePages ? maxSpread : info.numPages - 1}
            value={ignorePages ? spread : p}
            onChange={v => {
              if (ignorePages) {
                setSpread(v);
                if (v > spread) setPageOffset(0);
                else if (v < spread) setPageOffset(1);
              } else {
                setSpread(Math.floor((v + 1) / 2));
                setPageOffset((v + 1) % 2);
              }
            }}
            w={ignorePages ? sliderW : singlePageW}
          >
            <SliderTrack>
              <SliderFilledTrack bg="green.700" />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Flex
            direction="column"
            justify="center"
            align="center"
            w={ignorePages ? sliderW : singlePageW}
            pos="relative"
            pt={0.5}
          >
            <Text justifySelf="center">
              {ignorePages ? (
                <>
                  Page
                  {spread == 0
                    ? ' 1 '
                    : spread == maxSpread && !(info.numPages & 1)
                    ? ` ${info.numPages} `
                    : ` ${spread * 2}-${spread * 2 + 1} `}{' '}
                  of {info.numPages}
                </>
              ) : (
                'Page ' + (p + 1) + ' of ' + info.numPages
              )}
            </Text>
            {userInfo.permission >= PermissionLevel.Strategic && (
              <>
                <Show breakpoint={'(min-width: 48em) and (min-height: 36em)'}>
                  <Button
                    leftIcon={<EditIcon />}
                    pos="absolute"
                    bottom={-1}
                    right={0}
                    size="sm"
                    onClick={layoutDisclosure.onOpen}
                  >
                    Edit layout
                  </Button>
                  {pageValues.some(v => v && v.pdf) && (
                    <Button
                      pos="absolute"
                      bottom={-1}
                      left={0}
                      isLoading={downloading}
                      leftIcon={<DownloadIcon />}
                      size="sm"
                      onClick={downloadAll}
                    >
                      Full PDF
                    </Button>
                  )}
                </Show>
                <Hide breakpoint={'(min-width: 48em) and (min-height: 36em)'}>
                  <IconButton
                    aria-label="Edit layout"
                    pos="absolute"
                    bottom={-1}
                    right={0}
                    icon={<EditIcon />}
                    size="sm"
                    onClick={layoutDisclosure.onOpen}
                  />
                  {pageValues.some(v => v && v.pdf) && (
                    <IconButton
                      aria-label="Full PDF"
                      pos="absolute"
                      isLoading={downloading}
                      bottom={-1}
                      left={0}
                      icon={<DownloadIcon />}
                      size="sm"
                      onClick={downloadAll}
                    />
                  )}
                </Hide>
                <EditLayout
                  isOpen={layoutDisclosure.isOpen}
                  onClose={layoutDisclosure.onClose}
                  pages={pageValues}
                  onUpdate={async newPages => {
                    if (
                      newPages.length == info.numPages &&
                      newPages.every((v, i) => !v || v.page == i + 1)
                    )
                      return true;
                    try {
                      const doubletruck = newPages.length % 4 == 0 && info.doubletruck;
                      await api('/assets/issue/' + info.id, {
                        method: 'PATCH',
                        body: {
                          numPages: newPages.length,
                          doubletruck,
                          pageOrder: newPages.map(v => v && v.id)
                        }
                      });
                      const newPagesObj = {};
                      for (let i = 0; i < newPages.length; ++i) {
                        const page = newPages[i];
                        if (!page) continue;
                        newPagesObj[page.id] = {
                          ...page,
                          page: i + 1
                        };
                      }
                      updateIssue({
                        numPages: newPages.length,
                        doubletruck,
                        pages: newPagesObj
                      });
                      return true;
                    } catch (err) {
                      // todo: toast?
                      return false;
                    }
                  }}
                />
              </>
            )}
          </Flex>
        </VStack>
      </Flex>
      <Flex
        pos="fixed"
        w="100vw"
        h="100vh"
        display={fullscreen ? null : 'none'}
        ref={fullscreenRef}
        align="center"
        justify="center"
      >
        <DarkMode>
          <Flex direction="row" align="center">
            <IconButton
              aria-label={ignorePages ? 'Previous pages' : 'Previous page'}
              variant="ghost"
              colorScheme="gray"
              me={2}
              icon={<ChevronLeftIcon boxSize={8} />}
              isDisabled={prevDisabled}
              onClick={goPrev}
            />
            {fullscreen &&
              [...seenPages]
                .filter(page => pageValues[p0]?.id != page && pageValues[p1]?.id != page)
                .map(page => (
                  <PageDisplay
                    key={page}
                    issue={id}
                    info={info.pages[page]}
                    w={fullscreenPageW}
                    h={fullscreenPageH}
                    noLink
                    hide
                  />
                ))
                .concat([fullscreenPageDisplay(p0), fullscreenPageDisplay(p1)])}
            <IconButton
              aria-label={ignorePages ? 'Next pages' : 'Next page'}
              variant="ghost"
              colorScheme="gray"
              ms={2}
              icon={<ChevronRightIcon boxSize={8} />}
              isDisabled={nextDisabled}
              onClick={goNext}
            />
          </Flex>
          <IconButton
            aria-label="Exit fullscreen"
            variant="ghost"
            colorScheme="gray"
            pos="absolute"
            top={2}
            right={2}
            icon={<CloseIcon boxSize={4} />}
            isDisabled={nextDisabled}
            onClick={() => exitFullscreen.call(document)}
          />
        </DarkMode>
      </Flex>
    </Flex>
  );
};

const IssueCard = ({ info }: { info: IssueInfo }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [userInfo] = useGlobalState('userInfo');
  const [currentIssue] = useGlobalState('currentIssue');
  const { colorMode } = useColorMode();

  return (
    <LinkBox w="100%">
      <Card
        variant="filled"
        direction="row"
        key={info.id}
        transition="box-shadow 200ms ease"
        _hover={{
          boxShadow: colorMode == 'dark' ? 'dark-lg' : 'lg'
        }}
        _active={{
          boxShadow: colorMode == 'dark' ? 'dark-lg' : 'xl'
        }}
      >
        <CardHeader>
          <VStack align="flex-start">
            <HStack>
              <LinkOverlay as={RouterLink} to={'/issue/' + info.id}>
                <Heading size="md">{info.name}</Heading>
              </LinkOverlay>
              {info.id == currentIssue && <Badge>Current</Badge>}
            </HStack>
            <Text fontSize="xs">
              Publish{info.folio < Date.now() ? 'ed' : 'ing'} {prettyDate(info.folio)}
            </Text>
          </VStack>
        </CardHeader>
        <CardBody>
          <DeleteIssueModal isOpen={isOpen} onClose={onClose} id={info.id} />
          <Flex w="100%" h="100%" justifyContent="flex-end" alignItems="center">
            {userInfo.permission >= PermissionLevel.Strategic && (
              <ButtonGroup>
                <IconButton
                  aria-label="Use as template"
                  icon={<CopyIcon boxSize={6} />}
                  variant="ghost"
                  colorScheme="gray"
                  as={RouterLink}
                  to={'/issues/new?from=' + info.id}
                />
                <IconButton
                  aria-label="Delete"
                  icon={<DeleteIcon boxSize={6} />}
                  variant="ghost"
                  colorScheme="red"
                  onClick={onOpen}
                />
              </ButtonGroup>
            )}
          </Flex>
        </CardBody>
      </Card>
    </LinkBox>
  );
};

const AllIssues = () => {
  const [userInfo] = useGlobalState('userInfo');
  const [issues, setIssues] = useGlobalState('issues');

  useEffect(() => {
    const ctrl = new AbortController();
    for (const id in issues) {
      if (!issues[id]) {
        issue(id, ctrl.signal).then(result => {
          setIssues(issues => ({ ...issues, [id]: result }));
        });
      }
    }
    return () => ctrl.abort();
  }, []);

  const issueIDs = Object.keys(issues)
    .filter(v => issues[v])
    .sort((a, b) => issues[b].folio - issues[a].folio);

  return (
    <Box p={6}>
      <Flex justify="space-between">
        <Heading size={['lg', 'xl']}>All issues</Heading>
        {userInfo.permission >= PermissionLevel.Strategic && (
          <Button size={['sm', 'md']} leftIcon={<AddIcon />} as={RouterLink} to="/issues/new">
            New issue
          </Button>
        )}
      </Flex>
      <VStack w="100%" mt={4}>
        {issueIDs.map(v => (
          <IssueCard key={v} info={issues[v]} />
        ))}
        {issueIDs.length == 0 && (
          <Text textAlign="center">
            {userInfo.permission >= PermissionLevel.Strategic
              ? 'Click "New issue" to create the first issue!'
              : 'No issues created'}
          </Text>
        )}
      </VStack>
    </Box>
  );
};

// Force 17/11 aspect ratio for pages
const newPageH = [
  'calc(calc(0.7418 * var(--dvw)) - 99px)',
  'calc(calc(0.7418 * var(--dvw)) - 99px)',
  'calc(calc(0.7418 * var(--dvw)) - 99px)',
  'calc(calc(0.7418 * var(--dvw)) - 99px)',
  'min(calc(var(--dvh) - 246px), calc(0.3864 * var(--dvw)))'
];
const newPageW = [
  'calc(calc(0.48 * var(--dvw)) - 64px)',
  'calc(calc(0.48 * var(--dvw)) - 64px)',
  'calc(calc(0.48 * var(--dvw)) - 64px)',
  'calc(calc(0.48 * var(--dvw)) - 64px)',
  'min(calc(calc(0.6471 * var(--dvh)) - 159.2px), calc(0.25 * var(--dvw)))'
];
const newSliderW = newPageW.map(v => `calc(calc(2 * ${v}) + 8px)`);
const maxNewTableW = [
  'var(--dvw)',
  'var(--dvw)',
  'var(--dvw)',
  'var(--dvw)',
  `calc(var(--dvw) - calc(${newSliderW[newSliderW.length - 1]} + 168px))`
];
const newPageA = ['column', 'column', 'column', 'column', 'row'] as ('row' | 'column')[];

const NonPage = ({ w, h, dark }: Pick<CardProps, 'w' | 'h'> & { dark?: boolean }) => {
  return (
    <Card borderRadius={0} w={w} h={h} variant="outline" borderWidth="2px" overflow="hidden">
      <Card
        borderRadius={0}
        w="9999px"
        h={0}
        position="absolute"
        top="-2px"
        left={0}
        variant="outline"
        borderWidth="2px"
        transform="rotate(0.9978rad)"
        borderBottom="none"
        transformOrigin="top left"
      />
      <Card
        borderRadius={0}
        w="9999px"
        h={0}
        position="absolute"
        top="-2px"
        right={0}
        variant="outline"
        borderWidth="2px"
        borderBottom="none"
        transform="rotate(-0.9978rad)"
        transformOrigin="top right"
      />
    </Card>
  );
};

type CreateDeadline = {
  name: string;
  time: number;
};

type CreateChecklistQuestion<T extends string | number> = {
  question: string;
  deadline?: T;
};

type CreatePageInfo<T extends string | number> = {
  name: string;
  slug: string;
  roles: string[];
  checklist: CreateChecklistQuestion<T>[];
} | null;

const prettyTime = (time: number) => {
  const date = new Date(time);

  let hour = date.getHours();
  let suffix = 'AM';

  if (hour >= 12) {
    suffix = 'PM';
    hour -= 12;
  }

  if (!hour) hour = 12;

  return `${prettyDate(time)}, ${hour}:${date.getMinutes().toString().padStart(2, '0')} ${suffix}`;
};

const prettyDate = (time: number) => {
  const date = new Date(time);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

const EditableChecklistQuestion = <T extends string | number>({
  info,
  deadlines,
  onChange,
  onDelete
}: {
  info: CreateChecklistQuestion<T>;
  deadlines: Record<T, CreateDeadline>;
  onChange: (question: CreateChecklistQuestion<T>) => unknown;
  onDelete: () => unknown;
}) => {
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState(info.question);
  const [deadline, setDeadline] = useState(info.deadline);
  const [editing, setEditing] = useState(false);

  const reset = () => {
    setQuestion(info.question);
    setDeadline(info.deadline);
  };

  useEffect(reset, [info]);

  return (
    <Tr>
      <Td ps={1}>
        {editing ? (
          <Input
            isDisabled={loading}
            value={question}
            onChange={e => setQuestion(e.currentTarget.value)}
          />
        ) : (
          <Text fontWeight="semibold">{question}</Text>
        )}
      </Td>
      <Td>
        {editing ? (
          <Select
            isDisabled={loading}
            value={deadline == null ? 'none' : deadline}
            onChange={evt => {
              setDeadline(
                evt.currentTarget.value == 'none'
                  ? undefined
                  : ((typeof deadline == 'number'
                      ? +evt.currentTarget.value
                      : evt.currentTarget.value) as T)
              );
            }}
          >
            <option value="none">None</option>
            {Object.keys(deadlines).map(key => (
              <option key={key} value={key}>
                {deadlines[key].name} ({prettyTime(deadlines[key].time)})
              </option>
            ))}
          </Select>
        ) : (
          <Text fontWeight="semibold">
            {deadline == null
              ? 'None'
              : `${deadlines[deadline].name} (${prettyTime(deadlines[deadline].time)})`}
          </Text>
        )}
      </Td>
      <Td isNumeric pe={1}>
        <ButtonGroup>
          {editing ? (
            <>
              <IconButton
                aria-label="Save"
                icon={<CheckIcon />}
                isDisabled={!question || loading}
                colorScheme="green"
                onClick={async () => {
                  setLoading(true);
                  await onChange({
                    question,
                    deadline
                  });
                  setLoading(false);
                  setEditing(false);
                }}
              />
              <IconButton
                aria-label="Cancel"
                variant="outline"
                icon={<CloseIcon />}
                isDisabled={loading}
                colorScheme="red"
                onClick={() => {
                  setEditing(false);
                  reset();
                }}
              />
            </>
          ) : (
            <>
              <IconButton
                aria-label="Edit"
                icon={<EditIcon />}
                isDisabled={loading}
                colorScheme="gray"
                onClick={() => {
                  setEditing(true);
                }}
              />
              <IconButton
                aria-label="Delete"
                variant="outline"
                icon={<DeleteIcon />}
                isDisabled={loading}
                colorScheme="red"
                onClick={async () => {
                  setLoading(true);
                  await onDelete();
                  setLoading(false);
                }}
              />
            </>
          )}
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

const NewPage = ({
  info,
  page,
  deadlines,
  others,
  onChange
}: {
  info: CreatePageInfo<number>;
  page: number;
  deadlines: CreateDeadline[];
  others: CreatePageInfo<number>[];
  onChange: (info: CreatePageInfo<number>) => unknown;
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { colorMode } = useColorMode();
  const [name, setName] = useState(info ? info.name : '');
  const [slug, setSlug] = useState(info ? info.slug : '');
  const [allRoles] = useGlobalState('roles');
  const [roles, setRoles] = useState(info ? info.roles : []);
  const [checklist, setChecklist] = useState<CreateChecklistQuestion<number>[]>(
    info ? info.checklist : []
  );
  const [newQuestion, setNewQuestion] = useState<string | null>('');
  const [newDeadline, setNewDeadline] = useState<number | undefined>();
  const containerRef = useRef<HTMLElement>();

  useEffect(() => {
    if (info) {
      setName(info.name);
      setSlug(info.slug);
      setRoles(info.roles);
      setChecklist(info.checklist);
    } else {
      setName('');
      setSlug('');
      setRoles([]);
      setChecklist([]);
    }
    setNewQuestion('');
    setNewDeadline(undefined);
  }, [info]);

  const templates = others
    .filter(v => v && v.checklist.length)
    .map(v => ({
      name: v.name,
      checklist: v.checklist
    }));

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered returnFocusOnClose={false}>
        <ModalOverlay />
        <ModalContent
          maxW="calc(0.9 * var(--dvw))"
          w="4xl"
          maxH="calc(0.85 * var(--dvh))"
          overflow="visible"
          ref={containerRef}
        >
          <Box w="100%" h="100%" overflowY="auto" position="relative">
            <ModalHeader>Page {page}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Page name</FormLabel>
                  <Input value={name} onChange={e => setName(e.currentTarget.value)} />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Filename slug</FormLabel>
                  <Input
                    placeholder="e.g. FP1, STEM16"
                    value={slug}
                    onChange={e => setSlug(e.currentTarget.value)}
                  />
                </FormControl>
                <Box w="100%">
                  <FormLabel>Roles</FormLabel>
                  <Wrap>
                    {allRoles &&
                      roles.map(role => (
                        <WrapItem key={role}>
                          <RemovableRole
                            role={allRoles[role]}
                            onRemove={() => {
                              setRoles(roles.filter(v => v != role));
                            }}
                          />
                        </WrapItem>
                      ))}
                    {allRoles && (
                      <AddRoleMenu
                        roles={Object.keys(allRoles)
                          .filter(v => !roles.includes(v))
                          .map(v => allRoles[v])}
                        canEdit
                        containerRef={containerRef}
                        onSelect={role => {
                          setRoles(roles.concat(role.id));
                        }}
                      />
                    )}
                  </Wrap>
                </Box>
                <TableContainer w="100%">
                  <Table colorScheme={colorMode == 'dark' ? 'whiteAlpha' : 'blackAlpha'}>
                    <Thead>
                      <Tr>
                        <Th ps={1}>Checklist item</Th>
                        <Th>Deadline</Th>
                        <Th isNumeric pe={1}>
                          {templates.length ? (
                            <Menu placement="bottom-end">
                              <MenuButton as={Button} size="sm">
                                Copy from page
                              </MenuButton>
                              <Portal containerRef={containerRef}>
                                <MenuList maxH="min(calc(50vh - 24px), 300px)" overflowY="auto">
                                  {templates.map((v, i) => (
                                    <MenuItem
                                      key={i}
                                      onClick={() => {
                                        setChecklist(v.checklist.slice());
                                      }}
                                    >
                                      <Text fontSize="md">{v.name}</Text>
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </Portal>
                            </Menu>
                          ) : null}
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {checklist.map((item, i) => (
                        <EditableChecklistQuestion
                          key={i}
                          info={item}
                          onChange={item => {
                            const questions = [...checklist];
                            questions[i] = item;
                            setChecklist(questions);
                          }}
                          onDelete={() => {
                            setChecklist(checklist.filter((_, j) => i != j));
                          }}
                          deadlines={deadlines}
                        />
                      ))}
                      <Tr>
                        <Td ps={1}>
                          <Input
                            placeholder="e.g. Layout done"
                            value={newQuestion}
                            onChange={e => setNewQuestion(e.currentTarget.value)}
                          />
                        </Td>
                        <Td>
                          <Select
                            value={newDeadline == null ? 'none' : newDeadline}
                            onChange={evt => {
                              setNewDeadline(
                                evt.currentTarget.value == 'none'
                                  ? undefined
                                  : +evt.currentTarget.value
                              );
                            }}
                          >
                            <option value="none">None</option>
                            {deadlines.map((deadline, i) => (
                              <option key={i} value={i}>
                                {deadline.name} ({prettyTime(deadline.time)})
                              </option>
                            ))}
                          </Select>
                        </Td>
                        <Td isNumeric pe={1}>
                          <Button
                            leftIcon={<AddIcon />}
                            size="sm"
                            isDisabled={!newQuestion}
                            onClick={() => {
                              setChecklist(
                                checklist.concat({
                                  question: newQuestion,
                                  deadline: newDeadline
                                })
                              );
                              setNewQuestion('');
                              setNewDeadline(undefined);
                            }}
                          >
                            Add item
                          </Button>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </TableContainer>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup>
                {info && (
                  <Button
                    colorScheme="red"
                    variant="outline"
                    onClick={() => {
                      onChange(null);
                      onClose();
                    }}
                  >
                    Delete
                  </Button>
                )}
                <Button
                  isDisabled={!name || !slug}
                  colorScheme="green"
                  onClick={() => {
                    onChange({
                      name,
                      slug,
                      roles,
                      checklist
                    });
                    onClose();
                  }}
                >
                  {info ? 'Save' : 'Create'}
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
      {info ? (
        <Card
          as="button"
          borderRadius={0}
          w={newPageW}
          h={newPageH}
          variant="filled"
          transition="box-shadow 200ms"
          _hover={{
            boxShadow: colorMode == 'dark' ? 'dark-lg' : 'lg'
          }}
          _active={{
            boxShadow: colorMode == 'dark' ? 'dark-lg' : 'xl'
          }}
          onClick={onOpen}
        >
          <Flex direction="column" align="center" w="100%" h="100%">
            <Flex direction="column" justify="center" align="center" grow="1">
              <Heading size={['md', 'lg']} px={2} textAlign="center">
                {info.name} {info.name != info.slug && <>({info.slug})</>}
              </Heading>
              <Wrap mt={2}>
                {allRoles && info.roles.map(role => <RoleTag role={allRoles[role]} />)}
              </Wrap>
            </Flex>
            <Text pb={2}>{page}</Text>
          </Flex>
        </Card>
      ) : (
        <Card
          as="button"
          borderRadius={0}
          w={newPageW}
          h={newPageH}
          variant="filled"
          borderStyle="dashed"
          borderWidth="2px"
          borderColor={colorMode == 'dark' ? null : 'blackAlpha.500'}
          transition="box-shadow 200ms"
          _hover={{
            boxShadow: colorMode == 'dark' ? 'dark-lg' : 'lg'
          }}
          _active={{
            boxShadow: colorMode == 'dark' ? 'dark-lg' : 'xl'
          }}
          onClick={onOpen}
        >
          <Flex direction="column" align="center" w="100%" h="100%">
            <Flex direction="column" justify="center" align="center" w="100%" h="100%">
              <AddIcon boxSize={[6, 9]} />
              <Heading size={['md', 'lg']} mt={2} px={2}>
                Add page
              </Heading>
            </Flex>
            <Text pb={2}>{page}</Text>
          </Flex>
        </Card>
      )}
    </>
  );
};

const pad2 = (num: number) => num.toString().padStart(2, '0');

const toISODate = (time: number) => {
  const date = new Date(time);
  return `${date.getFullYear()}-${pad2(date.getMonth() + 1)}-${pad2(date.getDate())}`;
};

const toISO = (time: number) => {
  const date = new Date(time);
  return `${toISODate(time)}T${pad2(date.getHours())}:${pad2(date.getMinutes())}`;
};

const EditableDeadline = ({
  info,
  onChange,
  onDelete
}: {
  info: CreateDeadline;
  onChange: (deadline: CreateDeadline) => unknown;
  onDelete: () => unknown;
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(info.name);
  const [localTime, setLocalTime] = useState(toISO(info.time));
  const [editing, setEditing] = useState(false);

  const reset = () => {
    setName(info.name);
    setLocalTime(toISO(info.time));
  };

  useEffect(reset, [info]);

  const timeInvalid = isNaN(Date.parse(localTime));

  return (
    <Tr>
      <Td ps={1} pe={1}>
        {editing ? (
          <Input isDisabled={loading} value={name} onChange={e => setName(e.currentTarget.value)} />
        ) : (
          <Text fontWeight="semibold">{name}</Text>
        )}
      </Td>
      <Td pe={1}>
        {editing ? (
          <Input
            isInvalid={timeInvalid}
            type="datetime-local"
            isDisabled={loading}
            value={localTime}
            onChange={e => setLocalTime(e.currentTarget.value)}
          />
        ) : (
          <Text fontWeight="semibold">{prettyTime(Date.parse(localTime))}</Text>
        )}
      </Td>
      <Td isNumeric pe={1}>
        <ButtonGroup>
          {editing ? (
            <>
              <IconButton
                aria-label="Save"
                icon={<CheckIcon />}
                isDisabled={!name || timeInvalid || loading}
                colorScheme="green"
                onClick={async () => {
                  setLoading(true);
                  await onChange({
                    name,
                    time: Date.parse(localTime)
                  });
                  setLoading(false);
                  setEditing(false);
                }}
              />
              <IconButton
                aria-label="Cancel"
                variant="outline"
                icon={<CloseIcon />}
                isDisabled={loading}
                colorScheme="red"
                onClick={() => {
                  setEditing(false);
                  reset();
                }}
              />
            </>
          ) : (
            <>
              <IconButton
                aria-label="Edit"
                icon={<EditIcon />}
                isDisabled={loading}
                colorScheme="gray"
                onClick={() => {
                  setEditing(true);
                }}
              />
              <IconButton
                aria-label="Delete"
                variant="outline"
                icon={<DeleteIcon />}
                isDisabled={loading}
                colorScheme="red"
                onClick={async () => {
                  setLoading(true);
                  await onDelete();
                  setLoading(false);
                }}
              />
            </>
          )}
        </ButtonGroup>
      </Td>
    </Tr>
  );
};

const cd = [2, 3, 5].map(n => {
  const w = `calc(calc(calc(calc(0.9 * var(--dvw)) - 89px) / ${n}) - 8px)`;
  const h = `calc(calc(calc(calc(1.3909 * var(--dvw)) - 148.36px) / ${n}) - 12.36px)`;
  return { w, h };
});
const cw = cd.map(v => v.w).concat('125.33px');
const ch = cd.map(v => v.h).concat('193.69px');

type EditLayoutInfo = {
  name: string;
  slug: string;
  pdf?: string;
} | null;

const DraggableCard = ({
  info,
  page,
  index,
  onStart,
  onMove,
  onDrop,
  onCancel,
  boxRef,
  padRef
}: {
  info: EditLayoutInfo;
  page: number;
  index: number;
  onStart: (x: number, y: number, w: number, h: number) => unknown;
  onMove: (x: number, y: number, w: number, h: number) => unknown;
  onDrop: (x: number, y: number, w: number, h: number) => unknown;
  onCancel: (x: number, y: number, w: number, h: number) => unknown;
  boxRef: Ref<HTMLLIElement>;
  padRef: Ref<HTMLLIElement>;
}) => {
  const { colorMode } = useColorMode();
  const dragging = useRef<boolean>(false);
  const dragStartPos = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0
  });
  const lastMove = useRef<{ x: number; y: number }>({
    x: 0,
    y: 0
  });
  const innerRef = useRef<HTMLLIElement>();
  type Touchy = { clientX: number; clientY: number };
  const scroller = useRef<HTMLElement>(null);
  const observer = useRef<IntersectionObserver>();
  useEffect(() => {
    let curID = 0;
    observer.current = new IntersectionObserver(
      ([entry]) => {
        if (dragging.current) {
          const dir =
            entry.intersectionRect.top > entry.boundingClientRect.top
              ? -1
              : entry.intersectionRect.bottom < entry.boundingClientRect.bottom
              ? 1
              : 0;
          let bound =
            entry.intersectionRatio > 0.875
              ? 1
              : entry.intersectionRatio > 0.625
              ? 0.75
              : entry.intersectionRatio > 0.375
              ? 0.5
              : 0.25;
          const scrollStrength = dir * (1.25 - bound) * 6;
          const id = ++curID;
          const run = () => {
            if (id == curID && dragging.current) {
              scroller.current.scrollTop += scrollStrength;
              requestAnimationFrame(run);
            }
          };
          if (entry.intersectionRatio < 1 && dir != 0) run();
        }
      },
      {
        root: scroller.current,
        threshold: [0.25, 0.5, 0.75, 1]
      }
    );
    observer.current.observe(innerRef.current);
    return () => observer.current.disconnect();
  }, []);

  useEffect(() => {
    scroller.current = innerRef.current?.offsetParent.parentElement;
  }, [innerRef.current]);

  const upListener = () => {
    if (dragging.current) {
      dragging.current = false;
      const left = innerRef.current.offsetLeft;
      const top = innerRef.current.offsetTop;
      innerRef.current.style.position = '';
      innerRef.current.style.top = '';
      innerRef.current.style.left = '';
      innerRef.current.style.margin = '';
      innerRef.current.style.zIndex = '';
      onDrop(left, top, innerRef.current.offsetWidth, innerRef.current.offsetHeight);
    }
  };
  const moveListener = (evt: Touchy) => {
    if (dragging.current) {
      lastMove.current = {
        x: evt.clientX,
        y: evt.clientY
      };
      innerRef.current.style.top =
        evt.clientY - dragStartPos.current.y + scroller.current.scrollTop + 'px';
      innerRef.current.style.left =
        evt.clientX - dragStartPos.current.x + scroller.current.scrollLeft + 'px';
      onMove(
        innerRef.current.offsetLeft,
        innerRef.current.offsetTop,
        innerRef.current.offsetWidth,
        innerRef.current.offsetHeight
      );
    }
  };

  const downListener = (evt: Touchy) => {
    if (!dragging.current) {
      dragStartPos.current = {
        x: evt.clientX - innerRef.current.offsetLeft + scroller.current.scrollLeft,
        y: evt.clientY - innerRef.current.offsetTop + scroller.current.scrollTop
      };
      lastMove.current = {
        x: evt.clientX,
        y: evt.clientY
      };
      dragging.current = true;
      innerRef.current.style.position = 'absolute';
      innerRef.current.style.margin = '0';
      innerRef.current.style.top =
        evt.clientY - dragStartPos.current.y + scroller.current.scrollTop + 'px';
      innerRef.current.style.left =
        evt.clientX - dragStartPos.current.x + scroller.current.scrollLeft + 'px';
      innerRef.current.style.zIndex = '10000';
      onStart(
        innerRef.current.offsetLeft,
        innerRef.current.offsetTop,
        innerRef.current.offsetWidth,
        innerRef.current.offsetHeight
      );
    }
  };

  const outListener = () => {
    if (dragging.current) {
      dragging.current = false;
      const left = innerRef.current.offsetLeft;
      const top = innerRef.current.offsetTop;
      innerRef.current.style.position = '';
      innerRef.current.style.top = '';
      innerRef.current.style.left = '';
      innerRef.current.style.margin = '';
      innerRef.current.style.zIndex = '';
      onCancel(left, top, innerRef.current.offsetWidth, innerRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    const mouseUpListener = (evt: { buttons: number } & Touchy) => {
      if (!(evt.buttons & 1)) upListener();
    };
    const touchEndListener = (evt: { preventDefault(): void }) => {
      if (dragging.current) evt.preventDefault();
      upListener();
    };
    const mouseMoveListener = moveListener;
    const touchMoveListener = (evt: { targetTouches: TouchList }) => {
      moveListener(evt.targetTouches[0]);
    };
    const touchStartListener = (evt: { preventDefault(): void; touches: TouchList }) => {
      if (dragging.current) evt.preventDefault();
      if (evt.touches.length > 1) outListener();
    };
    const touchCancelListener = outListener;
    const scrollListener = () => {
      if (dragging.current) {
        innerRef.current.style.top =
          lastMove.current.y - dragStartPos.current.y + scroller.current.scrollTop + 'px';
        innerRef.current.style.left =
          lastMove.current.x - dragStartPos.current.x + scroller.current.scrollLeft + 'px';
      }
    };
    document.addEventListener('mouseup', mouseUpListener, { passive: true });
    document.addEventListener('touchend', touchEndListener);
    document.addEventListener('mousemove', mouseMoveListener, {
      passive: true
    });
    document.addEventListener('touchmove', touchMoveListener, {
      passive: true
    });
    document.addEventListener('touchstart', touchStartListener);
    document.addEventListener('touchcancel', touchCancelListener, {
      passive: true
    });
    scroller.current.addEventListener('scroll', scrollListener, {
      passive: true
    });
    return () => {
      document.removeEventListener('mouseup', mouseUpListener);
      document.removeEventListener('touchend', touchEndListener);
      document.removeEventListener('mousemove', mouseMoveListener);
      document.removeEventListener('touchmove', touchMoveListener);
      document.removeEventListener('touchstart', touchStartListener);
      document.removeEventListener('touchcancel', touchCancelListener);
    };
  }, []);

  useImperativeHandle(boxRef, () => innerRef.current);

  return (
    <>
      <WrapItem ref={padRef} style={{ margin: '0', width: '0' }}>
        <Box w={cw} h={ch} bg="transparent" />
      </WrapItem>
      <WrapItem ref={innerRef}>
        <Flex
          w={cw}
          h={ch}
          justify="center"
          align="center"
          cursor="move"
          position="relative"
          bgColor={colorMode == 'dark' ? 'gray.600' : 'gray.100'}
          boxShadow={info && info.pdf && colorMode == 'light' ? 'md' : null}
          onMouseDown={evt => {
            if (evt.buttons & 1) downListener(evt);
          }}
          onTouchStart={evt => {
            if (evt.touches.length == 1) downListener(evt.targetTouches[0]);
          }}
          style={{ touchAction: 'none' }}
          onDragStart={evt => evt.preventDefault()}
          transition="200ms box-shadow ease"
          _active={{
            boxShadow: colorMode == 'dark' ? null : 'lg'
          }}
        >
          {info && info.pdf ? (
            <>
              <DrivePage
                fileID={info.pdf}
                onLoad={() => {
                  (innerRef.current.firstElementChild as HTMLElement).style.backgroundColor =
                    'white';
                }}
              />
              <Flex pos="absolute" bottom="1" left="0" w="100%" justify="center" direction="row">
                <Flex
                  direction="row"
                  align="center"
                  px={2}
                  bgColor={colorMode == 'dark' ? 'gray.600' : 'gray.100'}
                  borderRadius={2}
                >
                  <Text>{info ? page : index + 1}</Text>
                  {page != index + 1 && info && (
                    <>
                      <ArrowForwardIcon mx={1} />
                      <Text>{index + 1}</Text>
                    </>
                  )}
                </Flex>
              </Flex>
            </>
          ) : (
            <Flex w="100%" h="100%" direction="column" align="center">
              <Flex direction="column" justify="center" align="center" grow="1">
                <Heading size="md" px={2} textAlign="center">
                  {info ? `${info.name} ${info.slug != info.name ? `(${info.slug})` : ''}` : '?'}
                </Heading>
              </Flex>
              <Flex direction="row" align="center" pb={2}>
                <Text>{info ? page : index + 1}</Text>
                {page != index + 1 && info && (
                  <>
                    <ArrowForwardIcon mx={1} />
                    <Text>{index + 1}</Text>
                  </>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
      </WrapItem>
    </>
  );
};

const EditLayout = <T extends EditLayoutInfo>({
  isOpen,
  onClose,
  pages,
  onUpdate
}: {
  isOpen: boolean;
  onClose: () => unknown;
  pages: T[];
  onUpdate: (pages: T[]) => boolean | Promise<boolean>;
}) => {
  const boxRefs = useRef<HTMLLIElement[]>([]);
  const padRefs = useRef<HTMLLIElement[]>([]);
  const [draftPages, setDraftPages] = useState<{ i: number; page: number; info: T }[]>([]);
  const [draftNumPages, setDraftNumPages] = useState(0);
  const curDrag = useRef<number>(-1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDraftPages(
      pages.map((page, i) => ({
        info: page,
        page: i + 1,
        i
      }))
    );
    setDraftNumPages(pages.length);
    padRefs.current.length = pages.length + 1;
    boxRefs.current.length = pages.length;
  }, [pages]);

  const removedPages = draftPages.length
    ? pages.filter(page => page && draftPages.every(v => v.info != page))
    : [];

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent maxW="calc(0.9 * var(--dvw))" w="4xl" maxH="calc(var(--dvh) - 128px)">
        <ModalHeader>Layout editor</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <FormControl isInvalid={draftPages.length != draftNumPages && draftNumPages % 4 != 0}>
            <FormLabel>Page count</FormLabel>
            <Flex direction="row">
              <Input
                min="1"
                type="number"
                value={draftNumPages <= 0 ? '' : draftNumPages}
                onKeyDown={e => {
                  const keyCode = e.key.charCodeAt(0);
                  if (e.key.length == 1 && (keyCode < 48 || keyCode > 57)) e.preventDefault();
                }}
                onChange={e => setDraftNumPages(+e.currentTarget.value)}
                width="60px"
              />
              <Button
                isDisabled={draftNumPages <= 0 || draftNumPages == draftPages.length}
                ms={2}
                leftIcon={<CheckIcon />}
                onClick={() => {
                  const newPages = draftPages.slice();
                  for (let i = newPages.length; i < draftNumPages; ++i) {
                    newPages[i] = { i, page: i + 1, info: null };
                  }
                  padRefs.current.length = draftNumPages + 1;
                  boxRefs.current.length = draftNumPages;
                  newPages.length = draftNumPages;
                  setDraftPages(newPages);
                }}
              >
                Apply
                {draftNumPages % 4 == 0 || draftPages.length == draftNumPages
                  ? ''
                  : ' (not recommended)'}
              </Button>
            </Flex>
            {draftNumPages >= 0 ? (
              <FormErrorMessage>Page count should usually be a multiple of 4</FormErrorMessage>
            ) : null}
          </FormControl>
          <Wrap position="relative" mt={5} p={4} userSelect="none">
            {draftPages.map(page => (
              <DraggableCard
                index={page.i}
                onStart={() => {
                  padRefs.current[page.i + 1].style.margin = '';
                  padRefs.current[page.i + 1].style.width = '';
                  curDrag.current = page.i;
                }}
                onMove={(x, y, w, h) => {
                  const usableBoxRefs = boxRefs.current.filter((_, j) => page.i != j);
                  const els = padRefs.current
                    .filter((_, j) => page.i != j)
                    .map((v, j) => ({
                      e: j == curDrag.current ? v : usableBoxRefs[j - +(j > curDrag.current)],
                      t: v,
                      i: j
                    }));
                  const OVERLAP_PAD = 0;

                  const overlap = (v?: HTMLElement) => {
                    if (!v) return 0;
                    const xs = Math.max(x - OVERLAP_PAD, v.offsetLeft);
                    const xe = Math.min(
                      x + w + OVERLAP_PAD,
                      v.offsetLeft + Math.max(1, v.offsetWidth)
                    );
                    const ys = Math.max(y - OVERLAP_PAD, v.offsetTop);
                    const ye = Math.min(y + h + OVERLAP_PAD, v.offsetTop + v.offsetHeight);
                    if (xe < xs || ye < ys) return 0;
                    return ((xe - xs) * (ye - ys)) / (Math.max(1, v.offsetWidth) * v.offsetHeight);
                  };
                  els.sort((a, b) => overlap(b.e) - overlap(a.e));

                  if (overlap(els[0].e) > 0) {
                    els[0].t.style.width = '';
                    els[0].t.style.margin = '';

                    for (const el of els.slice(1)) {
                      el.t.style.width = '0';
                      el.t.style.margin = '0';
                    }
                    return (curDrag.current = els[0].i);
                  }

                  curDrag.current = -1;

                  for (const el of els) {
                    if (el.t == padRefs.current[draftPages.length]) {
                      el.t.style.width = '';
                      el.t.style.margin = '';
                    } else {
                      el.t.style.width = '0';
                      el.t.style.margin = '0';
                    }
                  }
                }}
                onCancel={() => {
                  for (const el of padRefs.current) {
                    el.style.margin = '0';
                    el.style.width = '0';
                  }
                  curDrag.current = -1;
                }}
                onDrop={() => {
                  for (const el of padRefs.current) {
                    el.style.margin = '0';
                    el.style.width = '0';
                  }
                  if (curDrag.current != -1) {
                    const val = curDrag.current;
                    setDraftPages(draftPages => {
                      const newPages = draftPages.slice();
                      newPages.splice(page.i, 1);
                      newPages.splice(val, 0, page);
                      for (let i = 0; i < newPages.length; ++i) {
                        newPages[i].i = i;
                      }
                      return newPages;
                    });
                  }
                  curDrag.current = -1;
                }}
                boxRef={el => {
                  if (boxRefs.current[page.i] != el) {
                    boxRefs.current[page.i] = el;
                  }
                }}
                padRef={el => {
                  if (padRefs.current[page.i] != el) {
                    padRefs.current[page.i] = el;
                  }
                }}
                info={page.info}
                page={page.page}
                key={page.page}
              />
            ))}
            <WrapItem
              ref={el => {
                if (padRefs.current[draftPages.length] != el) {
                  padRefs.current[draftPages.length] = el;
                }
              }}
              style={{ margin: '0', width: '0' }}
            >
              <Box w={cw} h={ch} bg="transparent" />
            </WrapItem>
          </Wrap>
        </ModalBody>
        <ModalFooter>
          {removedPages.length > 0 && (
            <HStack spacing={1} alignItems="center" me={2}>
              <WarningTwoIcon color="red.500" />
              <Text color="red.500" fontWeight="bold">
                Deleting {removedPages.length} page
                {removedPages.length != 1 ? 's' : ''}
              </Text>
            </HStack>
          )}
          <Button
            isLoading={loading}
            onClick={async () => {
              setLoading(true);
              if (await onUpdate(draftPages.map(v => v.info))) {
                onClose();
              }
              setLoading(false);
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const curYear = (new Date().getFullYear() - (new Date().getMonth() > 5 ? 0 : 1)) % 100;

const NewIssue = () => {
  const [params, setParams] = useSearchParams();
  const [name, setName] = useState('');
  const [prefix, setPrefix] = useState('');
  const [suffix, setSuffix] = useState('');
  const [folio, setFolio] = useState('');
  const [doubletruck, setDoubletruck] = useState(true);
  const [spread, setSpread] = useState(0);
  const [pages, setPages] = useState<CreatePageInfo<number>[]>([null, null, null, null]);
  const [deadlines, setDeadlines] = useState<CreateDeadline[]>([]);
  const [allRoles, setAllRoles] = useGlobalState('roles');
  const [allIssues, setAllIssues] = useGlobalState('issues');
  const [allPages, setAllPages] = useGlobalState('pages');
  const [userInfo] = useGlobalState('userInfo');
  const [newDeadlineName, setNewDeadlineName] = useState('');
  const [newDeadlineTime, setNewDeadlineTime] = useState('');
  const [loading, setLoading] = useState(false);
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const rootErrModal = useDisclosure();
  const redirecting = useRef(false);

  const [currentIssue, setCurrentIssue] = useGlobalState('currentIssue');
  const navigate = useNavigate();

  useBlocker({
    enabled: deadlines.length > 0 || pages.some(v => v != null),
    onBlock: nav => {
      if (
        redirecting.current ||
        window.confirm('You have unsaved changes. Are you sure you want to leave?')
      ) {
        return nav.confirm();
      }
      nav.cancel();
    }
  });

  const timeInvalid = isNaN(Date.parse(newDeadlineTime));

  useEffect(() => {
    const ctrl = new AbortController();
    if (!allRoles) {
      roles(ctrl.signal).then(setAllRoles);
    }

    return () => ctrl.abort();
  }, []);

  useEffect(() => {
    const ctrl = new AbortController();
    const from = params.get('from');
    if (from) {
      const update = async (info: IssueInfo) => {
        const deadlines = Object.values(info.deadlines).map(({ id, name }) => ({
          id,
          name,
          time: new Date().setHours(23, 59, 0, 0)
        }));
        const revDeadlines = {};
        for (let i = 0; i < deadlines.length; ++i) revDeadlines[deadlines[i].id] = i;
        const pages: CreatePageInfo<number>[] = Array.from({ length: info.numPages }, () => null);
        const metas = await Promise.all(
          Object.keys(info.pages).map(
            id =>
              allPages[id] ||
              getPage(info.id, id, ctrl.signal).then(res => {
                setAllPages(pages => ({ ...pages, [id]: res }));
                return res;
              })
          )
        );
        if (metas.some(v => !v)) return;
        for (const meta of metas) {
          pages[meta.page - 1] = {
            name: meta.name,
            slug: meta.slug,
            roles: meta.roles,
            checklist: meta.checklist.map(v => ({
              question: v.question,
              deadline: v.deadline && revDeadlines[v.deadline]
            }))
          };
        }
        setDeadlines(deadlines);
        setPages(pages);
        setDoubletruck(info.doubletruck);
      };
      if (allIssues[from]) update(allIssues[from]);
      else
        issue(from, ctrl.signal).then(
          info => {
            setAllIssues({ ...allIssues, [from]: info });
            if (info) {
              update(info);
            }
          },
          () => {
            const newParams = new URLSearchParams(params);
            newParams.delete('from');
            setParams(newParams);
          }
        );
    }
    return () => ctrl.abort();
  }, [params]);

  const maxSpread = Math.floor(pages.length / 2);
  const p0 = spread * 2 - 1;
  const p1 = spread * 2;

  return (
    <Box p={6}>
      <Flex direction="row" justify="space-between" align="center">
        <Heading mb={4}>New issue</Heading>
        <Button
          leftIcon={<CheckIcon />}
          isDisabled={!name || !folio || isNaN(Date.parse(folio)) || loading}
          isLoading={loading}
          loadingText="Creating..."
          colorScheme="green"
          onClick={async () => {
            setLoading(true);
            try {
              const id = await api<string>('/assets/issue', {
                method: 'POST',
                body: {
                  name,
                  prefix,
                  suffix,
                  folio: Date.parse(folio.replace(/-/g, '/')),
                  deadlines,
                  doubletruck,
                  pages
                }
              });
              setCurrentIssue(id);
              redirecting.current = true;
              navigate('/issue/' + id);
            } catch (err) {
              if (err instanceof APIError && err.status == 500) {
                rootErrModal.onOpen();
              }
            }
            setLoading(false);
          }}
          mb={2}
        >
          Create issue
        </Button>
        <Modal isOpen={rootErrModal.isOpen} onClose={rootErrModal.onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Failed to create issue</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {userInfo.superuser ? (
                <>
                  You need to select a Google Drive folder for Winged Post in{' '}
                  <Link textDecoration="underline" as={RouterLink} to="/me">
                    your settings page.
                  </Link>
                </>
              ) : (
                <>
                  It seems the admin hasn't yet set up the Winged Post folder in Google Drive.
                  Please ask them to do so from their settings page.
                </>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
      <Flex direction={newPageA} gap={6} align="center" minH="calc(calc(0.9 * var(--dvh)) - 128px)">
        <VStack spacing={4} width="100%" alignSelf="flex-start" align="left">
          <FormControl isRequired>
            <FormLabel>Issue name</FormLabel>
            <Input
              placeholder="e.g. Issue 5"
              value={name}
              onChange={e => setName(e.currentTarget.value)}
            />
          </FormControl>
          <FormControl isRequired isInvalid={folio && isNaN(Date.parse(folio))}>
            <FormLabel>Folio</FormLabel>
            <Input value={folio} type="date" onChange={e => setFolio(e.currentTarget.value)} />
          </FormControl>
          <FormControl>
            <FormLabel>Filename prefix</FormLabel>
            <Input
              placeholder="e.g. I5_"
              value={prefix}
              onChange={e => setPrefix(e.currentTarget.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Filename suffix</FormLabel>
            <Input
              placeholder={`e.g. _${curYear}-${curYear + 1}`}
              value={suffix}
              onChange={e => setSuffix(e.currentTarget.value)}
            />
          </FormControl>
          <Checkbox
            isChecked={doubletruck}
            isDisabled={pages.length % 4 != 0}
            onChange={e => setDoubletruck(e.currentTarget.checked)}
          >
            Use doubletruck layout
          </Checkbox>
          <TableContainer w={maxNewTableW}>
            <Table colorScheme={colorMode == 'dark' ? 'whiteAlpha' : 'blackAlpha'}>
              <Thead>
                <Tr>
                  <Th ps={1} pe={1}>
                    Deadline name
                  </Th>
                  <Th pe={1}>Time</Th>
                  <Th isNumeric pe={1}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {deadlines.map((item, i) => (
                  <EditableDeadline
                    key={i}
                    info={item}
                    onChange={item => {
                      const newDeadlines = [...deadlines];
                      newDeadlines[i] = item;
                      setDeadlines(newDeadlines);
                    }}
                    onDelete={() => {
                      const newPages = [...pages];
                      for (const page of newPages) {
                        if (page) {
                          for (const item of page.checklist) {
                            if (item.deadline != null) {
                              if (item.deadline == i) item.deadline = undefined;
                              else if (item.deadline > i) item.deadline -= 1;
                            }
                          }
                        }
                      }
                      setPages(newPages);
                      setDeadlines(deadlines.filter((_, j) => i != j));
                    }}
                  />
                ))}
                <Tr>
                  <Td ps={1} pe={1}>
                    <Input
                      placeholder="e.g. Prod. night 1"
                      value={newDeadlineName}
                      onChange={e => setNewDeadlineName(e.currentTarget.value)}
                    />
                  </Td>
                  <Td pe={1}>
                    <Input
                      isInvalid={newDeadlineTime && timeInvalid}
                      type="datetime-local"
                      value={newDeadlineTime}
                      onChange={e => setNewDeadlineTime(e.currentTarget.value)}
                    />
                  </Td>
                  <Td isNumeric pe={1}>
                    <IconButton
                      aria-label="Create deadline"
                      icon={<AddIcon />}
                      size="sm"
                      isDisabled={!newDeadlineName || timeInvalid}
                      onClick={() => {
                        setDeadlines(
                          deadlines.concat({
                            name: newDeadlineName,
                            time: Date.parse(newDeadlineTime)
                          })
                        );
                        setNewDeadlineName('');
                        setNewDeadlineTime('');
                      }}
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <EditLayout
            isOpen={isOpen}
            onClose={onClose}
            pages={pages}
            onUpdate={newPages => {
              if (newPages.length % 4 != 0) {
                setDoubletruck(false);
              }
              setPages(newPages);
              setSpread(Math.min(spread, Math.floor(newPages.length / 2)));
              return true;
            }}
          />
        </VStack>
        <VStack>
          <HStack spacing={2}>
            <IconButton
              aria-label="Previous pages"
              variant="ghost"
              colorScheme="gray"
              icon={<ChevronLeftIcon boxSize={8} />}
              isDisabled={spread == 0}
              onClick={() => {
                setSpread(spread - 1);
              }}
            />
            {p0 < 0 ? (
              <NonPage w={newPageW} h={newPageH} />
            ) : (
              <NewPage
                info={pages[p0]}
                page={p0 + 1}
                deadlines={deadlines}
                others={pages.filter((_, i) => i != p0)}
                onChange={info => {
                  const newPages = [...pages];
                  newPages[p0] = info;
                  setPages(newPages);
                }}
              />
            )}
            {p1 >= pages.length ? (
              <NonPage w={newPageW} h={newPageH} />
            ) : (
              <NewPage
                info={pages[p1]}
                page={p1 + 1}
                deadlines={deadlines}
                others={pages.filter((_, i) => i != p1)}
                onChange={info => {
                  const newPages = [...pages];
                  newPages[p1] = info;
                  setPages(newPages);
                }}
              />
            )}
            <IconButton
              aria-label="Next pages"
              variant="ghost"
              colorScheme="gray"
              icon={<ChevronRightIcon boxSize={8} />}
              isDisabled={spread == maxSpread}
              onClick={() => {
                setSpread(spread + 1);
              }}
            />
          </HStack>
          <Slider
            aria-label="Select page"
            min={0}
            max={maxSpread}
            value={spread}
            onChange={setSpread}
            w={newSliderW}
          >
            <SliderTrack>
              <SliderFilledTrack bg="green.700" />
            </SliderTrack>
            <SliderThumb />
          </Slider>
          <Flex direction="column" justify="center" align="center" w={newSliderW} pos="relative">
            <Text justifySelf="center">
              Page
              {spread == 0
                ? ' 1 '
                : spread == maxSpread && !(pages.length & 1)
                ? ` ${pages.length} `
                : ` ${spread * 2}-${spread * 2 + 1} `}{' '}
              of {pages.length}
            </Text>
            <Show above="sm">
              <Button
                pos="absolute"
                bottom={-1}
                right={0}
                leftIcon={<EditIcon />}
                size="sm"
                onClick={onOpen}
              >
                Edit layout
              </Button>
            </Show>
            <Hide above="sm">
              <IconButton
                aria-label="Edit layout"
                pos="absolute"
                bottom={-1}
                right={0}
                icon={<EditIcon />}
                size="sm"
                onClick={onOpen}
              />
            </Hide>
          </Flex>
        </VStack>
      </Flex>
    </Box>
  );
};

const DefaultIssue = () => {
  const navigate = useNavigate();
  const [currentIssue, setCurrentIssue] = useGlobalState('currentIssue');
  const [allIssues, setAllIssues] = useGlobalState('issues');

  useEffect(() => {
    if (currentIssue == null) {
      issueInfo().then(
        info => {
          const newIssues = { ...allIssues };
          for (const id of info.issues) {
            newIssues[id] ||= null;
          }
          setAllIssues(newIssues);
          setCurrentIssue(info.currentIssue || '');
          navigate(info.currentIssue ? '/issue/' + info.currentIssue : '/issues', {
            replace: true
          });
        },
        () => {
          navigate('/issues', { replace: true });
        }
      );
    } else {
      navigate(currentIssue ? '/issue/' + currentIssue : '/issues', {
        replace: true
      });
    }
  }, []);

  return (
    <Flex w="100%" h="calc(var(--dvh) - 80px)" justify="center" align="center">
      <Loading />
    </Flex>
  );
};

export { AllIssues, NewIssue, Issue };
export default DefaultIssue;
