import { Box, Flex, Spinner } from '@chakra-ui/react';
import { useEffect, useRef, useState, lazy, Suspense } from 'react';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './pdf-page.css';

let libProm: Promise<Partial<typeof import('react-pdf/dist/esm/entry.parcel2')>>;
const getLib = () => {
  if (!libProm) {
    // Weird syntax enables Parcel tree shaking
    libProm = (async () => {
      const { Document, Page } = await import('react-pdf/dist/esm/entry.parcel2');
      return { Document, Page };
    })();
  }
  return libProm;
};

const Document = lazy(async () => {
  return { default: (await getLib()).Document! };
});

const Page = lazy(async () => {
  return { default: (await getLib()).Page! };
});

const PDFPage = ({
  data,
  onLoad,
  onErr
}: {
  data: unknown;
  onLoad?: () => unknown;
  onErr?: (err: string) => unknown;
}) => {
  const parentRef = useRef<HTMLDivElement>();
  const [width, setWidth] = useState<number>(null);
  const [renderedWidth, setRenderedWidth] = useState<number>(null);
  const [resize, setResize] = useState(true);
  const [left, setLeft] = useState(true);

  useEffect(() => {
    setWidth(parentRef.current.offsetWidth);
    let lastResize: ReturnType<typeof setTimeout>;
    const onResize = () => {
      setRenderedWidth(rw => {
        if (parentRef.current) setResize(rw != parentRef.current.offsetWidth);
        return rw;
      });
      clearTimeout(lastResize);
      lastResize = setTimeout(() => {
        if (parentRef.current) setWidth(parentRef.current.offsetWidth);
      }, 100);
    };
    window.addEventListener('resize', onResize, { passive: true });
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <Box ref={parentRef} w="100%" h="100%" overflow="hidden" bgColor={renderedWidth && 'white'}>
      {renderedWidth == null && (
        <Flex py="calc(77.27% - 24px)" w="100%" justify="center" direction="row" bgColor="white">
          <Spinner color="primary.700" size="xl" thickness="4px" emptyColor="gray.200" />
        </Flex>
      )}
      <Suspense fallback={null}>
        <Document
          file={data}
          loading={null}
          noData={null}
          error={null}
          onLoadSuccess={doc => {
            if (doc.numPages != 1 && onErr)
              onErr('has ' + doc.numPages + ' pages, but should have 1');
          }}
          onLoadError={() => {
            if (onErr) onErr('failed to load');
          }}
        >
          {width != null && renderedWidth != null && (left || renderedWidth != width) && (
            <Page
              className={
                (left ? 'dynamic-pdf-page' : 'dynamic-pdf-page hidden') + (resize ? ' resize' : '')
              }
              pageNumber={1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              width={(left ? renderedWidth : width) + 1} // avoid round-down white bars
              onRenderSuccess={() => {
                setRenderedWidth(width);
                setLeft(true);
                setResize(false);
              }}
            />
          )}
          {width != null && (!left || renderedWidth != width) && (
            <Page
              className={
                (left ? 'dynamic-pdf-page hidden' : 'dynamic-pdf-page') + (resize ? ' resize' : '')
              }
              pageNumber={1}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              width={(left ? width : renderedWidth) + 1} // avoid round-down white bars
              onRenderSuccess={page => {
                if (renderedWidth == null) {
                  if (onLoad) onLoad();
                  const { width, height } = page.getViewport({ scale: 1 });
                  // range: 10.5x17 to 11.5x17 or 11x16.5 to 11x17.5
                  if (Math.abs(height / width - 17 / 11) > 0.075 && onErr) {
                    onErr(
                      `has incorrect dimensions; make sure you're using an 11" x 17" tabloid format`
                    );
                  }
                }
                setRenderedWidth(width);
                setLeft(false);
                setResize(false);
              }}
            />
          )}
        </Document>
      </Suspense>
    </Box>
  );
};

export default PDFPage;
